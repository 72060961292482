import React, {useState, useEffect} from 'react';
import './subscriptionStyles.scss';
import {ALL_TEXT, ENUMS, colors, images} from '../../common';
import {GetActiveInactiveBadge, GetBadge} from '../../utils/helpers/users';
import {Grid} from '@mui/material';
import SubscriptionTabs from './subscription-tabs';
import {Loader, SubscriptionDetailPopup} from '../../components';
import {NodukAdmnApi} from '../../redux/slice/nodukAdmin.ts';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import {HiOutlineInformationCircle} from 'react-icons/hi2';

export const SusbcriptionsItem = ({
  name,
  deductible,
  cost,
  serviceTypeId,
  make,
  model,
  address,
  brand,
}) => {
  const planSelectorIcon = id => {
    switch (id) {
      case ENUMS.SERVICE_TYPE.VEHICLE:
        return images.vehicleV;
      case ENUMS.SERVICE_TYPE.HOME:
        return images.homeV;
      case ENUMS.SERVICE_TYPE.DEVICE:
        return images.devicesV;
      case ENUMS.SERVICE_TYPE.COMMERCIAL:
        return images.commercialV;
      case ENUMS.COVERAGE_TYPES.DISASTER:
        return images.disasterV;
    }
  };
  return (
    <div className="invoice-item-view">
      <div className="plan-icon">
        <img src={planSelectorIcon(serviceTypeId)} alt="" />
      </div>
      <div className="invoice-detail-card">
        <div className="primary-card-container-invoice ">
          <span>{name}</span>
          {make && <span>{`Make: ${make}`} </span>}
          {brand && <span>{`Brand: ${brand}`} </span>}
          {model && <span>{`Model: ${model}`} </span>}
          {address && <span>{`Address: ${address}`} </span>}
        </div>
        <div className="secondary-card-container-invoice">
          <span>{`${
            ALL_TEXT.ERROR_PLACEHOLDERS.DEDUCTIBLES.charAt(0).toUpperCase() +
            ALL_TEXT.ERROR_PLACEHOLDERS.DEDUCTIBLES.slice(1)
          }: ${deductible}`}</span>
          <div>
            <span> {`${ALL_TEXT.COST}: ${cost}`} </span>
          </div>
        </div>
      </div>
    </div>
  );
};

function SubscriptionDetail() {
  const {useLazyGetApiAdminGetAdminSubscriptionsQuery} = NodukAdmnApi;
  const [selectedRefundItem, setselectedRefundItem] = useState(0);
  const [selectedTab, setSelectedTab] = useState(1);
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);
  const [subscriptionDetailsAPI, subscriptionDetailsResponse] =
    useLazyGetApiAdminGetAdminSubscriptionsQuery();

  const getParamFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamFromUrl();
  const id = query.get('id');

  useEffect(() => {
    fetchSubscriptionDetails(id);
  }, []);

  const fetchSubscriptionDetails = async id => {
    await subscriptionDetailsAPI({subId: id});
  };

  const subscriptionDetailsData =
    subscriptionDetailsResponse.data?.content || {};

  const {
    name,
    email,
    subscriptionType,
    isActive,
    viewSubscriptionId,
    createdDate,
    subscriptionTotal,
    cardNumber,
    cardType,
    items,
    refunds,
    signupId,
    taxAmount,
    taxPercent,
    platformFee,
    discount,
    subscriptionSubtotal,
    subtotalAfterDiscount,
  } = subscriptionDetailsData || {};

  const SubscriptionDetailInfo = ({title, value}) => {
    return (
      <div className="sub-detail-info">
        <span className="headingSpan">{title}</span>
        <span>{value}</span>
      </div>
    );
  };

  const RefundsItemsDetails = ({
    customRefundStyle,
    refundId,
    date,
    type,
    amount,
    onItemClick,
  }) => {
    return (
      <div className={customRefundStyle} onClick={onItemClick}>
        <span>{refundId}</span>
        <span>{date}</span>
        <span>{type}</span>
        <span>{amount}</span>
      </div>
    );
  };
  return (
    <div className="subscriptions-details-main-container">
      <div className="title-container-subscriptions-details">
        <span>{ALL_TEXT.SUBSCRIPTIONS_DETAILS}</span>
      </div>
      {subscriptionDetailsResponse.isFetching ? (
        <Loader />
      ) : (
        <div className="subscription-detail-content-outer-layer">
          <div className="subscription-detail-content-header">
            <div className="subscription-header-left">
              <div className="user-image">
                <span class="icon-user"></span>
              </div>
              <div className="user-name">
                <p>{name}</p>
                <p>{email}</p>
              </div>
            </div>
            <div className="subscription-header-right">
              <span>{GetBadge(subscriptionType)}</span>
              <span>
                {GetActiveInactiveBadge(isActive ? 'Active' : 'Cancelled')}
              </span>
            </div>
          </div>
          <div className="subscription-detail-content-body">
            <div>
              <div className="subscription-detail-content-box">
                <SubscriptionDetailInfo
                  title={ALL_TEXT.SUBSCRIPTION_ID}
                  value={viewSubscriptionId}
                />
                <Tooltip id={`subtotal`} />
                <SubscriptionDetailInfo
                  title={
                    <span>
                      {ALL_TEXT.SUBTOTAL}
                      <HiOutlineInformationCircle
                        data-tooltip-id={`subtotal`}
                        data-tooltip-content={`Including $${platformFee}  Processing Fee`}
                        className={'I-Icon'}
                      />
                    </span>
                  }
                  value={`$${parseFloat(subscriptionSubtotal).toFixed(2)}`}
                />
              </div>
              <div className="subscription-detail-content-box">
                <SubscriptionDetailInfo
                  title={ALL_TEXT.CREATED_DATE}
                  value={createdDate}
                />
                <SubscriptionDetailInfo
                  title={ALL_TEXT.SUBSCRIPTION_DETAILS_.DISCOUNT}
                  value={`${discount ? discount : 'No discount'}`}
                />
              </div>
              <div className="subscription-detail-content-box">
                <SubscriptionDetailInfo
                  title={ALL_TEXT.SUBSCRIPTION_DETAILS_.SUBTOTAL_AFTER_DISCOUNT}
                  value={`$${parseFloat(subtotalAfterDiscount).toFixed(2)}`}
                />
                <SubscriptionDetailInfo
                  title={
                    <span>
                      {ALL_TEXT.TAXES_AND_OTHER_FEE}
                      <HiOutlineInformationCircle
                        data-tooltip-id={`taxes-and-otherFee`}
                        data-tooltip-content={`${taxPercent}% Sales Tax`}
                        className={'I-Icon'}
                      />
                    </span>
                  }
                  value={`$${parseFloat(taxAmount).toFixed(2)}`}
                />
                <Tooltip id={`taxes-and-otherFee`} />
              </div>
              <div className="subscription-detail-content-box">
                <SubscriptionDetailInfo
                  title={ALL_TEXT.SUBSCRIPTION_DETAILS_.BILLING_CARD}
                  value={
                    <div className="billing-card-digits">
                      <i
                        className={`subscription-card-type fa-brands fa-cc-${cardType}`}
                        style={{color: colors.primary}}></i>
                      {cardNumber ? (
                        <span>{`****${cardNumber}`}</span>
                      ) : (
                        <span>N/A</span>
                      )}
                    </div>
                  }
                />
                <SubscriptionDetailInfo
                  title={ALL_TEXT.TOTAL_AMOUNT}
                  value={`$${subscriptionTotal}`}
                />
              </div>
              <SubscriptionTabs
                selectedTab={selectedTab}
                setSelectedTab={i => {
                  setSelectedTab(i);
                }}>
                {selectedTab === 1 && (
                  <div className="sub-detail-item-box">
                    <Grid container spacing={2}>
                      {items?.map((item, index) => {
                        const {
                          providerName,
                          make,
                          model,
                          brand,
                          serviceTypeId,
                          address,
                          cost,
                          deductibleAmount,
                        } = item || {};
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            key={index}>
                            <div className="sub-detail-item">
                              <SusbcriptionsItem
                                name={providerName}
                                make={make}
                                model={model}
                                brand={brand}
                                serviceTypeId={serviceTypeId}
                                address={address}
                                cost={`$${parseFloat(cost).toFixed(2)}`}
                                deductible={`$${parseFloat(
                                  deductibleAmount,
                                ).toFixed(2)}`}
                              />
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                )}
                {selectedTab === 2 &&
                  (!refunds ? (
                    <div className="no-refunds-conainer">
                      {ALL_TEXT.SUBSCRIPTION_DETAILS_.NO_REFUND_ITEMS}
                    </div>
                  ) : (
                    <div className="sub-detail-refund">
                      <div className="sub-detail-refund-header">
                        <span>{ALL_TEXT.SUBSCRIPTION_DETAILS_.REFUND_ID}</span>
                        <span>
                          {ALL_TEXT.SUBSCRIPTION_DETAILS_.REFUND_DATE}
                        </span>
                        <span>
                          {ALL_TEXT.SUBSCRIPTION_DETAILS_.REFUND_TYPE}
                        </span>
                        <span>{ALL_TEXT.SUBSCRIPTION_DETAILS_.AMOUNT}</span>
                      </div>
                      <div className="sub-detail-refund-body">
                        {refunds?.map((item, index) => {
                          const {
                            refundId,
                            refundDate,
                            refundType,
                            refundAmount,
                          } = item || {};
                          return (
                            <RefundsItemsDetails
                              onItemClick={() => {
                                setIsSubscriptionModal(true);
                                setselectedRefundItem(refundId.split('_')[1]);
                              }}
                              customRefundStyle={
                                refunds?.length < 1
                                  ? 'sub-detail-refund-column none-border-refund-column'
                                  : 'sub-detail-refund-column'
                              }
                              key={index}
                              refundId={refundId}
                              date={refundDate}
                              type={refundType}
                              amount={refundAmount}
                            />
                          );
                        })}
                      </div>
                    </div>
                  ))}
              </SubscriptionTabs>
            </div>
          </div>
        </div>
      )}
      {isSubscriptionModal && (
        <SubscriptionDetailPopup
          show={isSubscriptionModal}
          refundId={selectedRefundItem}
          signupId={signupId}
          handelClose={() => {
            setIsSubscriptionModal(false);
          }}
        />
      )}
    </div>
  );
}

export default SubscriptionDetail;
