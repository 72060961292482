import React from 'react';
import './styles.scss';
import {ActionDropDownPopUp, CellValue} from '../../components';
import {BsThreeDotsVertical} from 'react-icons/bs';
// import {RiMenuSearchLine} from 'react-icons/ri';
import {ALL_TEXT, colors} from '../../common';
import {GetActiveInactiveBadge, GetBadge} from '../../utils/helpers/users';
import {MdOutlineManageSearch} from 'react-icons/md';
import {useNavigate} from 'react-router';
import {path} from '../../common/routesNames';

export function TableSubscription() {
  const navigate = useNavigate();

  const getMenuItems = row => {
    return [
      {
        title: ALL_TEXT.VIEW_SUBSCRIPTION,
        icon: (
          <span class="popup-gray-menu-icon gray-popup-icon">
            <MdOutlineManageSearch />
          </span>
        ),
        onItemPress: () => {
          navigate({
            pathname: path.subscriptionsDetails,
            search: `?id=${row.id}`,
          });
        },
      },
    ];
  };
  return [
    {
      title: 'Id',
      fieldName: 'viewId',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Email',
      fieldName: 'email',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Subscription Type',
      fieldName: 'subscriptionType',
      dataformat: cell => GetBadge(cell),
    },
    {
      title: 'Payment Card',
      fieldName: 'paymentCard',
      dataformat: (cell, row) => (
        <div className="billing-card-digits">
          <i
            className={`subscription-card-type fa-brands fa-cc-${row?.cardType}`}
            style={{color: colors.primary}}></i>
          <span>{cell ? `****${cell}` : 'N/A'}</span>
        </div>
      ),
    },
    {
      title: 'Status',
      fieldName: 'status',
      dataformat: cell => GetActiveInactiveBadge(cell ? 'Active' : 'Cancelled'),
    },
    {
      title: 'Created Date',
      fieldName: 'createdDate',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Subscription Amount',
      fieldName: 'amount',
      dataformat: (cell, row) => <CellValue cell={`$${cell}`} row={row} />,
    },
    {
      title: 'Actions',
      fieldName: 'action',
      dataformat: (cell, row) => (
        <ActionDropDownPopUp
          visibleIcon={<BsThreeDotsVertical />}
          items={getMenuItems(row)}
          userObj={row}

          //   screenCode={path.manageCarriers}
        />
      ),
    },
  ];
}
