import React, {useState, useEffect} from 'react';
import './styles.scss';
import {ALL_TEXT, ENUMS} from '../../../common';
import {CustomizedSearch, GenericTable} from '../../../components';
// import {BsSearch} from 'react-icons/bs';
import {TableHeading} from './table';
import {usePostApiAuditLogsGetAllAuditLogsMutation} from '../../../redux/slice/nodukAdmin.ts';
import {getValuesFromEnumsObj} from '../../../utils/helpers';
import Pagination from '@mui/material/Pagination';

// function EmptyListMessage() {
//   return (
//     <div className="search-user-logs-container">
//       <div className="audit-search-icon">
//         <BsSearch size={30} color="#7C7C7C" />
//       </div>
//       <p>{ALL_TEXT.AUDIT_LOGS.SEARCH_USER}</p>
//     </div>
//   );
// }

function AuditLogs() {
  const [btnLoader, setBtnLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedRole, setSelectedRole] = useState(0);

  const {
    MODULE: {
      ACTION: {INVOICE, CLAIM, SUBSCRIPTION, ALL},
    },
  } = ENUMS;

  const [getAllAuditAPI, getAllAuditResponse] =
    usePostApiAuditLogsGetAllAuditLogsMutation();

  useEffect(() => {
    let payload = {
      auditLogReq: {
        pageNo: currentPage,
        pageSize: ENUMS.PAGE_SIZE,
        email: selectedEmail,
        moduleAction: selectedModule,
        requestStatus: selectedStatus,
        role: selectedRole,
      },
    };
    getAuditLogsList(payload);
  }, [currentPage]);

  const getAuditLogsList = async payload => {
    let response = await getAllAuditAPI(payload);
    if (response && response?.data?.success) {
      setTotalPages(response?.data?.content?.totalPages);
    } else {
      setTotalPages(0);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setSelectedStatus(0);
  };

  const getAuditList = getAllAuditResponse?.data?.content?.list || [];
  const auditSearchHandler = async (
    email,
    moduleDropDown,
    statusDropDown,
    roleDropDown,
    clear,
  ) => {
    let payload;
    if (clear) {
      payload = {
        auditLogReq: {
          pageNo: 1,
          pageSize: ENUMS.PAGE_SIZE,
          email: '',
          moduleAction: '',
          requestStatus: 0,
          role: 0,
        },
      };
      setSelectedEmail('');
      setSelectedModule('');
      setSelectedStatus(0);
      setSelectedRole(0);
    } else {
      const role = getValuesFromEnumsObj(ENUMS.STAFF.ROLE, roleDropDown);
      payload = {
        auditLogReq: {
          pageNo: currentPage,
          pageSize: ENUMS.PAGE_SIZE,
          email: email,
          moduleAction: moduleDropDown === 'All' ? '' : moduleDropDown,
          requestStatus: statusDropDown === 'All' ? 0 : statusDropDown,
          role: role,
        },
      };
      setSelectedEmail(email);
      setSelectedModule(moduleDropDown === 'All' ? '' : moduleDropDown);
      setSelectedStatus(statusDropDown);
      setSelectedRole(role);
    }
    setCurrentPage(1);
    setBtnLoader(true);
    await getAuditLogsList(payload);
    setBtnLoader(false);
  };

  return (
    <div className="auditLogs-logs-main-container">
      <div className="title-container-auditLogs-logs">
        <div>
          <span>{ALL_TEXT.AUDIT_LOGS.LOGS} </span>
          <span>{ALL_TEXT.AUDIT_LOGS.AUDIT_LOGS_TITLE} </span>
        </div>
      </div>
      <div className="auditLogs-logs-search-bar">
        <CustomizedSearch
          title={ALL_TEXT.AUDIT_LOGS.SEARCH_AUDIT_LOGS_BY}
          firstLabel={ALL_TEXT.AUDIT_LOGS.EMAIL}
          firstPlaceholder={ALL_TEXT.AUDIT_LOGS.ENTER_EMAIL}
          firstDropdown
          firstDropdownLabel={ALL_TEXT.EMAIL_LOG.MODULE_ACTION}
          firstDropdownList={[
            ALL.LABEL,
            INVOICE.LABEL,
            CLAIM.LABEL,
            SUBSCRIPTION.LABEL,
          ]}
          secondDropdown
          secondDropdownLabel={ALL_TEXT.AUDIT_LOGS.REQUEST_STATUS}
          seconddropdownlist={['200', '400', '404', '401', '500', 'All']}
          thirdDropdown
          thirdDropdownLabel={ALL_TEXT.AUDIT_LOGS.ROLE}
          thirddropdownlist={[
            ENUMS.STAFF.ROLE.ALL.LABEL,
            ENUMS.STAFF.ROLE.ADMIN.LABEL,
            ENUMS.STAFF.ROLE.AGENCY.LABEL,
            ENUMS.STAFF.ROLE.AGENT.LABEL,
            ENUMS.STAFF.ROLE.SALES_REP.LABEL,
            ENUMS.STAFF.ROLE.CUSTOMER_SUPPORT.LABEL,
            ENUMS.STAFF.ROLE.BUSINESS_AMB.LABEL,
            // ENUMS.STAFF.ROLE.DISTRIBUTION_CHANNEL.LABEL,
          ]}
          onClearFilterHandler={() => {
            auditSearchHandler('', '', '', '', true);
          }}
          btnLoader={btnLoader}
          onSearchBtnPress={(
            email,
            _,
            __,
            moduleActionDropDown,
            statusDropDown,
            roleDropDown,
          ) => {
            auditSearchHandler(
              email,
              moduleActionDropDown,
              statusDropDown,
              roleDropDown,
            );
          }}
        />
      </div>
      {/* {getAllAuditResponse?.data?.content?.list.length === 0 ? (
        <EmptyListMessage />
      ) : ( */}
      <div className="table-auditLogs-logs-container">
        <GenericTable
          headings={TableHeading()}
          data={getAuditList}
          loader={getAllAuditResponse.isLoading}
        />
      </div>
      {/* )} */}

      {totalPages > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPages}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default AuditLogs;
