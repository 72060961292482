import {emptySplitApi as api} from './emptySplitApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    postApiAdminGetUserByRoleId: build.mutation<
      PostApiAdminGetUserByRoleIdApiResponse,
      PostApiAdminGetUserByRoleIdApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-user-by-role-id`,
        method: 'POST',
        body: queryArg.userReq,
      }),
    }),
    getApiAdminGetTutorialLinks: build.query<
      GetApiAdminGetTutorialLinksApiResponse,
      GetApiAdminGetTutorialLinksApiArg
    >({
      query: () => ({url: `/api/Admin/get-tutorial-links`}),
    }),
    postApiAdminJoinTeam: build.mutation<
      PostApiAdminJoinTeamApiResponse,
      PostApiAdminJoinTeamApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/join-team`,
        method: 'POST',
        body: queryArg.joinTeamDto,
      }),
    }),
    postApiAdminUpdateAgentStatus: build.mutation<
      PostApiAdminUpdateAgentStatusApiResponse,
      PostApiAdminUpdateAgentStatusApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/update-agent-status`,
        method: 'POST',
        body: queryArg.agentStatusDto,
      }),
    }),
    postApiAdminRequestDocuments: build.mutation<
      PostApiAdminRequestDocumentsApiResponse,
      PostApiAdminRequestDocumentsApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/request-documents`,
        method: 'POST',
        body: queryArg.requestDocsDto,
      }),
    }),
    postApiAdminVerifyUploadLink: build.mutation<
      PostApiAdminVerifyUploadLinkApiResponse,
      PostApiAdminVerifyUploadLinkApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/verify-upload-link`,
        method: 'POST',
        params: {emp: queryArg.emp},
      }),
    }),
    postApiAdminUploadDocuments: build.mutation<
      PostApiAdminUploadDocumentsApiResponse,
      PostApiAdminUploadDocumentsApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/upload-documents`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiAdminVerifyDuplicateEmail: build.mutation<
      PostApiAdminVerifyDuplicateEmailApiResponse,
      PostApiAdminVerifyDuplicateEmailApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/verify-duplicate-email`,
        method: 'POST',
        params: {email: queryArg.email},
      }),
    }),
    getApiAdminDownloadStaffFiles: build.query<
      GetApiAdminDownloadStaffFilesApiResponse,
      GetApiAdminDownloadStaffFilesApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/download-staff-files`,
        params: {empId: queryArg.empId},
      }),
    }),
    postApiAdminRegisterAgent: build.mutation<
      PostApiAdminRegisterAgentApiResponse,
      PostApiAdminRegisterAgentApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/register-agent`,
        method: 'POST',
        body: queryArg.adminEmployeeDto,
      }),
    }),
    postApiAdminSetAdminPassword: build.mutation<
      PostApiAdminSetAdminPasswordApiResponse,
      PostApiAdminSetAdminPasswordApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/set-admin-password`,
        method: 'POST',
        body: queryArg.resetPassDto,
      }),
    }),
    postApiAdminLogin: build.mutation<
      PostApiAdminLoginApiResponse,
      PostApiAdminLoginApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/login`,
        method: 'POST',
        body: queryArg.login,
      }),
    }),
    getApiAdminForgetPassword: build.query<
      GetApiAdminForgetPasswordApiResponse,
      GetApiAdminForgetPasswordApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/forget-password`,
        params: {email: queryArg.email},
      }),
    }),
    postApiAdminResetPassword: build.mutation<
      PostApiAdminResetPasswordApiResponse,
      PostApiAdminResetPasswordApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/reset-password`,
        method: 'POST',
        body: queryArg.resetPassDto,
      }),
    }),
    getApiAdminGetDashboardStats: build.query<
      GetApiAdminGetDashboardStatsApiResponse,
      GetApiAdminGetDashboardStatsApiArg
    >({
      query: () => ({url: `/api/Admin/get-dashboard-stats`}),
    }),
    getApiAdminGetProfile: build.query<
      GetApiAdminGetProfileApiResponse,
      GetApiAdminGetProfileApiArg
    >({
      query: () => ({url: `/api/Admin/get-profile`}),
    }),
    postApiAdminUpdateProfile: build.mutation<
      PostApiAdminUpdateProfileApiResponse,
      PostApiAdminUpdateProfileApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/update-profile`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiAdminVerifyEmployee: build.query<
      GetApiAdminVerifyEmployeeApiResponse,
      GetApiAdminVerifyEmployeeApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/verify-employee`,
        params: {token: queryArg.token},
      }),
    }),
    postApiAdminChangePassword: build.mutation<
      PostApiAdminChangePasswordApiResponse,
      PostApiAdminChangePasswordApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/change-password`,
        method: 'POST',
        body: queryArg.password,
      }),
    }),
    getApiAdminGetAllAgents: build.query<
      GetApiAdminGetAllAgentsApiResponse,
      GetApiAdminGetAllAgentsApiArg
    >({
      query: () => ({url: `/api/Admin/get-all-agents`}),
    }),
    postApiAdminGetAllUsers: build.mutation<
      PostApiAdminGetAllUsersApiResponse,
      PostApiAdminGetAllUsersApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-all-users`,
        method: 'POST',
        body: queryArg.searchUser,
      }),
    }),
    postApiAdminGetUsersDropdownList: build.mutation<
      PostApiAdminGetUsersDropdownListApiResponse,
      PostApiAdminGetUsersDropdownListApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-users-dropdown-list`,
        method: 'POST',
        body: queryArg.dropDownSearch,
      }),
    }),
    postApiAdminAddUser: build.mutation<
      PostApiAdminAddUserApiResponse,
      PostApiAdminAddUserApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/add-user`,
        method: 'POST',
        body: queryArg.signupDto,
      }),
    }),
    getApiAdminActivateDeactivateUser: build.query<
      GetApiAdminActivateDeactivateUserApiResponse,
      GetApiAdminActivateDeactivateUserApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/activate-deactivate-user`,
        params: {signupId: queryArg.signupId, status: queryArg.status},
      }),
    }),
    getApiAdminGenerateForgetPasswordLink: build.query<
      GetApiAdminGenerateForgetPasswordLinkApiResponse,
      GetApiAdminGenerateForgetPasswordLinkApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/generate-forget-password-link`,
        params: {email: queryArg.email},
      }),
    }),
    postApiAdminRemoveEmpToken: build.mutation<
      PostApiAdminRemoveEmpTokenApiResponse,
      PostApiAdminRemoveEmpTokenApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/remove-emp-token`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiAdminGetAllInvoices: build.mutation<
      PostApiAdminGetAllInvoicesApiResponse,
      PostApiAdminGetAllInvoicesApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-all-invoices`,
        method: 'POST',
        body: queryArg.adminInvoices,
      }),
    }),
    postApiAdminGetCarrierById: build.mutation<
      PostApiAdminGetCarrierByIdApiResponse,
      PostApiAdminGetCarrierByIdApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-carrier-by-id`,
        method: 'POST',
        params: {id: queryArg.id},
      }),
    }),
    postApiAdminAddUpdateCarrier: build.mutation<
      PostApiAdminAddUpdateCarrierApiResponse,
      PostApiAdminAddUpdateCarrierApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/add-update-carrier`,
        method: 'POST',
        body: queryArg.carrier,
      }),
    }),
    postApiAdminActivateDeactivateCarrier: build.mutation<
      PostApiAdminActivateDeactivateCarrierApiResponse,
      PostApiAdminActivateDeactivateCarrierApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/activate-deactivate-carrier`,
        method: 'POST',
        params: {id: queryArg.id, status: queryArg.status},
      }),
    }),
    postApiAdminGetAllCarriers: build.mutation<
      PostApiAdminGetAllCarriersApiResponse,
      PostApiAdminGetAllCarriersApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-all-carriers`,
        method: 'POST',
        body: queryArg.carrierResponse,
      }),
    }),
    postApiAdminGetAllClaims: build.mutation<
      PostApiAdminGetAllClaimsApiResponse,
      PostApiAdminGetAllClaimsApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-all-claims`,
        method: 'POST',
        body: queryArg.claimsResponse,
      }),
    }),
    getApiAdminGetClaimDetail: build.query<
      GetApiAdminGetClaimDetailApiResponse,
      GetApiAdminGetClaimDetailApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-claim-detail`,
        params: {claimId: queryArg.claimId},
      }),
    }),
    postApiAdminUpdateClaimInfo: build.mutation<
      PostApiAdminUpdateClaimInfoApiResponse,
      PostApiAdminUpdateClaimInfoApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/update-claim-info`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiAdminClaimDocZip: build.mutation<
      PostApiAdminClaimDocZipApiResponse,
      PostApiAdminClaimDocZipApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/claim-doc-zip`,
        method: 'POST',
        params: {claimId: queryArg.claimId, signupId: queryArg.signupId},
      }),
    }),
    getApiAdminGetEmployeeById: build.query<
      GetApiAdminGetEmployeeByIdApiResponse,
      GetApiAdminGetEmployeeByIdApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-employee-by-id`,
        params: {employeeId: queryArg.employeeId},
      }),
    }),
    getApiAdminActivateDeactivateEmployee: build.query<
      GetApiAdminActivateDeactivateEmployeeApiResponse,
      GetApiAdminActivateDeactivateEmployeeApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/activate-deactivate-employee`,
        params: {employeeId: queryArg.employeeId, status: queryArg.status},
      }),
    }),
    postApiAdminUpdateEmployeeById: build.mutation<
      PostApiAdminUpdateEmployeeByIdApiResponse,
      PostApiAdminUpdateEmployeeByIdApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/update-employee-by-id`,
        method: 'POST',
        body: queryArg.adminEmployeeDto,
      }),
    }),
    postApiAdminGetAllEmployees: build.mutation<
      PostApiAdminGetAllEmployeesApiResponse,
      PostApiAdminGetAllEmployeesApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-all-employees`,
        method: 'POST',
        body: queryArg.employee,
      }),
    }),
    postApiAdminAddNewEmployee: build.mutation<
      PostApiAdminAddNewEmployeeApiResponse,
      PostApiAdminAddNewEmployeeApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/add-new-employee`,
        method: 'POST',
        body: queryArg.adminEmployeeDto,
      }),
    }),
    getApiAdminGetEmpForgetLink: build.query<
      GetApiAdminGetEmpForgetLinkApiResponse,
      GetApiAdminGetEmpForgetLinkApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-emp-forget-link`,
        params: {email: queryArg.email},
      }),
    }),
    getApiAdminGetEmpActivationLink: build.query<
      GetApiAdminGetEmpActivationLinkApiResponse,
      GetApiAdminGetEmpActivationLinkApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-emp-activation-link`,
        params: {employeeId: queryArg.employeeId},
      }),
    }),
    getApiAdminGetRoleBasedAgenciesList: build.query<
      GetApiAdminGetRoleBasedAgenciesListApiResponse,
      GetApiAdminGetRoleBasedAgenciesListApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-role-based-agencies-list`,
        params: {agencyNameLetters: queryArg.agencyNameLetters},
      }),
    }),
    postApiAdminGetAllCouponCode: build.mutation<
      PostApiAdminGetAllCouponCodeApiResponse,
      PostApiAdminGetAllCouponCodeApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-all-coupon-code`,
        method: 'POST',
        body: queryArg.couponRequestDto,
      }),
    }),
    postApiAdminAddCouponCode: build.mutation<
      PostApiAdminAddCouponCodeApiResponse,
      PostApiAdminAddCouponCodeApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/add-coupon-code`,
        method: 'POST',
        body: queryArg.agentCouponCode,
      }),
    }),
    getApiAdminUpdateCoupon: build.query<
      GetApiAdminUpdateCouponApiResponse,
      GetApiAdminUpdateCouponApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/update-coupon`,
        params: {
          couponId: queryArg.couponId,
          description: queryArg.description,
        },
      }),
    }),
    getApiAdminVerifyPromotionCode: build.query<
      GetApiAdminVerifyPromotionCodeApiResponse,
      GetApiAdminVerifyPromotionCodeApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/verify-promotion-code`,
        params: {PromotionCode: queryArg.promotionCode},
      }),
    }),
    getApiAdminActiveDeactivePromotionCode: build.query<
      GetApiAdminActiveDeactivePromotionCodeApiResponse,
      GetApiAdminActiveDeactivePromotionCodeApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/active-deactive-promotion-code`,
        params: {couponId: queryArg.couponId, status: queryArg.status},
      }),
    }),
    postApiAdminShareQrCode: build.mutation<
      PostApiAdminShareQrCodeApiResponse,
      PostApiAdminShareQrCodeApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/share-qr-code`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiAdminQrCode: build.query<
      GetApiAdminQrCodeApiResponse,
      GetApiAdminQrCodeApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/qr-code`,
        params: {shortUrl: queryArg.shortUrl},
      }),
    }),
    getApiAdminGetQrCodeUrl: build.query<
      GetApiAdminGetQrCodeUrlApiResponse,
      GetApiAdminGetQrCodeUrlApiArg
    >({
      query: () => ({url: `/api/Admin/get-qr-code-url`}),
    }),
    postApiAdminUploadBrochure: build.mutation<
      PostApiAdminUploadBrochureApiResponse,
      PostApiAdminUploadBrochureApiArg
    >({
      query: () => ({url: `/api/Admin/upload-brochure`, method: 'POST'}),
    }),
    postApiAdminAddQrCode: build.mutation<
      PostApiAdminAddQrCodeApiResponse,
      PostApiAdminAddQrCodeApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/add-qr-code`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiAdminGetAllSubscriptions: build.mutation<
      PostApiAdminGetAllSubscriptionsApiResponse,
      PostApiAdminGetAllSubscriptionsApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-all-subscriptions`,
        method: 'POST',
        body: queryArg.adminSubscriptions,
      }),
    }),
    getApiAdminGetAdminSubscriptions: build.query<
      GetApiAdminGetAdminSubscriptionsApiResponse,
      GetApiAdminGetAdminSubscriptionsApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-admin-subscriptions`,
        params: {subId: queryArg.subId},
      }),
    }),
    getApiAdminGetRefundDetail: build.query<
      GetApiAdminGetRefundDetailApiResponse,
      GetApiAdminGetRefundDetailApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-refund-detail`,
        params: {RefundId: queryArg.refundId, SignupId: queryArg.signupId},
      }),
    }),
    postApiAdminCalculateCommission: build.mutation<
      PostApiAdminCalculateCommissionApiResponse,
      PostApiAdminCalculateCommissionApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/calculate-commission`,
        method: 'POST',
        body: queryArg.adminDashboard,
      }),
    }),
    postApiAdminGetAgencyCommissionedAgents: build.mutation<
      PostApiAdminGetAgencyCommissionedAgentsApiResponse,
      PostApiAdminGetAgencyCommissionedAgentsApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-agency-commissioned-agents`,
        method: 'POST',
        body: queryArg.adminDashboard,
      }),
    }),
    getApiAdminGetAgentCommissionRate: build.query<
      GetApiAdminGetAgentCommissionRateApiResponse,
      GetApiAdminGetAgentCommissionRateApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-agent-commission-rate`,
        params: {empId: queryArg.empId, roleId: queryArg.roleId},
      }),
    }),
    postApiAdminAddEditCommissionRate: build.mutation<
      PostApiAdminAddEditCommissionRateApiResponse,
      PostApiAdminAddEditCommissionRateApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/add-edit-commission-rate`,
        method: 'POST',
        body: queryArg.agentCommissionRateDto,
      }),
    }),
    postApiAdminGetAdminDashboard: build.mutation<
      PostApiAdminGetAdminDashboardApiResponse,
      PostApiAdminGetAdminDashboardApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-admin-dashboard`,
        method: 'POST',
        body: queryArg.adminDashboard,
      }),
    }),
    postApiAdminGetDashboardOverview: build.mutation<
      PostApiAdminGetDashboardOverviewApiResponse,
      PostApiAdminGetDashboardOverviewApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-dashboard-overview`,
        method: 'POST',
        body: queryArg.adminDashboard,
      }),
    }),
    postApiAdminGetDashboardRevenuestats: build.mutation<
      PostApiAdminGetDashboardRevenuestatsApiResponse,
      PostApiAdminGetDashboardRevenuestatsApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-dashboard-revenuestats`,
        method: 'POST',
        body: queryArg.adminDashboard,
      }),
    }),
    postApiAdminGetSubsClaimsStats: build.mutation<
      PostApiAdminGetSubsClaimsStatsApiResponse,
      PostApiAdminGetSubsClaimsStatsApiArg
    >({
      query: queryArg => ({
        url: `/api/Admin/get-subs-claims-stats`,
        method: 'POST',
        body: queryArg.adminDashboard,
      }),
    }),
    postApiAdminGetUserRoles: build.mutation<
      PostApiAdminGetUserRolesApiResponse,
      PostApiAdminGetUserRolesApiArg
    >({
      query: () => ({url: `/api/Admin/get-user-roles`, method: 'POST'}),
    }),
    postApiAdminCustomerAddCartitem: build.mutation<
      PostApiAdminCustomerAddCartitemApiResponse,
      PostApiAdminCustomerAddCartitemApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/add-cartitem`,
        method: 'POST',
        body: queryArg.cartItem,
      }),
    }),
    getApiAdminCustomerGetCartitem: build.query<
      GetApiAdminCustomerGetCartitemApiResponse,
      GetApiAdminCustomerGetCartitemApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-cartitem`,
        params: {cartItemId: queryArg.cartItemId, signupId: queryArg.signupId},
      }),
    }),
    getApiAdminCustomerGetCartitemList: build.query<
      GetApiAdminCustomerGetCartitemListApiResponse,
      GetApiAdminCustomerGetCartitemListApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-cartitem-list`,
        params: {signupId: queryArg.signupId},
      }),
    }),
    getApiAdminCustomerRemoveCartitem: build.query<
      GetApiAdminCustomerRemoveCartitemApiResponse,
      GetApiAdminCustomerRemoveCartitemApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/remove-cartitem`,
        params: {cartItemId: queryArg.cartItemId},
      }),
    }),
    getApiAdminCustomerRemoveAllCartitems: build.query<
      GetApiAdminCustomerRemoveAllCartitemsApiResponse,
      GetApiAdminCustomerRemoveAllCartitemsApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/remove-all-cartitems`,
        params: {signupId: queryArg.signupId},
      }),
    }),
    getApiAdminCustomerGetPaymentPlans: build.query<
      GetApiAdminCustomerGetPaymentPlansApiResponse,
      GetApiAdminCustomerGetPaymentPlansApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-payment-plans`,
        params: {signupId: queryArg.signupId},
      }),
    }),
    getApiAdminCustomerGetCompleteCheckout: build.query<
      GetApiAdminCustomerGetCompleteCheckoutApiResponse,
      GetApiAdminCustomerGetCompleteCheckoutApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-complete-checkout`,
        params: {signupId: queryArg.signupId},
      }),
    }),
    getApiAdminCustomerApplyCouponCode: build.query<
      GetApiAdminCustomerApplyCouponCodeApiResponse,
      GetApiAdminCustomerApplyCouponCodeApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/apply-coupon-code`,
        params: {signupId: queryArg.signupId, couponCode: queryArg.couponCode},
      }),
    }),
    getApiAdminCustomerGetUserBillingcards: build.query<
      GetApiAdminCustomerGetUserBillingcardsApiResponse,
      GetApiAdminCustomerGetUserBillingcardsApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-user-billingcards`,
        params: {signupId: queryArg.signupId},
      }),
    }),
    postApiAdminCustomerAddUserBillingcard: build.mutation<
      PostApiAdminCustomerAddUserBillingcardApiResponse,
      PostApiAdminCustomerAddUserBillingcardApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/add-user-billingcard`,
        method: 'POST',
        body: queryArg.billingCard,
      }),
    }),
    getApiAdminCustomerGetCheckoutDetails: build.query<
      GetApiAdminCustomerGetCheckoutDetailsApiResponse,
      GetApiAdminCustomerGetCheckoutDetailsApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-checkout-details`,
        params: {signupId: queryArg.signupId},
      }),
    }),
    postApiAdminCustomerCreateSubscription: build.mutation<
      PostApiAdminCustomerCreateSubscriptionApiResponse,
      PostApiAdminCustomerCreateSubscriptionApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/create-subscription`,
        method: 'POST',
        body: queryArg.checkoutDto,
      }),
    }),
    postApiAdminCustomerCreateSubscriptionV3: build.mutation<
      PostApiAdminCustomerCreateSubscriptionV3ApiResponse,
      PostApiAdminCustomerCreateSubscriptionV3ApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/create-subscription-v3`,
        method: 'POST',
        body: queryArg.checkoutDto,
      }),
    }),
    postApiAdminCustomerSaveSubscriptionDetail: build.mutation<
      PostApiAdminCustomerSaveSubscriptionDetailApiResponse,
      PostApiAdminCustomerSaveSubscriptionDetailApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/save-subscription-detail`,
        method: 'POST',
        body: queryArg.saveSubscriptionDto,
      }),
    }),
    getApiAdminCustomerGetCustomerProfile: build.query<
      GetApiAdminCustomerGetCustomerProfileApiResponse,
      GetApiAdminCustomerGetCustomerProfileApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-customer-profile`,
        params: {signUpId: queryArg.signUpId},
      }),
    }),
    getApiAdminCustomerGetCustomerDetail: build.query<
      GetApiAdminCustomerGetCustomerDetailApiResponse,
      GetApiAdminCustomerGetCustomerDetailApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-customer-detail`,
        params: {signUpId: queryArg.signUpId},
      }),
    }),
    postApiAdminCustomerUpdateCustomerInfo: build.mutation<
      PostApiAdminCustomerUpdateCustomerInfoApiResponse,
      PostApiAdminCustomerUpdateCustomerInfoApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/update-customer-info`,
        method: 'POST',
        body: queryArg.customerDto,
      }),
    }),
    postApiAdminCustomerGetCustomerPolicies: build.mutation<
      PostApiAdminCustomerGetCustomerPoliciesApiResponse,
      PostApiAdminCustomerGetCustomerPoliciesApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-customer-policies`,
        method: 'POST',
        body: queryArg.policiesPageDto,
      }),
    }),
    postApiAdminCustomerGetCustomerClaims: build.mutation<
      PostApiAdminCustomerGetCustomerClaimsApiResponse,
      PostApiAdminCustomerGetCustomerClaimsApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-customer-claims`,
        method: 'POST',
        body: queryArg.claimPageDto,
      }),
    }),
    postApiAdminCustomerGetCustomerInvoices: build.mutation<
      PostApiAdminCustomerGetCustomerInvoicesApiResponse,
      PostApiAdminCustomerGetCustomerInvoicesApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-customer-invoices`,
        method: 'POST',
        body: queryArg.invoicePageDto,
      }),
    }),
    postApiAdminCustomerGetCustomerSubscriptions: build.mutation<
      PostApiAdminCustomerGetCustomerSubscriptionsApiResponse,
      PostApiAdminCustomerGetCustomerSubscriptionsApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-customer-subscriptions`,
        method: 'POST',
        body: queryArg.subscPageDto,
      }),
    }),
    getApiAdminCustomerGetPolicyDetail: build.query<
      GetApiAdminCustomerGetPolicyDetailApiResponse,
      GetApiAdminCustomerGetPolicyDetailApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-policy-detail`,
        params: {itemId: queryArg.itemId, signupId: queryArg.signupId},
      }),
    }),
    getApiAdminCustomerGetClaimDetail: build.query<
      GetApiAdminCustomerGetClaimDetailApiResponse,
      GetApiAdminCustomerGetClaimDetailApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-claim-detail`,
        params: {claimId: queryArg.claimId, signupId: queryArg.signupId},
      }),
    }),
    getApiAdminCustomerGetSubscDetail: build.query<
      GetApiAdminCustomerGetSubscDetailApiResponse,
      GetApiAdminCustomerGetSubscDetailApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-subsc-detail`,
        params: {subId: queryArg.subId, signupId: queryArg.signupId},
      }),
    }),
    getApiAdminCustomerGetInvoiceDetail: build.query<
      GetApiAdminCustomerGetInvoiceDetailApiResponse,
      GetApiAdminCustomerGetInvoiceDetailApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/get-invoice-detail`,
        params: {invId: queryArg.invId, signupId: queryArg.signupId},
      }),
    }),
    postApiAdminCustomerAddClaim: build.mutation<
      PostApiAdminCustomerAddClaimApiResponse,
      PostApiAdminCustomerAddClaimApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/add-claim`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiAdminCustomerCancelCustomerPolicy: build.mutation<
      PostApiAdminCustomerCancelCustomerPolicyApiResponse,
      PostApiAdminCustomerCancelCustomerPolicyApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/cancel-customer-policy`,
        method: 'POST',
        body: queryArg.cancelPolicyDto,
      }),
    }),
    postApiAdminCustomerCancelCustomerPolicyV2: build.mutation<
      PostApiAdminCustomerCancelCustomerPolicyV2ApiResponse,
      PostApiAdminCustomerCancelCustomerPolicyV2ApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/cancel-customer-policy-V2`,
        method: 'POST',
        body: queryArg.cancelPolicyDto,
      }),
    }),
    postApiAdminCustomerCancelSubscription: build.mutation<
      PostApiAdminCustomerCancelSubscriptionApiResponse,
      PostApiAdminCustomerCancelSubscriptionApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/cancel-subscription`,
        method: 'POST',
        body: queryArg.cancelPolicyDto,
      }),
    }),
    postApiAdminCustomerSetCustomerPassword: build.mutation<
      PostApiAdminCustomerSetCustomerPasswordApiResponse,
      PostApiAdminCustomerSetCustomerPasswordApiArg
    >({
      query: queryArg => ({
        url: `/api/AdminCustomer/set-customer-password`,
        method: 'POST',
        body: queryArg.resetPassDto,
      }),
    }),
    postApiAuditLogsGetAllNotificationLogs: build.mutation<
      PostApiAuditLogsGetAllNotificationLogsApiResponse,
      PostApiAuditLogsGetAllNotificationLogsApiArg
    >({
      query: queryArg => ({
        url: `/api/AuditLogs/get-all-notification-logs`,
        method: 'POST',
        body: queryArg.logPageDto,
      }),
    }),
    getApiAuditLogsGetNotificationTokens: build.query<
      GetApiAuditLogsGetNotificationTokensApiResponse,
      GetApiAuditLogsGetNotificationTokensApiArg
    >({
      query: queryArg => ({
        url: `/api/AuditLogs/get-notification-tokens`,
        params: {logId: queryArg.logId},
      }),
    }),
    getApiAuditLogsDeleteNotificationById: build.query<
      GetApiAuditLogsDeleteNotificationByIdApiResponse,
      GetApiAuditLogsDeleteNotificationByIdApiArg
    >({
      query: queryArg => ({
        url: `/api/AuditLogs/delete-notification-by-id`,
        params: {id: queryArg.id},
      }),
    }),
    postApiAuditLogsGetAllEmailLogs: build.mutation<
      PostApiAuditLogsGetAllEmailLogsApiResponse,
      PostApiAuditLogsGetAllEmailLogsApiArg
    >({
      query: queryArg => ({
        url: `/api/AuditLogs/get-all-email-logs`,
        method: 'POST',
        body: queryArg.logPageDto,
      }),
    }),
    getApiAuditLogsGetEmailLogById: build.query<
      GetApiAuditLogsGetEmailLogByIdApiResponse,
      GetApiAuditLogsGetEmailLogByIdApiArg
    >({
      query: queryArg => ({
        url: `/api/AuditLogs/get-email-log-by-id`,
        params: {logId: queryArg.logId},
      }),
    }),
    postApiAuditLogsGetAllAuditLogs: build.mutation<
      PostApiAuditLogsGetAllAuditLogsApiResponse,
      PostApiAuditLogsGetAllAuditLogsApiArg
    >({
      query: queryArg => ({
        url: `/api/AuditLogs/get-all-audit-logs`,
        method: 'POST',
        body: queryArg.auditLogReq,
      }),
    }),
    getApiAuditLogsGetAuditLogDetails: build.query<
      GetApiAuditLogsGetAuditLogDetailsApiResponse,
      GetApiAuditLogsGetAuditLogDetailsApiArg
    >({
      query: queryArg => ({
        url: `/api/AuditLogs/get-audit-log-details`,
        params: {logId: queryArg.logId},
      }),
    }),
    getApiCustomerGetTotalCoverages: build.query<
      GetApiCustomerGetTotalCoveragesApiResponse,
      GetApiCustomerGetTotalCoveragesApiArg
    >({
      query: () => ({url: `/api/Customer/GetTotalCoverages`}),
    }),
    getApiCustomerGetCarriersMakersByType: build.query<
      GetApiCustomerGetCarriersMakersByTypeApiResponse,
      GetApiCustomerGetCarriersMakersByTypeApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/GetCarriersMakersByType`,
        params: {serviceTypeId: queryArg.serviceTypeId},
      }),
    }),
    getApiCustomerGetVehicleModelListById: build.query<
      GetApiCustomerGetVehicleModelListByIdApiResponse,
      GetApiCustomerGetVehicleModelListByIdApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/GetVehicleModelListById`,
        params: {
          serviceTypeId: queryArg.serviceTypeId,
          makeId: queryArg.makeId,
        },
      }),
    }),
    getApiCustomerGetDeductibleList: build.query<
      GetApiCustomerGetDeductibleListApiResponse,
      GetApiCustomerGetDeductibleListApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/GetDeductibleList`,
        params: {isDisaster: queryArg.isDisaster},
      }),
    }),
    getApiCustomerGetCartCount: build.query<
      GetApiCustomerGetCartCountApiResponse,
      GetApiCustomerGetCartCountApiArg
    >({
      query: () => ({url: `/api/Customer/GetCartCount`}),
    }),
    postApiCustomerAddCartItem: build.mutation<
      PostApiCustomerAddCartItemApiResponse,
      PostApiCustomerAddCartItemApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/AddCartItem`,
        method: 'POST',
        body: queryArg.cartItem,
      }),
    }),
    getApiCustomerGetCartItemById: build.query<
      GetApiCustomerGetCartItemByIdApiResponse,
      GetApiCustomerGetCartItemByIdApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/GetCartItemById`,
        params: {cartItemId: queryArg.cartItemId},
      }),
    }),
    postApiCustomerRemoveCartItemById: build.mutation<
      PostApiCustomerRemoveCartItemByIdApiResponse,
      PostApiCustomerRemoveCartItemByIdApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/RemoveCartItemById`,
        method: 'POST',
        params: {cartItemId: queryArg.cartItemId},
      }),
    }),
    postApiCustomerRemoveAllCartItems: build.mutation<
      PostApiCustomerRemoveAllCartItemsApiResponse,
      PostApiCustomerRemoveAllCartItemsApiArg
    >({
      query: () => ({url: `/api/Customer/RemoveAllCartItems`, method: 'POST'}),
    }),
    getApiCustomerGetCartItemList: build.query<
      GetApiCustomerGetCartItemListApiResponse,
      GetApiCustomerGetCartItemListApiArg
    >({
      query: () => ({url: `/api/Customer/GetCartItemList`}),
    }),
    getApiCustomerGetCheckOutCartItemsById: build.query<
      GetApiCustomerGetCheckOutCartItemsByIdApiResponse,
      GetApiCustomerGetCheckOutCartItemsByIdApiArg
    >({
      query: () => ({url: `/api/Customer/GetCheckOutCartItemsById`}),
    }),
    getApiCustomerGetCompleteCheckOut: build.query<
      GetApiCustomerGetCompleteCheckOutApiResponse,
      GetApiCustomerGetCompleteCheckOutApiArg
    >({
      query: () => ({url: `/api/Customer/GetCompleteCheckOut`}),
    }),
    getApiCustomerApplyCouponCode: build.query<
      GetApiCustomerApplyCouponCodeApiResponse,
      GetApiCustomerApplyCouponCodeApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/ApplyCouponCode`,
        params: {couponCode: queryArg.couponCode},
      }),
    }),
    getApiCustomerGetCardPaymentInfo: build.query<
      GetApiCustomerGetCardPaymentInfoApiResponse,
      GetApiCustomerGetCardPaymentInfoApiArg
    >({
      query: () => ({url: `/api/Customer/GetCardPaymentInfo`}),
    }),
    postApiCustomerGetPolicies: build.mutation<
      PostApiCustomerGetPoliciesApiResponse,
      PostApiCustomerGetPoliciesApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/GetPolicies`,
        method: 'POST',
        body: queryArg.policiesPageDto,
      }),
    }),
    postApiCustomerGetClaimsByStatus: build.mutation<
      PostApiCustomerGetClaimsByStatusApiResponse,
      PostApiCustomerGetClaimsByStatusApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/GetClaimsByStatus`,
        method: 'POST',
        body: queryArg.claimPageDto,
      }),
    }),
    getApiCustomerGetClaimsCountByTypeById: build.query<
      GetApiCustomerGetClaimsCountByTypeByIdApiResponse,
      GetApiCustomerGetClaimsCountByTypeByIdApiArg
    >({
      query: () => ({url: `/api/Customer/GetClaimsCountByTypeById`}),
    }),
    getApiCustomerGetClaimsByPolicyId: build.query<
      GetApiCustomerGetClaimsByPolicyIdApiResponse,
      GetApiCustomerGetClaimsByPolicyIdApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/GetClaimsByPolicyId`,
        params: {cartItemId: queryArg.cartItemId},
      }),
    }),
    postApiCustomerAddNewClaims: build.mutation<
      PostApiCustomerAddNewClaimsApiResponse,
      PostApiCustomerAddNewClaimsApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/AddNewClaims`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiCustomerSaveFeedBack: build.mutation<
      PostApiCustomerSaveFeedBackApiResponse,
      PostApiCustomerSaveFeedBackApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/SaveFeedBack`,
        method: 'POST',
        body: queryArg.feedbackDto,
      }),
    }),
    getApiCustomerGetClaimDetailById: build.query<
      GetApiCustomerGetClaimDetailByIdApiResponse,
      GetApiCustomerGetClaimDetailByIdApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/GetClaimDetailById`,
        params: {ClaimId: queryArg.claimId},
      }),
    }),
    getApiCustomerGetNodukClaimDetail: build.query<
      GetApiCustomerGetNodukClaimDetailApiResponse,
      GetApiCustomerGetNodukClaimDetailApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/GetNodukClaimDetail`,
        params: {ClaimId: queryArg.claimId},
      }),
    }),
    getApiCustomerGetInvoiceDetailById: build.query<
      GetApiCustomerGetInvoiceDetailByIdApiResponse,
      GetApiCustomerGetInvoiceDetailByIdApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/GetInvoiceDetailById`,
        params: {InvoiceId: queryArg.invoiceId},
      }),
    }),
    postApiCustomerGetAllInvoicesByStatus: build.mutation<
      PostApiCustomerGetAllInvoicesByStatusApiResponse,
      PostApiCustomerGetAllInvoicesByStatusApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/GetAllInvoicesByStatus`,
        method: 'POST',
        body: queryArg.invoicePageDto,
      }),
    }),
    getApiCustomerVerifyZipCode: build.query<
      GetApiCustomerVerifyZipCodeApiResponse,
      GetApiCustomerVerifyZipCodeApiArg
    >({
      query: queryArg => ({
        url: `/api/Customer/VerifyZipCode`,
        params: {ZipCode: queryArg.zipCode},
      }),
    }),
    postApiDisasterGetDisasterAreasList: build.mutation<
      PostApiDisasterGetDisasterAreasListApiResponse,
      PostApiDisasterGetDisasterAreasListApiArg
    >({
      query: queryArg => ({
        url: `/api/Disaster/get-disaster-areas-list`,
        method: 'POST',
        body: queryArg.disasterPageDto,
      }),
    }),
    getApiDisasterGetRiskLevelsList: build.query<
      GetApiDisasterGetRiskLevelsListApiResponse,
      GetApiDisasterGetRiskLevelsListApiArg
    >({
      query: () => ({url: `/api/Disaster/get-risk-levels-list`}),
    }),
    postApiDisasterAddUpdateDisasterArea: build.mutation<
      PostApiDisasterAddUpdateDisasterAreaApiResponse,
      PostApiDisasterAddUpdateDisasterAreaApiArg
    >({
      query: queryArg => ({
        url: `/api/Disaster/add-update-disaster-area`,
        method: 'POST',
        body: queryArg.disasterReq,
      }),
    }),
    getApiDisasterActiveDeactiveDisasterArea: build.query<
      GetApiDisasterActiveDeactiveDisasterAreaApiResponse,
      GetApiDisasterActiveDeactiveDisasterAreaApiArg
    >({
      query: queryArg => ({
        url: `/api/Disaster/active-deactive-disaster-area`,
        params: {id: queryArg.id, status: queryArg.status},
      }),
    }),
    postApiDisasterImportDisasterArea: build.mutation<
      PostApiDisasterImportDisasterAreaApiResponse,
      PostApiDisasterImportDisasterAreaApiArg
    >({
      query: queryArg => ({
        url: `/api/Disaster/import-disaster-area`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiDisasterAddEditDisasterRisk: build.mutation<
      PostApiDisasterAddEditDisasterRiskApiResponse,
      PostApiDisasterAddEditDisasterRiskApiArg
    >({
      query: queryArg => ({
        url: `/api/Disaster/add-edit-disaster-risk`,
        method: 'POST',
        body: queryArg.addDisasterRisk,
      }),
    }),
    getApiDisasterActiveInactiveDisasterRisk: build.query<
      GetApiDisasterActiveInactiveDisasterRiskApiResponse,
      GetApiDisasterActiveInactiveDisasterRiskApiArg
    >({
      query: queryArg => ({
        url: `/api/Disaster/active-inactive-disaster-risk`,
        params: {riskId: queryArg.riskId, isActive: queryArg.isActive},
      }),
    }),
    getApiFaqGetFaQsList: build.query<
      GetApiFaqGetFaQsListApiResponse,
      GetApiFaqGetFaQsListApiArg
    >({
      query: () => ({url: `/api/FAQ/GetFAQsList`}),
    }),
    postApiNotificationSendNotification: build.mutation<
      PostApiNotificationSendNotificationApiResponse,
      PostApiNotificationSendNotificationApiArg
    >({
      query: queryArg => ({
        url: `/api/Notification/SendNotification`,
        method: 'POST',
        body: queryArg.notificationDto,
      }),
    }),
    getApiNotificationGetEmployeeNotifications: build.query<
      GetApiNotificationGetEmployeeNotificationsApiResponse,
      GetApiNotificationGetEmployeeNotificationsApiArg
    >({
      query: () => ({url: `/api/Notification/GetEmployeeNotifications`}),
    }),
    getApiNotificationUpdateAdminNotificationStatus: build.query<
      GetApiNotificationUpdateAdminNotificationStatusApiResponse,
      GetApiNotificationUpdateAdminNotificationStatusApiArg
    >({
      query: queryArg => ({
        url: `/api/Notification/UpdateAdminNotificationStatus`,
        params: {notifyId: queryArg.notifyId},
      }),
    }),
    getApiNotificationGetCustomerNotification: build.query<
      GetApiNotificationGetCustomerNotificationApiResponse,
      GetApiNotificationGetCustomerNotificationApiArg
    >({
      query: () => ({url: `/api/Notification/GetCustomerNotification`}),
    }),
    getApiNotificationUpdateCustomerNotificationStatus: build.query<
      GetApiNotificationUpdateCustomerNotificationStatusApiResponse,
      GetApiNotificationUpdateCustomerNotificationStatusApiArg
    >({
      query: queryArg => ({
        url: `/api/Notification/UpdateCustomerNotificationStatus`,
        params: {notifyId: queryArg.notifyId},
      }),
    }),
    postApiNotificationSendAdminNotification: build.mutation<
      PostApiNotificationSendAdminNotificationApiResponse,
      PostApiNotificationSendAdminNotificationApiArg
    >({
      query: queryArg => ({
        url: `/api/Notification/SendAdminNotification`,
        method: 'POST',
        body: queryArg.notificationDto,
      }),
    }),
    deleteApiNotificationDeleteOldNotifications: build.mutation<
      DeleteApiNotificationDeleteOldNotificationsApiResponse,
      DeleteApiNotificationDeleteOldNotificationsApiArg
    >({
      query: queryArg => ({
        url: `/api/Notification/DeleteOldNotifications`,
        method: 'DELETE',
        params: {status: queryArg.status},
      }),
    }),
    getApiNotificationGetEmployeeNotificationCount: build.query<
      GetApiNotificationGetEmployeeNotificationCountApiResponse,
      GetApiNotificationGetEmployeeNotificationCountApiArg
    >({
      query: () => ({url: `/api/Notification/GetEmployeeNotificationCount`}),
    }),
    postApiPaymentCreateCustomerSubscriptionV2: build.mutation<
      PostApiPaymentCreateCustomerSubscriptionV2ApiResponse,
      PostApiPaymentCreateCustomerSubscriptionV2ApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/CreateCustomerSubscriptionV2`,
        method: 'POST',
        body: queryArg.checkoutDto,
      }),
    }),
    postApiPaymentCreateCustomerSubscriptionV3: build.mutation<
      PostApiPaymentCreateCustomerSubscriptionV3ApiResponse,
      PostApiPaymentCreateCustomerSubscriptionV3ApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/CreateCustomerSubscriptionV3`,
        method: 'POST',
        body: queryArg.checkoutDto,
      }),
    }),
    getApiPaymentGetLinkedSubscriptions: build.query<
      GetApiPaymentGetLinkedSubscriptionsApiResponse,
      GetApiPaymentGetLinkedSubscriptionsApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/GetLinkedSubscriptions`,
        params: {stripePayementId: queryArg.stripePayementId},
      }),
    }),
    postApiPaymentCancelPolicyByCartItemId: build.mutation<
      PostApiPaymentCancelPolicyByCartItemIdApiResponse,
      PostApiPaymentCancelPolicyByCartItemIdApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/CancelPolicyByCartItemId`,
        method: 'POST',
        body: queryArg.cancelPolicyDto,
      }),
    }),
    postApiPaymentCancelPolicyByCartItemIdV2: build.mutation<
      PostApiPaymentCancelPolicyByCartItemIdV2ApiResponse,
      PostApiPaymentCancelPolicyByCartItemIdV2ApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/CancelPolicyByCartItemIdV2`,
        method: 'POST',
        body: queryArg.cancelPolicyDto,
      }),
    }),
    postApiPaymentGetSubscriptionsByStatus: build.mutation<
      PostApiPaymentGetSubscriptionsByStatusApiResponse,
      PostApiPaymentGetSubscriptionsByStatusApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/GetSubscriptionsByStatus`,
        method: 'POST',
        body: queryArg.subscPageDto,
      }),
    }),
    getApiPaymentGetSubscriptionsDetails: build.query<
      GetApiPaymentGetSubscriptionsDetailsApiResponse,
      GetApiPaymentGetSubscriptionsDetailsApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/GetSubscriptionsDetails`,
        params: {subId: queryArg.subId},
      }),
    }),
    postApiPaymentUpdateSubscriptionCard: build.mutation<
      PostApiPaymentUpdateSubscriptionCardApiResponse,
      PostApiPaymentUpdateSubscriptionCardApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/UpdateSubscriptionCard`,
        method: 'POST',
        body: queryArg.updateCardDto,
      }),
    }),
    getApiPaymentGetSubscriptionPlans: build.query<
      GetApiPaymentGetSubscriptionPlansApiResponse,
      GetApiPaymentGetSubscriptionPlansApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/GetSubscriptionPlans`,
        params: {subId: queryArg.subId},
      }),
    }),
    postApiPaymentUpgradeSubscriptions: build.mutation<
      PostApiPaymentUpgradeSubscriptionsApiResponse,
      PostApiPaymentUpgradeSubscriptionsApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/UpgradeSubscriptions`,
        method: 'POST',
        body: queryArg.upgradeSubsDto,
      }),
    }),
    postApiPaymentAddNewBillingCard: build.mutation<
      PostApiPaymentAddNewBillingCardApiResponse,
      PostApiPaymentAddNewBillingCardApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/AddNewBillingCard`,
        method: 'POST',
        body: queryArg.billingCard,
      }),
    }),
    postApiPaymentDeleteBillingCardById: build.mutation<
      PostApiPaymentDeleteBillingCardByIdApiResponse,
      PostApiPaymentDeleteBillingCardByIdApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/DeleteBillingCardById`,
        method: 'POST',
        params: {stripePayementId: queryArg.stripePayementId},
      }),
    }),
    postApiPaymentGetPaymentsByStatus: build.mutation<
      PostApiPaymentGetPaymentsByStatusApiResponse,
      PostApiPaymentGetPaymentsByStatusApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/GetPaymentsByStatus`,
        method: 'POST',
        body: queryArg.paginationDto,
      }),
    }),
    postApiPaymentCancelSubscription: build.mutation<
      PostApiPaymentCancelSubscriptionApiResponse,
      PostApiPaymentCancelSubscriptionApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/CancelSubscription`,
        method: 'POST',
        body: queryArg.cancelPolicyDto,
      }),
    }),
    postApiPaymentSavePaymentsResponse: build.mutation<
      PostApiPaymentSavePaymentsResponseApiResponse,
      PostApiPaymentSavePaymentsResponseApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/SavePaymentsResponse`,
        method: 'POST',
        body: queryArg.testPayment,
      }),
    }),
    postApiPaymentCreateTestPaymentIntent: build.mutation<
      PostApiPaymentCreateTestPaymentIntentApiResponse,
      PostApiPaymentCreateTestPaymentIntentApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/CreateTestPaymentIntent`,
        method: 'POST',
        body: queryArg.paymentIntentDto,
      }),
    }),
    postApiPaymentCreateTestPaymentMethod: build.mutation<
      PostApiPaymentCreateTestPaymentMethodApiResponse,
      PostApiPaymentCreateTestPaymentMethodApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/CreateTestPaymentMethod`,
        method: 'POST',
        body: queryArg.testCard,
      }),
    }),
    postApiPaymentConfirmTestPayment: build.mutation<
      PostApiPaymentConfirmTestPaymentApiResponse,
      PostApiPaymentConfirmTestPaymentApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/ConfirmTestPayment`,
        method: 'POST',
        params: {
          PaymentMethodId: queryArg.paymentMethodId,
          IntentId: queryArg.intentId,
        },
      }),
    }),
    postApiPaymentGetRefundsByStatus: build.mutation<
      PostApiPaymentGetRefundsByStatusApiResponse,
      PostApiPaymentGetRefundsByStatusApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/GetRefundsByStatus`,
        method: 'POST',
        body: queryArg.refundPageDto,
      }),
    }),
    getApiPaymentGetRefundById: build.query<
      GetApiPaymentGetRefundByIdApiResponse,
      GetApiPaymentGetRefundByIdApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/GetRefundById`,
        params: {RefundId: queryArg.refundId},
      }),
    }),
    postApiPaymentSubscriptionRefunds: build.mutation<
      PostApiPaymentSubscriptionRefundsApiResponse,
      PostApiPaymentSubscriptionRefundsApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/SubscriptionRefunds`,
        method: 'POST',
        params: {subId: queryArg.subId},
      }),
    }),
    getApiPaymentGetPromoCodeInfo: build.query<
      GetApiPaymentGetPromoCodeInfoApiResponse,
      GetApiPaymentGetPromoCodeInfoApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/GetPromoCodeInfo`,
        params: {code: queryArg.code},
      }),
    }),
    getApiPaymentIncrementCouponRedeemCount: build.query<
      GetApiPaymentIncrementCouponRedeemCountApiResponse,
      GetApiPaymentIncrementCouponRedeemCountApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/IncrementCouponRedeemCount`,
        params: {couponid: queryArg.couponid},
      }),
    }),
    postApiPaymentInvoiceWebhook: build.mutation<
      PostApiPaymentInvoiceWebhookApiResponse,
      PostApiPaymentInvoiceWebhookApiArg
    >({
      query: () => ({url: `/api/Payment/InvoiceWebhook`, method: 'POST'}),
    }),
    postApiPaymentCouponWebhook: build.mutation<
      PostApiPaymentCouponWebhookApiResponse,
      PostApiPaymentCouponWebhookApiArg
    >({
      query: () => ({url: `/api/Payment/CouponWebhook`, method: 'POST'}),
    }),
    postApiPaymentSaveSubscriptionDetails: build.mutation<
      PostApiPaymentSaveSubscriptionDetailsApiResponse,
      PostApiPaymentSaveSubscriptionDetailsApiArg
    >({
      query: queryArg => ({
        url: `/api/Payment/SaveSubscriptionDetails`,
        method: 'POST',
        params: {SubId: queryArg.subId},
      }),
    }),
    postApiPaymentSubscriptionWebhook: build.mutation<
      PostApiPaymentSubscriptionWebhookApiResponse,
      PostApiPaymentSubscriptionWebhookApiArg
    >({
      query: () => ({url: `/api/Payment/SubscriptionWebhook`, method: 'POST'}),
    }),
    postApiUserLogin: build.mutation<
      PostApiUserLoginApiResponse,
      PostApiUserLoginApiArg
    >({
      query: queryArg => ({
        url: `/api/User/Login`,
        method: 'POST',
        body: queryArg.login,
      }),
    }),
    postApiUserSignUpUser: build.mutation<
      PostApiUserSignUpUserApiResponse,
      PostApiUserSignUpUserApiArg
    >({
      query: queryArg => ({
        url: `/api/User/SignUpUser`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiUserForgetPassword: build.mutation<
      PostApiUserForgetPasswordApiResponse,
      PostApiUserForgetPasswordApiArg
    >({
      query: queryArg => ({
        url: `/api/User/ForgetPassword`,
        method: 'POST',
        params: {email: queryArg.email},
      }),
    }),
    postApiUserUpdateCustomerProfile: build.mutation<
      PostApiUserUpdateCustomerProfileApiResponse,
      PostApiUserUpdateCustomerProfileApiArg
    >({
      query: queryArg => ({
        url: `/api/User/UpdateCustomerProfile`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiUserGetCustomerProfile: build.query<
      GetApiUserGetCustomerProfileApiResponse,
      GetApiUserGetCustomerProfileApiArg
    >({
      query: () => ({url: `/api/User/GetCustomerProfile`}),
    }),
    postApiUserChangePassword: build.mutation<
      PostApiUserChangePasswordApiResponse,
      PostApiUserChangePasswordApiArg
    >({
      query: queryArg => ({
        url: `/api/User/ChangePassword`,
        method: 'POST',
        body: queryArg.password,
      }),
    }),
    postApiUserResetPassword: build.mutation<
      PostApiUserResetPasswordApiResponse,
      PostApiUserResetPasswordApiArg
    >({
      query: queryArg => ({
        url: `/api/User/ResetPassword`,
        method: 'POST',
        body: queryArg.resetPassDto,
      }),
    }),
    postApiUserVerifyEmail: build.mutation<
      PostApiUserVerifyEmailApiResponse,
      PostApiUserVerifyEmailApiArg
    >({
      query: queryArg => ({
        url: `/api/User/VerifyEmail`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiUserResendVerificationEmail: build.mutation<
      PostApiUserResendVerificationEmailApiResponse,
      PostApiUserResendVerificationEmailApiArg
    >({
      query: queryArg => ({
        url: `/api/User/ResendVerificationEmail`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiUserVerifyPhoneNumber: build.mutation<
      PostApiUserVerifyPhoneNumberApiResponse,
      PostApiUserVerifyPhoneNumberApiArg
    >({
      query: queryArg => ({
        url: `/api/User/VerifyPhoneNumber`,
        method: 'POST',
        params: {phone: queryArg.phone},
      }),
    }),
    postApiUserVerifyPhoneCode: build.mutation<
      PostApiUserVerifyPhoneCodeApiResponse,
      PostApiUserVerifyPhoneCodeApiArg
    >({
      query: queryArg => ({
        url: `/api/User/VerifyPhoneCode`,
        method: 'POST',
        params: {code: queryArg.code, phone: queryArg.phone},
      }),
    }),
    postApiUserDeleteAccount: build.mutation<
      PostApiUserDeleteAccountApiResponse,
      PostApiUserDeleteAccountApiArg
    >({
      query: queryArg => ({
        url: `/api/User/DeleteAccount`,
        method: 'POST',
        body: queryArg.deleteVm,
      }),
    }),
    postApiUserRemoveDeviceToken: build.mutation<
      PostApiUserRemoveDeviceTokenApiResponse,
      PostApiUserRemoveDeviceTokenApiArg
    >({
      query: queryArg => ({
        url: `/api/User/RemoveDeviceToken`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiUserCheckLiveEnvironment: build.query<
      GetApiUserCheckLiveEnvironmentApiResponse,
      GetApiUserCheckLiveEnvironmentApiArg
    >({
      query: () => ({url: `/api/User/CheckLiveEnvironment`}),
    }),
    getApiUserTest500Error: build.query<
      GetApiUserTest500ErrorApiResponse,
      GetApiUserTest500ErrorApiArg
    >({
      query: () => ({url: `/api/User/Test500Error`}),
    }),
    postApiUserVerifyPkPhoneNumber: build.mutation<
      PostApiUserVerifyPkPhoneNumberApiResponse,
      PostApiUserVerifyPkPhoneNumberApiArg
    >({
      query: queryArg => ({
        url: `/api/User/VerifyPKPhoneNumber`,
        method: 'POST',
        params: {phone: queryArg.phone},
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as NodukAdmnApi};
export type PostApiAdminGetUserByRoleIdApiResponse = unknown;
export type PostApiAdminGetUserByRoleIdApiArg = {
  userReq: UserReq;
};
export type GetApiAdminGetTutorialLinksApiResponse = unknown;
export type GetApiAdminGetTutorialLinksApiArg = void;
export type PostApiAdminJoinTeamApiResponse = unknown;
export type PostApiAdminJoinTeamApiArg = {
  joinTeamDto: JoinTeamDto;
};
export type PostApiAdminUpdateAgentStatusApiResponse = unknown;
export type PostApiAdminUpdateAgentStatusApiArg = {
  agentStatusDto: AgentStatusDto;
};
export type PostApiAdminRequestDocumentsApiResponse = unknown;
export type PostApiAdminRequestDocumentsApiArg = {
  requestDocsDto: RequestDocsDto;
};
export type PostApiAdminVerifyUploadLinkApiResponse = unknown;
export type PostApiAdminVerifyUploadLinkApiArg = {
  emp?: string;
};
export type PostApiAdminUploadDocumentsApiResponse = unknown;
export type PostApiAdminUploadDocumentsApiArg = {
  body: {
    FirstName?: string;
    LastName?: string;
    EmployeeId?: string;
    W9Form: Blob[];
    EFT: Blob[];
    License: Blob[];
    Others?: Blob[];
  };
};
export type PostApiAdminVerifyDuplicateEmailApiResponse = unknown;
export type PostApiAdminVerifyDuplicateEmailApiArg = {
  email?: string;
};
export type GetApiAdminDownloadStaffFilesApiResponse = unknown;
export type GetApiAdminDownloadStaffFilesApiArg = {
  empId?: number;
};
export type PostApiAdminRegisterAgentApiResponse = unknown;
export type PostApiAdminRegisterAgentApiArg = {
  adminEmployeeDto: AdminEmployeeDto;
};
export type PostApiAdminSetAdminPasswordApiResponse = unknown;
export type PostApiAdminSetAdminPasswordApiArg = {
  resetPassDto: ResetPassDto;
};
export type PostApiAdminLoginApiResponse = unknown;
export type PostApiAdminLoginApiArg = {
  login: Login;
};
export type GetApiAdminForgetPasswordApiResponse = unknown;
export type GetApiAdminForgetPasswordApiArg = {
  email: string;
};
export type PostApiAdminResetPasswordApiResponse = unknown;
export type PostApiAdminResetPasswordApiArg = {
  resetPassDto: ResetPassDto;
};
export type GetApiAdminGetDashboardStatsApiResponse = unknown;
export type GetApiAdminGetDashboardStatsApiArg = void;
export type GetApiAdminGetProfileApiResponse = unknown;
export type GetApiAdminGetProfileApiArg = void;
export type PostApiAdminUpdateProfileApiResponse = unknown;
export type PostApiAdminUpdateProfileApiArg = {
  body: {
    EmployeeId?: number;
    EmployeeRoleId?: number;
    ContactId?: number;
    RoleId?: number;
    FirstName: string;
    LastName: string;
    Address: string;
    City: string;
    State: string;
    Zip: string;
    Phone: string;
    Email: string;
    Website?: string;
    IsActive?: boolean;
    Password?: string;
    ProfilePic?: Blob;
    ImageURL?: string;
    CompanyName?: string;
    TaxId?: string;
    AgencyId?: number;
  };
};
export type GetApiAdminVerifyEmployeeApiResponse = unknown;
export type GetApiAdminVerifyEmployeeApiArg = {
  token?: string;
};
export type PostApiAdminChangePasswordApiResponse = unknown;
export type PostApiAdminChangePasswordApiArg = {
  password: Password;
};
export type GetApiAdminGetAllAgentsApiResponse = unknown;
export type GetApiAdminGetAllAgentsApiArg = void;
export type PostApiAdminGetAllUsersApiResponse = unknown;
export type PostApiAdminGetAllUsersApiArg = {
  searchUser: SearchUser;
};
export type PostApiAdminGetUsersDropdownListApiResponse = unknown;
export type PostApiAdminGetUsersDropdownListApiArg = {
  dropDownSearch: DropDownSearch;
};
export type PostApiAdminAddUserApiResponse = unknown;
export type PostApiAdminAddUserApiArg = {
  signupDto: SignupDto;
};
export type GetApiAdminActivateDeactivateUserApiResponse = unknown;
export type GetApiAdminActivateDeactivateUserApiArg = {
  signupId?: number;
  status?: boolean;
};
export type GetApiAdminGenerateForgetPasswordLinkApiResponse = unknown;
export type GetApiAdminGenerateForgetPasswordLinkApiArg = {
  email?: string;
};
export type PostApiAdminRemoveEmpTokenApiResponse = unknown;
export type PostApiAdminRemoveEmpTokenApiArg = {
  body: string;
};
export type PostApiAdminGetAllInvoicesApiResponse = unknown;
export type PostApiAdminGetAllInvoicesApiArg = {
  adminInvoices: AdminInvoices;
};
export type PostApiAdminGetCarrierByIdApiResponse = unknown;
export type PostApiAdminGetCarrierByIdApiArg = {
  id?: number;
};
export type PostApiAdminAddUpdateCarrierApiResponse = unknown;
export type PostApiAdminAddUpdateCarrierApiArg = {
  carrier: Carrier;
};
export type PostApiAdminActivateDeactivateCarrierApiResponse = unknown;
export type PostApiAdminActivateDeactivateCarrierApiArg = {
  id?: number;
  status?: boolean;
};
export type PostApiAdminGetAllCarriersApiResponse = unknown;
export type PostApiAdminGetAllCarriersApiArg = {
  carrierResponse: CarrierResponse;
};
export type PostApiAdminGetAllClaimsApiResponse = unknown;
export type PostApiAdminGetAllClaimsApiArg = {
  claimsResponse: ClaimsResponse;
};
export type GetApiAdminGetClaimDetailApiResponse = unknown;
export type GetApiAdminGetClaimDetailApiArg = {
  claimId?: number;
};
export type PostApiAdminUpdateClaimInfoApiResponse = unknown;
export type PostApiAdminUpdateClaimInfoApiArg = {
  body: {
    EmployeeId?: number;
    ClaimId?: number;
    Notes?: string;
    Status?: number;
    DateSettled?: string;
    ServiceProviderId?: number;
    ServiceProviderName?: string;
    UpdateDocs?: Blob[];
  };
};
export type PostApiAdminClaimDocZipApiResponse = unknown;
export type PostApiAdminClaimDocZipApiArg = {
  claimId?: number;
  signupId?: number;
};
export type GetApiAdminGetEmployeeByIdApiResponse = unknown;
export type GetApiAdminGetEmployeeByIdApiArg = {
  employeeId?: number;
};
export type GetApiAdminActivateDeactivateEmployeeApiResponse = unknown;
export type GetApiAdminActivateDeactivateEmployeeApiArg = {
  employeeId?: number;
  status?: boolean;
};
export type PostApiAdminUpdateEmployeeByIdApiResponse = unknown;
export type PostApiAdminUpdateEmployeeByIdApiArg = {
  adminEmployeeDto: AdminEmployeeDto;
};
export type PostApiAdminGetAllEmployeesApiResponse = unknown;
export type PostApiAdminGetAllEmployeesApiArg = {
  employee: Employee;
};
export type PostApiAdminAddNewEmployeeApiResponse = unknown;
export type PostApiAdminAddNewEmployeeApiArg = {
  adminEmployeeDto: AdminEmployeeDto;
};
export type GetApiAdminGetEmpForgetLinkApiResponse = unknown;
export type GetApiAdminGetEmpForgetLinkApiArg = {
  email?: string;
};
export type GetApiAdminGetEmpActivationLinkApiResponse = unknown;
export type GetApiAdminGetEmpActivationLinkApiArg = {
  employeeId?: number;
};
export type GetApiAdminGetRoleBasedAgenciesListApiResponse =
  /** status 200 Success */ AgencyDto[];
export type GetApiAdminGetRoleBasedAgenciesListApiArg = {
  agencyNameLetters?: string;
};
export type PostApiAdminGetAllCouponCodeApiResponse = unknown;
export type PostApiAdminGetAllCouponCodeApiArg = {
  couponRequestDto: CouponRequestDto;
};
export type PostApiAdminAddCouponCodeApiResponse = unknown;
export type PostApiAdminAddCouponCodeApiArg = {
  agentCouponCode: AgentCouponCode;
};
export type GetApiAdminUpdateCouponApiResponse = unknown;
export type GetApiAdminUpdateCouponApiArg = {
  couponId?: string;
  description?: string;
};
export type GetApiAdminVerifyPromotionCodeApiResponse = unknown;
export type GetApiAdminVerifyPromotionCodeApiArg = {
  promotionCode: string;
};
export type GetApiAdminActiveDeactivePromotionCodeApiResponse = unknown;
export type GetApiAdminActiveDeactivePromotionCodeApiArg = {
  couponId: string;
  status: boolean;
};
export type PostApiAdminShareQrCodeApiResponse = unknown;
export type PostApiAdminShareQrCodeApiArg = {
  body: {
    email?: string[];
    qrImage?: Blob;
  };
};
export type GetApiAdminQrCodeApiResponse = unknown;
export type GetApiAdminQrCodeApiArg = {
  shortUrl?: string;
};
export type GetApiAdminGetQrCodeUrlApiResponse = unknown;
export type GetApiAdminGetQrCodeUrlApiArg = void;
export type PostApiAdminUploadBrochureApiResponse = unknown;
export type PostApiAdminUploadBrochureApiArg = void;
export type PostApiAdminAddQrCodeApiResponse = unknown;
export type PostApiAdminAddQrCodeApiArg = {
  body: {
    qrCode?: Blob;
  };
};
export type PostApiAdminGetAllSubscriptionsApiResponse = unknown;
export type PostApiAdminGetAllSubscriptionsApiArg = {
  adminSubscriptions: AdminSubscriptions;
};
export type GetApiAdminGetAdminSubscriptionsApiResponse = unknown;
export type GetApiAdminGetAdminSubscriptionsApiArg = {
  subId?: number;
};
export type GetApiAdminGetRefundDetailApiResponse = unknown;
export type GetApiAdminGetRefundDetailApiArg = {
  refundId?: number;
  signupId?: number;
};
export type PostApiAdminCalculateCommissionApiResponse = unknown;
export type PostApiAdminCalculateCommissionApiArg = {
  adminDashboard: AdminDashboard;
};
export type PostApiAdminGetAgencyCommissionedAgentsApiResponse = unknown;
export type PostApiAdminGetAgencyCommissionedAgentsApiArg = {
  adminDashboard: AdminDashboard;
};
export type GetApiAdminGetAgentCommissionRateApiResponse = unknown;
export type GetApiAdminGetAgentCommissionRateApiArg = {
  empId?: number;
  roleId?: number;
};
export type PostApiAdminAddEditCommissionRateApiResponse = unknown;
export type PostApiAdminAddEditCommissionRateApiArg = {
  agentCommissionRateDto: AgentCommissionRateDto;
};
export type PostApiAdminGetAdminDashboardApiResponse = unknown;
export type PostApiAdminGetAdminDashboardApiArg = {
  adminDashboard: AdminDashboard;
};
export type PostApiAdminGetDashboardOverviewApiResponse = unknown;
export type PostApiAdminGetDashboardOverviewApiArg = {
  adminDashboard: AdminDashboard;
};
export type PostApiAdminGetDashboardRevenuestatsApiResponse = unknown;
export type PostApiAdminGetDashboardRevenuestatsApiArg = {
  adminDashboard: AdminDashboard;
};
export type PostApiAdminGetSubsClaimsStatsApiResponse = unknown;
export type PostApiAdminGetSubsClaimsStatsApiArg = {
  adminDashboard: AdminDashboard;
};
export type PostApiAdminGetUserRolesApiResponse = unknown;
export type PostApiAdminGetUserRolesApiArg = void;
export type PostApiAdminCustomerAddCartitemApiResponse = unknown;
export type PostApiAdminCustomerAddCartitemApiArg = {
  cartItem: CartItem;
};
export type GetApiAdminCustomerGetCartitemApiResponse = unknown;
export type GetApiAdminCustomerGetCartitemApiArg = {
  cartItemId: number;
  signupId?: number;
};
export type GetApiAdminCustomerGetCartitemListApiResponse = unknown;
export type GetApiAdminCustomerGetCartitemListApiArg = {
  signupId: number;
};
export type GetApiAdminCustomerRemoveCartitemApiResponse = unknown;
export type GetApiAdminCustomerRemoveCartitemApiArg = {
  cartItemId: number;
};
export type GetApiAdminCustomerRemoveAllCartitemsApiResponse = unknown;
export type GetApiAdminCustomerRemoveAllCartitemsApiArg = {
  signupId: number;
};
export type GetApiAdminCustomerGetPaymentPlansApiResponse = unknown;
export type GetApiAdminCustomerGetPaymentPlansApiArg = {
  signupId: number;
};
export type GetApiAdminCustomerGetCompleteCheckoutApiResponse = unknown;
export type GetApiAdminCustomerGetCompleteCheckoutApiArg = {
  signupId?: number;
};
export type GetApiAdminCustomerApplyCouponCodeApiResponse = unknown;
export type GetApiAdminCustomerApplyCouponCodeApiArg = {
  signupId?: number;
  couponCode?: string;
};
export type GetApiAdminCustomerGetUserBillingcardsApiResponse = unknown;
export type GetApiAdminCustomerGetUserBillingcardsApiArg = {
  signupId: number;
};
export type PostApiAdminCustomerAddUserBillingcardApiResponse = unknown;
export type PostApiAdminCustomerAddUserBillingcardApiArg = {
  billingCard: BillingCard;
};
export type GetApiAdminCustomerGetCheckoutDetailsApiResponse = unknown;
export type GetApiAdminCustomerGetCheckoutDetailsApiArg = {
  signupId: number;
};
export type PostApiAdminCustomerCreateSubscriptionApiResponse = unknown;
export type PostApiAdminCustomerCreateSubscriptionApiArg = {
  checkoutDto: CheckoutDto;
};
export type PostApiAdminCustomerCreateSubscriptionV3ApiResponse = unknown;
export type PostApiAdminCustomerCreateSubscriptionV3ApiArg = {
  checkoutDto: CheckoutDto;
};
export type PostApiAdminCustomerSaveSubscriptionDetailApiResponse = unknown;
export type PostApiAdminCustomerSaveSubscriptionDetailApiArg = {
  saveSubscriptionDto: SaveSubscriptionDto;
};
export type GetApiAdminCustomerGetCustomerProfileApiResponse = unknown;
export type GetApiAdminCustomerGetCustomerProfileApiArg = {
  signUpId?: number;
};
export type GetApiAdminCustomerGetCustomerDetailApiResponse = unknown;
export type GetApiAdminCustomerGetCustomerDetailApiArg = {
  signUpId?: number;
};
export type PostApiAdminCustomerUpdateCustomerInfoApiResponse = unknown;
export type PostApiAdminCustomerUpdateCustomerInfoApiArg = {
  customerDto: CustomerDto;
};
export type PostApiAdminCustomerGetCustomerPoliciesApiResponse = unknown;
export type PostApiAdminCustomerGetCustomerPoliciesApiArg = {
  policiesPageDto: PoliciesPageDto;
};
export type PostApiAdminCustomerGetCustomerClaimsApiResponse = unknown;
export type PostApiAdminCustomerGetCustomerClaimsApiArg = {
  claimPageDto: ClaimPageDto;
};
export type PostApiAdminCustomerGetCustomerInvoicesApiResponse = unknown;
export type PostApiAdminCustomerGetCustomerInvoicesApiArg = {
  invoicePageDto: InvoicePageDto;
};
export type PostApiAdminCustomerGetCustomerSubscriptionsApiResponse = unknown;
export type PostApiAdminCustomerGetCustomerSubscriptionsApiArg = {
  subscPageDto: SubscPageDto;
};
export type GetApiAdminCustomerGetPolicyDetailApiResponse = unknown;
export type GetApiAdminCustomerGetPolicyDetailApiArg = {
  itemId?: number;
  signupId?: number;
};
export type GetApiAdminCustomerGetClaimDetailApiResponse = unknown;
export type GetApiAdminCustomerGetClaimDetailApiArg = {
  claimId?: number;
  signupId?: number;
};
export type GetApiAdminCustomerGetSubscDetailApiResponse = unknown;
export type GetApiAdminCustomerGetSubscDetailApiArg = {
  subId?: number;
  signupId?: number;
};
export type GetApiAdminCustomerGetInvoiceDetailApiResponse = unknown;
export type GetApiAdminCustomerGetInvoiceDetailApiArg = {
  invId?: number;
  signupId?: number;
};
export type PostApiAdminCustomerAddClaimApiResponse = unknown;
export type PostApiAdminCustomerAddClaimApiArg = {
  body: {
    DateLoss?: string;
    DateFiled?: string;
    CartItemId?: number;
    UserNotes?: string;
    ClaimAmount?: number;
    ServiceProviderId?: number;
    ServiceProviderName?: string;
    DriverLicense?: Blob[];
    RegisterDoc?: Blob[];
    InsuranceDoc?: Blob[];
    ClaimNoDoc?: Blob[];
    AccidentReport?: Blob[];
    OtherDoc?: Blob[];
    signupId?: number;
  };
};
export type PostApiAdminCustomerCancelCustomerPolicyApiResponse = unknown;
export type PostApiAdminCustomerCancelCustomerPolicyApiArg = {
  cancelPolicyDto: CancelPolicyDto;
};
export type PostApiAdminCustomerCancelCustomerPolicyV2ApiResponse = unknown;
export type PostApiAdminCustomerCancelCustomerPolicyV2ApiArg = {
  cancelPolicyDto: CancelPolicyDto;
};
export type PostApiAdminCustomerCancelSubscriptionApiResponse = unknown;
export type PostApiAdminCustomerCancelSubscriptionApiArg = {
  cancelPolicyDto: CancelPolicyDto;
};
export type PostApiAdminCustomerSetCustomerPasswordApiResponse = unknown;
export type PostApiAdminCustomerSetCustomerPasswordApiArg = {
  resetPassDto: ResetPassDto;
};
export type PostApiAuditLogsGetAllNotificationLogsApiResponse = unknown;
export type PostApiAuditLogsGetAllNotificationLogsApiArg = {
  logPageDto: LogPageDto;
};
export type GetApiAuditLogsGetNotificationTokensApiResponse = unknown;
export type GetApiAuditLogsGetNotificationTokensApiArg = {
  logId?: string;
};
export type GetApiAuditLogsDeleteNotificationByIdApiResponse = unknown;
export type GetApiAuditLogsDeleteNotificationByIdApiArg = {
  id?: string;
};
export type PostApiAuditLogsGetAllEmailLogsApiResponse = unknown;
export type PostApiAuditLogsGetAllEmailLogsApiArg = {
  logPageDto: LogPageDto;
};
export type GetApiAuditLogsGetEmailLogByIdApiResponse = unknown;
export type GetApiAuditLogsGetEmailLogByIdApiArg = {
  logId?: string;
};
export type PostApiAuditLogsGetAllAuditLogsApiResponse = unknown;
export type PostApiAuditLogsGetAllAuditLogsApiArg = {
  auditLogReq: AuditLogReq;
};
export type GetApiAuditLogsGetAuditLogDetailsApiResponse = unknown;
export type GetApiAuditLogsGetAuditLogDetailsApiArg = {
  logId?: string;
};
export type GetApiCustomerGetTotalCoveragesApiResponse = unknown;
export type GetApiCustomerGetTotalCoveragesApiArg = void;
export type GetApiCustomerGetCarriersMakersByTypeApiResponse = unknown;
export type GetApiCustomerGetCarriersMakersByTypeApiArg = {
  serviceTypeId?: number;
};
export type GetApiCustomerGetVehicleModelListByIdApiResponse = unknown;
export type GetApiCustomerGetVehicleModelListByIdApiArg = {
  serviceTypeId?: number;
  makeId?: number;
};
export type GetApiCustomerGetDeductibleListApiResponse = unknown;
export type GetApiCustomerGetDeductibleListApiArg = {
  isDisaster?: boolean;
};
export type GetApiCustomerGetCartCountApiResponse = unknown;
export type GetApiCustomerGetCartCountApiArg = void;
export type PostApiCustomerAddCartItemApiResponse = unknown;
export type PostApiCustomerAddCartItemApiArg = {
  cartItem: CartItem;
};
export type GetApiCustomerGetCartItemByIdApiResponse = unknown;
export type GetApiCustomerGetCartItemByIdApiArg = {
  cartItemId?: number;
};
export type PostApiCustomerRemoveCartItemByIdApiResponse = unknown;
export type PostApiCustomerRemoveCartItemByIdApiArg = {
  cartItemId?: string;
};
export type PostApiCustomerRemoveAllCartItemsApiResponse = unknown;
export type PostApiCustomerRemoveAllCartItemsApiArg = void;
export type GetApiCustomerGetCartItemListApiResponse = unknown;
export type GetApiCustomerGetCartItemListApiArg = void;
export type GetApiCustomerGetCheckOutCartItemsByIdApiResponse = unknown;
export type GetApiCustomerGetCheckOutCartItemsByIdApiArg = void;
export type GetApiCustomerGetCompleteCheckOutApiResponse = unknown;
export type GetApiCustomerGetCompleteCheckOutApiArg = void;
export type GetApiCustomerApplyCouponCodeApiResponse = unknown;
export type GetApiCustomerApplyCouponCodeApiArg = {
  couponCode?: string;
};
export type GetApiCustomerGetCardPaymentInfoApiResponse = unknown;
export type GetApiCustomerGetCardPaymentInfoApiArg = void;
export type PostApiCustomerGetPoliciesApiResponse = unknown;
export type PostApiCustomerGetPoliciesApiArg = {
  policiesPageDto: PoliciesPageDto;
};
export type PostApiCustomerGetClaimsByStatusApiResponse = unknown;
export type PostApiCustomerGetClaimsByStatusApiArg = {
  claimPageDto: ClaimPageDto;
};
export type GetApiCustomerGetClaimsCountByTypeByIdApiResponse = unknown;
export type GetApiCustomerGetClaimsCountByTypeByIdApiArg = void;
export type GetApiCustomerGetClaimsByPolicyIdApiResponse = unknown;
export type GetApiCustomerGetClaimsByPolicyIdApiArg = {
  cartItemId?: number;
};
export type PostApiCustomerAddNewClaimsApiResponse = unknown;
export type PostApiCustomerAddNewClaimsApiArg = {
  body: {
    DateLoss?: string;
    DateFiled?: string;
    CartItemId?: number;
    UserNotes?: string;
    ClaimAmount?: number;
    ServiceProviderId?: number;
    ServiceProviderName?: string;
    DriverLicense?: Blob[];
    RegisterDoc?: Blob[];
    InsuranceDoc?: Blob[];
    ClaimNoDoc?: Blob[];
    AccidentReport?: Blob[];
    OtherDoc?: Blob[];
  };
};
export type PostApiCustomerSaveFeedBackApiResponse = unknown;
export type PostApiCustomerSaveFeedBackApiArg = {
  feedbackDto: FeedbackDto;
};
export type GetApiCustomerGetClaimDetailByIdApiResponse = unknown;
export type GetApiCustomerGetClaimDetailByIdApiArg = {
  claimId?: number;
};
export type GetApiCustomerGetNodukClaimDetailApiResponse = unknown;
export type GetApiCustomerGetNodukClaimDetailApiArg = {
  claimId?: number;
};
export type GetApiCustomerGetInvoiceDetailByIdApiResponse = unknown;
export type GetApiCustomerGetInvoiceDetailByIdApiArg = {
  invoiceId?: number;
};
export type PostApiCustomerGetAllInvoicesByStatusApiResponse = unknown;
export type PostApiCustomerGetAllInvoicesByStatusApiArg = {
  invoicePageDto: InvoicePageDto;
};
export type GetApiCustomerVerifyZipCodeApiResponse = unknown;
export type GetApiCustomerVerifyZipCodeApiArg = {
  zipCode?: string;
};
export type PostApiDisasterGetDisasterAreasListApiResponse = unknown;
export type PostApiDisasterGetDisasterAreasListApiArg = {
  disasterPageDto: DisasterPageDto;
};
export type GetApiDisasterGetRiskLevelsListApiResponse = unknown;
export type GetApiDisasterGetRiskLevelsListApiArg = void;
export type PostApiDisasterAddUpdateDisasterAreaApiResponse = unknown;
export type PostApiDisasterAddUpdateDisasterAreaApiArg = {
  disasterReq: DisasterReq;
};
export type GetApiDisasterActiveDeactiveDisasterAreaApiResponse = unknown;
export type GetApiDisasterActiveDeactiveDisasterAreaApiArg = {
  id: number;
  status: boolean;
};
export type PostApiDisasterImportDisasterAreaApiResponse = unknown;
export type PostApiDisasterImportDisasterAreaApiArg = {
  body: {
    file: Blob;
  };
};
export type PostApiDisasterAddEditDisasterRiskApiResponse = unknown;
export type PostApiDisasterAddEditDisasterRiskApiArg = {
  addDisasterRisk: AddDisasterRisk;
};
export type GetApiDisasterActiveInactiveDisasterRiskApiResponse = unknown;
export type GetApiDisasterActiveInactiveDisasterRiskApiArg = {
  riskId?: string;
  isActive?: boolean;
};
export type GetApiFaqGetFaQsListApiResponse = unknown;
export type GetApiFaqGetFaQsListApiArg = void;
export type PostApiNotificationSendNotificationApiResponse = unknown;
export type PostApiNotificationSendNotificationApiArg = {
  notificationDto: NotificationDto;
};
export type GetApiNotificationGetEmployeeNotificationsApiResponse = unknown;
export type GetApiNotificationGetEmployeeNotificationsApiArg = void;
export type GetApiNotificationUpdateAdminNotificationStatusApiResponse =
  unknown;
export type GetApiNotificationUpdateAdminNotificationStatusApiArg = {
  notifyId?: number;
};
export type GetApiNotificationGetCustomerNotificationApiResponse = unknown;
export type GetApiNotificationGetCustomerNotificationApiArg = void;
export type GetApiNotificationUpdateCustomerNotificationStatusApiResponse =
  unknown;
export type GetApiNotificationUpdateCustomerNotificationStatusApiArg = {
  notifyId?: number;
};
export type PostApiNotificationSendAdminNotificationApiResponse = unknown;
export type PostApiNotificationSendAdminNotificationApiArg = {
  notificationDto: NotificationDto;
};
export type DeleteApiNotificationDeleteOldNotificationsApiResponse = unknown;
export type DeleteApiNotificationDeleteOldNotificationsApiArg = {
  status: boolean;
};
export type GetApiNotificationGetEmployeeNotificationCountApiResponse = unknown;
export type GetApiNotificationGetEmployeeNotificationCountApiArg = void;
export type PostApiPaymentCreateCustomerSubscriptionV2ApiResponse = unknown;
export type PostApiPaymentCreateCustomerSubscriptionV2ApiArg = {
  checkoutDto: CheckoutDto;
};
export type PostApiPaymentCreateCustomerSubscriptionV3ApiResponse = unknown;
export type PostApiPaymentCreateCustomerSubscriptionV3ApiArg = {
  checkoutDto: CheckoutDto;
};
export type GetApiPaymentGetLinkedSubscriptionsApiResponse = unknown;
export type GetApiPaymentGetLinkedSubscriptionsApiArg = {
  stripePayementId?: string;
};
export type PostApiPaymentCancelPolicyByCartItemIdApiResponse = unknown;
export type PostApiPaymentCancelPolicyByCartItemIdApiArg = {
  cancelPolicyDto: CancelPolicyDto;
};
export type PostApiPaymentCancelPolicyByCartItemIdV2ApiResponse = unknown;
export type PostApiPaymentCancelPolicyByCartItemIdV2ApiArg = {
  cancelPolicyDto: CancelPolicyDto;
};
export type PostApiPaymentGetSubscriptionsByStatusApiResponse = unknown;
export type PostApiPaymentGetSubscriptionsByStatusApiArg = {
  subscPageDto: SubscPageDto;
};
export type GetApiPaymentGetSubscriptionsDetailsApiResponse = unknown;
export type GetApiPaymentGetSubscriptionsDetailsApiArg = {
  subId?: number;
};
export type PostApiPaymentUpdateSubscriptionCardApiResponse = unknown;
export type PostApiPaymentUpdateSubscriptionCardApiArg = {
  updateCardDto: UpdateCardDto;
};
export type GetApiPaymentGetSubscriptionPlansApiResponse = unknown;
export type GetApiPaymentGetSubscriptionPlansApiArg = {
  subId?: number;
};
export type PostApiPaymentUpgradeSubscriptionsApiResponse = unknown;
export type PostApiPaymentUpgradeSubscriptionsApiArg = {
  upgradeSubsDto: UpgradeSubsDto;
};
export type PostApiPaymentAddNewBillingCardApiResponse = unknown;
export type PostApiPaymentAddNewBillingCardApiArg = {
  billingCard: BillingCard;
};
export type PostApiPaymentDeleteBillingCardByIdApiResponse = unknown;
export type PostApiPaymentDeleteBillingCardByIdApiArg = {
  stripePayementId?: string;
};
export type PostApiPaymentGetPaymentsByStatusApiResponse = unknown;
export type PostApiPaymentGetPaymentsByStatusApiArg = {
  paginationDto: PaginationDto;
};
export type PostApiPaymentCancelSubscriptionApiResponse = unknown;
export type PostApiPaymentCancelSubscriptionApiArg = {
  cancelPolicyDto: CancelPolicyDto;
};
export type PostApiPaymentSavePaymentsResponseApiResponse = unknown;
export type PostApiPaymentSavePaymentsResponseApiArg = {
  testPayment: TestPayment;
};
export type PostApiPaymentCreateTestPaymentIntentApiResponse = unknown;
export type PostApiPaymentCreateTestPaymentIntentApiArg = {
  paymentIntentDto: PaymentIntentDto;
};
export type PostApiPaymentCreateTestPaymentMethodApiResponse = unknown;
export type PostApiPaymentCreateTestPaymentMethodApiArg = {
  testCard: TestCard;
};
export type PostApiPaymentConfirmTestPaymentApiResponse = unknown;
export type PostApiPaymentConfirmTestPaymentApiArg = {
  paymentMethodId?: string;
  intentId?: string;
};
export type PostApiPaymentGetRefundsByStatusApiResponse = unknown;
export type PostApiPaymentGetRefundsByStatusApiArg = {
  refundPageDto: RefundPageDto;
};
export type GetApiPaymentGetRefundByIdApiResponse = unknown;
export type GetApiPaymentGetRefundByIdApiArg = {
  refundId?: number;
};
export type PostApiPaymentSubscriptionRefundsApiResponse = unknown;
export type PostApiPaymentSubscriptionRefundsApiArg = {
  subId?: number;
};
export type GetApiPaymentGetPromoCodeInfoApiResponse = unknown;
export type GetApiPaymentGetPromoCodeInfoApiArg = {
  code: string;
};
export type GetApiPaymentIncrementCouponRedeemCountApiResponse = unknown;
export type GetApiPaymentIncrementCouponRedeemCountApiArg = {
  couponid?: string;
};
export type PostApiPaymentInvoiceWebhookApiResponse = unknown;
export type PostApiPaymentInvoiceWebhookApiArg = void;
export type PostApiPaymentCouponWebhookApiResponse = unknown;
export type PostApiPaymentCouponWebhookApiArg = void;
export type PostApiPaymentSaveSubscriptionDetailsApiResponse = unknown;
export type PostApiPaymentSaveSubscriptionDetailsApiArg = {
  subId?: string;
};
export type PostApiPaymentSubscriptionWebhookApiResponse = unknown;
export type PostApiPaymentSubscriptionWebhookApiArg = void;
export type PostApiUserLoginApiResponse = unknown;
export type PostApiUserLoginApiArg = {
  login: Login;
};
export type PostApiUserSignUpUserApiResponse = unknown;
export type PostApiUserSignUpUserApiArg = {
  body: {
    FirstName: string;
    LastName: string;
    AffiliateId?: number;
    AgentId?: string;
    BirthDate?: string;
    Address: string;
    City: string;
    State: string;
    Zip: string;
    Phone: string;
    Email: string;
    Password?: string;
    Profile?: Blob;
  };
};
export type PostApiUserForgetPasswordApiResponse = unknown;
export type PostApiUserForgetPasswordApiArg = {
  email?: string;
};
export type PostApiUserUpdateCustomerProfileApiResponse = unknown;
export type PostApiUserUpdateCustomerProfileApiArg = {
  body: {
    SignupId?: number;
    FirstName?: string;
    LastName?: string;
    BirthDate?: string;
    SignupDate?: string;
    Address?: string;
    City?: string;
    State?: string;
    Zip?: string;
    Phone?: string;
    Email?: string;
    IsPhoneConfirmed?: boolean;
    IsActive?: boolean;
    Profile?: Blob;
    AffiliateId?: number;
    ProfileURL?: string;
    LastSeen?: string;
    CustomerId?: string;
    StripeAccountId?: string;
    AccountId?: number;
    AgentId?: number;
    AgentName?: string;
    AgentRoleId?: number;
  };
};
export type GetApiUserGetCustomerProfileApiResponse = unknown;
export type GetApiUserGetCustomerProfileApiArg = void;
export type PostApiUserChangePasswordApiResponse = unknown;
export type PostApiUserChangePasswordApiArg = {
  password: Password;
};
export type PostApiUserResetPasswordApiResponse = unknown;
export type PostApiUserResetPasswordApiArg = {
  resetPassDto: ResetPassDto;
};
export type PostApiUserVerifyEmailApiResponse = unknown;
export type PostApiUserVerifyEmailApiArg = {
  body: string;
};
export type PostApiUserResendVerificationEmailApiResponse = unknown;
export type PostApiUserResendVerificationEmailApiArg = {
  body: string;
};
export type PostApiUserVerifyPhoneNumberApiResponse = unknown;
export type PostApiUserVerifyPhoneNumberApiArg = {
  phone?: string;
};
export type PostApiUserVerifyPhoneCodeApiResponse = unknown;
export type PostApiUserVerifyPhoneCodeApiArg = {
  code?: string;
  phone?: string;
};
export type PostApiUserDeleteAccountApiResponse = unknown;
export type PostApiUserDeleteAccountApiArg = {
  deleteVm: DeleteVm;
};
export type PostApiUserRemoveDeviceTokenApiResponse = unknown;
export type PostApiUserRemoveDeviceTokenApiArg = {
  body: string;
};
export type GetApiUserCheckLiveEnvironmentApiResponse = unknown;
export type GetApiUserCheckLiveEnvironmentApiArg = void;
export type GetApiUserTest500ErrorApiResponse = unknown;
export type GetApiUserTest500ErrorApiArg = void;
export type PostApiUserVerifyPkPhoneNumberApiResponse = unknown;
export type PostApiUserVerifyPkPhoneNumberApiArg = {
  phone?: string;
};
export type UserReq = {
  empId?: number;
  roleId?: number;
  id?: number;
  text?: string | null;
};
export type JoinTeamDto = {
  roleId: number;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  phone: string;
  email: string;
  website?: string | null;
  companyName?: string | null;
  taxId?: string | null;
  education?: {
    [key: string]: string | null;
  } | null;
  experience?:
    | {
        [key: string]: string;
      }[]
    | null;
  references?:
    | {
        [key: string]: string;
      }[]
    | null;
  acknowledgment?: {
    [key: string]: string | null;
  } | null;
};
export type AgentStatusDto = {
  employeeId?: number;
  status?: number;
  email?: string | null;
  name?: string | null;
  rejectionReason?: string | null;
};
export type RequestDocsDto = {
  employeeId: number;
  roleId: number;
  name: string;
  email: string;
  message: string;
};
export type AdminEmployeeDto = {
  employeeRoleId?: number;
  contactId?: number;
  roleId?: number;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  website?: string | null;
  isActive?: boolean;
  imageURL?: string | null;
  companyName?: string | null;
  taxId?: string | null;
  agencyId?: number;
};
export type ResetPassDto = {
  signupId?: string | null;
  employeeId?: string | null;
  token?: string | null;
  password?: string | null;
};
export type Login = {
  email: string;
  password: string;
  deviceToken?: string | null;
  deviceType?: string | null;
};
export type Password = {
  currentPassword: string;
  newPassword: string;
};
export type SearchUser = {
  name?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  role?: number;
  status?: number;
  agentid?: number;
  pageNumber?: number;
  pageSize?: number;
};
export type DropDownSearch = {
  signupId?: number;
  userName?: string | null;
};
export type SignupDto = {
  firstName: string;
  lastName: string;
  affiliateId?: number;
  agentId?: string | null;
  birthDate?: string | null;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  password?: string | null;
  profile?: Blob | null;
};
export type AdminInvoices = {
  invoiceId?: number;
  email?: string | null;
  status?: number;
  pageNumber?: number;
  pageSize?: number;
  isRefunded?: number;
};
export type Carrier = {
  id?: number;
  serviceTypeId: number;
  providerName: string;
  phone?: string | null;
  website?: string | null;
  status?: boolean;
};
export type CarrierResponse = {
  serviceTypeId?: number;
  providerName?: string | null;
  status?: number;
  pageNumber?: number;
  pageSize?: number;
};
export type ClaimsResponse = {
  pageNo?: number;
  pageSize?: number;
  claimNo?: number;
  claimType?: number;
  status?: number;
  email?: string | null;
};
export type Employee = {
  company?: string | null;
  email?: string | null;
  role?: number;
  status?: number;
  pageNo?: number;
  pageSize?: number;
};
export type AgencyDto = {
  employeeId?: number;
  agencyName?: string | null;
};
export type CouponRequestDto = {
  pageNo?: number;
  pageSize?: number;
  couponCode?: string | null;
  couponType?: number;
  startDate?: string | null;
  expireDate?: string | null;
  status?: number;
};
export type AgentCouponCode = {
  couponName?: string | null;
  couponType: number;
  couponDuration?: string | null;
  amountOff?: number | null;
  percentOff?: number | null;
  minimumCouponAmount?: number;
  validTill: string;
  redeemLimit: number;
  startDate: string;
  description?: string | null;
  promotionCode: string;
};
export type AdminSubscriptions = {
  subsId?: number;
  email?: string | null;
  type?: number;
  status?: number;
  pageNo?: number;
  pageSize?: number;
};
export type AdminDashboard = {
  empId?: number;
  roleId?: number;
  startDate?: string;
  endDate?: string;
  email?: string | null;
  pageNo?: number;
  rowsPerPage?: number;
};
export type AgentCommissionRateDto = {
  employeeId?: number;
  roleId?: number;
  commissionRateId?: number;
  autoRate?: number;
  homeRate?: number;
  commercialRate?: number;
  deviceRate?: number;
  disasterRate?: number;
};
export type CartItem = {
  signupId?: number;
  cartItemId?: number;
  serviceTypeId: number;
  deductibleAmount: number;
  serviceProviderId: number;
  otherServiceProvider?: string | null;
  make?: string | null;
  model?: string | null;
  brand?: string | null;
  year?: string | null;
  address?: string | null;
  isDisaster?: boolean;
  zipCode?: string | null;
  disasterDeductible?: number;
};
export type BillingCard = {
  signupId?: number;
  email?: string | null;
  paymentMethodId: string;
  nameOnCard: string;
};
export type CheckoutDto = {
  signupId?: number;
  email?: string | null;
  paymentMethodId?: string | null;
  subscriptionType: string;
  saveCard: boolean;
  nameOnCard?: string | null;
  newCard?: boolean;
  paymentPlatform?: number;
  promoCode?: string | null;
  couponType?: number;
};
export type SaveSubscriptionDto = {
  signupId?: number;
  email?: string | null;
  subscriptionId?: string | null;
};
export type CustomerDto = {
  signupId?: number;
  firstName?: string | null;
  lastName?: string | null;
  birthDate?: string | null;
  signupDate?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  phone?: string | null;
  email?: string | null;
  isPhoneConfirmed?: boolean;
  isActive?: boolean;
  profile?: Blob | null;
  affiliateId?: number;
  profileURL?: string | null;
  lastSeen?: string | null;
  customerId?: string | null;
  stripeAccountId?: string | null;
  accountId?: number;
  agentId?: number;
  agentName?: string | null;
  agentRoleId?: number;
};
export type PoliciesPageDto = {
  signupId?: number;
  pageNo?: number;
  rowsPerPage?: number;
  status?: number;
  id?: number;
  policyType?: number;
};
export type ClaimPageDto = {
  signupId?: number;
  pageNo?: number;
  rowsPerPage?: number;
  status?: number;
  id?: number;
  policyId?: number;
  policyType?: number;
};
export type InvoicePageDto = {
  signupId?: number;
  pageNo?: number;
  rowsPerPage?: number;
  status?: number;
  id?: number;
  subscriptionId?: number;
};
export type SubscPageDto = {
  signupId?: number;
  pageNo?: number;
  rowsPerPage?: number;
  status?: number;
  id?: number;
  subscriptionType?: string | null;
};
export type CancelPolicyDto = {
  signupId?: number;
  cartItemId?: number;
  cancelReason?: string | null;
  stripeSubscriptionId?: string | null;
  subscriptionId?: number;
};
export type LogPageDto = {
  pageNo?: number;
  pageSize?: number;
  email?: string | null;
  status?: string | null;
  moduleAction?: string | null;
};
export type AuditLogReq = {
  pageNo?: number;
  pageSize?: number;
  email?: string | null;
  moduleAction?: string | null;
  requestStatus?: number;
  role?: number;
};
export type FeedbackDto = {
  feedBackText?: string | null;
  feedBackDetail?: string | null;
  rating?: number;
};
export type DisasterPageDto = {
  zipCode?: number;
  riskLevel?: number;
  status?: number;
  pageNo?: number;
  pageSize?: number;
};
export type DisasterReq = {
  id?: number;
  zipCode?: string[] | null;
  riskId?: number;
  status?: boolean;
};
export type AddDisasterRisk = {
  riskId?: number;
  riskLevel?: string | null;
  percentage?: number;
};
export type NotificationDto = {
  notifyGroup?: number;
  title?: string | null;
  body?: string | null;
  sendDate?: string;
};
export type UpdateCardDto = {
  subscriptionId: number;
  stripeSubscriptionId: string;
  paymentMethodId: string;
  nameOnCard?: string | null;
  newCard?: boolean;
};
export type UpgradeSubsDto = {
  planId?: number;
  subscriptionId?: number;
  stripeSubId?: string | null;
};
export type PaginationDto = {
  signupId?: number;
  pageNo?: number;
  rowsPerPage?: number;
  status?: number;
  id?: number;
};
export type TestPayment = {
  platform?: string | null;
  response?: string | null;
};
export type PaymentIntentDto = {
  amount?: number;
  currency?: string | null;
  isLive?: boolean;
};
export type TestCard = {
  cardNumber?: string | null;
  expireMonth?: string | null;
  expireYear?: string | null;
  cvc?: string | null;
};
export type RefundPageDto = {
  signupId?: number;
  pageNo?: number;
  rowsPerPage?: number;
  status?: number;
  id?: number;
  subscriptionId?: number;
  refundType?: number;
};
export type DeleteVm = {
  deleteType: number;
  deleteReason: string;
  password: string;
};
export const {
  usePostApiAdminGetUserByRoleIdMutation,
  useGetApiAdminGetTutorialLinksQuery,
  usePostApiAdminJoinTeamMutation,
  usePostApiAdminUpdateAgentStatusMutation,
  usePostApiAdminRequestDocumentsMutation,
  usePostApiAdminVerifyUploadLinkMutation,
  usePostApiAdminUploadDocumentsMutation,
  usePostApiAdminVerifyDuplicateEmailMutation,
  useGetApiAdminDownloadStaffFilesQuery,
  usePostApiAdminRegisterAgentMutation,
  usePostApiAdminSetAdminPasswordMutation,
  usePostApiAdminLoginMutation,
  useGetApiAdminForgetPasswordQuery,
  usePostApiAdminResetPasswordMutation,
  useGetApiAdminGetDashboardStatsQuery,
  useGetApiAdminGetProfileQuery,
  usePostApiAdminUpdateProfileMutation,
  useGetApiAdminVerifyEmployeeQuery,
  usePostApiAdminChangePasswordMutation,
  useGetApiAdminGetAllAgentsQuery,
  usePostApiAdminGetAllUsersMutation,
  usePostApiAdminGetUsersDropdownListMutation,
  usePostApiAdminAddUserMutation,
  useGetApiAdminActivateDeactivateUserQuery,
  useGetApiAdminGenerateForgetPasswordLinkQuery,
  usePostApiAdminRemoveEmpTokenMutation,
  usePostApiAdminGetAllInvoicesMutation,
  usePostApiAdminGetCarrierByIdMutation,
  usePostApiAdminAddUpdateCarrierMutation,
  usePostApiAdminActivateDeactivateCarrierMutation,
  usePostApiAdminGetAllCarriersMutation,
  usePostApiAdminGetAllClaimsMutation,
  useGetApiAdminGetClaimDetailQuery,
  usePostApiAdminUpdateClaimInfoMutation,
  usePostApiAdminClaimDocZipMutation,
  useGetApiAdminGetEmployeeByIdQuery,
  useGetApiAdminActivateDeactivateEmployeeQuery,
  usePostApiAdminUpdateEmployeeByIdMutation,
  usePostApiAdminGetAllEmployeesMutation,
  usePostApiAdminAddNewEmployeeMutation,
  useGetApiAdminGetEmpForgetLinkQuery,
  useGetApiAdminGetEmpActivationLinkQuery,
  useGetApiAdminGetRoleBasedAgenciesListQuery,
  usePostApiAdminGetAllCouponCodeMutation,
  usePostApiAdminAddCouponCodeMutation,
  useGetApiAdminUpdateCouponQuery,
  useGetApiAdminVerifyPromotionCodeQuery,
  useGetApiAdminActiveDeactivePromotionCodeQuery,
  usePostApiAdminShareQrCodeMutation,
  useGetApiAdminQrCodeQuery,
  useGetApiAdminGetQrCodeUrlQuery,
  usePostApiAdminUploadBrochureMutation,
  usePostApiAdminAddQrCodeMutation,
  usePostApiAdminGetAllSubscriptionsMutation,
  useGetApiAdminGetAdminSubscriptionsQuery,
  useGetApiAdminGetRefundDetailQuery,
  usePostApiAdminCalculateCommissionMutation,
  usePostApiAdminGetAgencyCommissionedAgentsMutation,
  useGetApiAdminGetAgentCommissionRateQuery,
  usePostApiAdminAddEditCommissionRateMutation,
  usePostApiAdminGetAdminDashboardMutation,
  usePostApiAdminGetDashboardOverviewMutation,
  usePostApiAdminGetDashboardRevenuestatsMutation,
  usePostApiAdminGetSubsClaimsStatsMutation,
  usePostApiAdminGetUserRolesMutation,
  usePostApiAdminCustomerAddCartitemMutation,
  useGetApiAdminCustomerGetCartitemQuery,
  useGetApiAdminCustomerGetCartitemListQuery,
  useGetApiAdminCustomerRemoveCartitemQuery,
  useGetApiAdminCustomerRemoveAllCartitemsQuery,
  useGetApiAdminCustomerGetPaymentPlansQuery,
  useGetApiAdminCustomerGetCompleteCheckoutQuery,
  useGetApiAdminCustomerApplyCouponCodeQuery,
  useGetApiAdminCustomerGetUserBillingcardsQuery,
  usePostApiAdminCustomerAddUserBillingcardMutation,
  useGetApiAdminCustomerGetCheckoutDetailsQuery,
  usePostApiAdminCustomerCreateSubscriptionMutation,
  usePostApiAdminCustomerCreateSubscriptionV3Mutation,
  usePostApiAdminCustomerSaveSubscriptionDetailMutation,
  useGetApiAdminCustomerGetCustomerProfileQuery,
  useGetApiAdminCustomerGetCustomerDetailQuery,
  usePostApiAdminCustomerUpdateCustomerInfoMutation,
  usePostApiAdminCustomerGetCustomerPoliciesMutation,
  usePostApiAdminCustomerGetCustomerClaimsMutation,
  usePostApiAdminCustomerGetCustomerInvoicesMutation,
  usePostApiAdminCustomerGetCustomerSubscriptionsMutation,
  useGetApiAdminCustomerGetPolicyDetailQuery,
  useGetApiAdminCustomerGetClaimDetailQuery,
  useGetApiAdminCustomerGetSubscDetailQuery,
  useGetApiAdminCustomerGetInvoiceDetailQuery,
  usePostApiAdminCustomerAddClaimMutation,
  usePostApiAdminCustomerCancelCustomerPolicyMutation,
  usePostApiAdminCustomerCancelCustomerPolicyV2Mutation,
  usePostApiAdminCustomerCancelSubscriptionMutation,
  usePostApiAdminCustomerSetCustomerPasswordMutation,
  usePostApiAuditLogsGetAllNotificationLogsMutation,
  useGetApiAuditLogsGetNotificationTokensQuery,
  useGetApiAuditLogsDeleteNotificationByIdQuery,
  usePostApiAuditLogsGetAllEmailLogsMutation,
  useGetApiAuditLogsGetEmailLogByIdQuery,
  usePostApiAuditLogsGetAllAuditLogsMutation,
  useGetApiAuditLogsGetAuditLogDetailsQuery,
  useGetApiCustomerGetTotalCoveragesQuery,
  useGetApiCustomerGetCarriersMakersByTypeQuery,
  useGetApiCustomerGetVehicleModelListByIdQuery,
  useGetApiCustomerGetDeductibleListQuery,
  useGetApiCustomerGetCartCountQuery,
  usePostApiCustomerAddCartItemMutation,
  useGetApiCustomerGetCartItemByIdQuery,
  usePostApiCustomerRemoveCartItemByIdMutation,
  usePostApiCustomerRemoveAllCartItemsMutation,
  useGetApiCustomerGetCartItemListQuery,
  useGetApiCustomerGetCheckOutCartItemsByIdQuery,
  useGetApiCustomerGetCompleteCheckOutQuery,
  useGetApiCustomerApplyCouponCodeQuery,
  useGetApiCustomerGetCardPaymentInfoQuery,
  usePostApiCustomerGetPoliciesMutation,
  usePostApiCustomerGetClaimsByStatusMutation,
  useGetApiCustomerGetClaimsCountByTypeByIdQuery,
  useGetApiCustomerGetClaimsByPolicyIdQuery,
  usePostApiCustomerAddNewClaimsMutation,
  usePostApiCustomerSaveFeedBackMutation,
  useGetApiCustomerGetClaimDetailByIdQuery,
  useGetApiCustomerGetNodukClaimDetailQuery,
  useGetApiCustomerGetInvoiceDetailByIdQuery,
  usePostApiCustomerGetAllInvoicesByStatusMutation,
  useGetApiCustomerVerifyZipCodeQuery,
  usePostApiDisasterGetDisasterAreasListMutation,
  useGetApiDisasterGetRiskLevelsListQuery,
  usePostApiDisasterAddUpdateDisasterAreaMutation,
  useGetApiDisasterActiveDeactiveDisasterAreaQuery,
  usePostApiDisasterImportDisasterAreaMutation,
  usePostApiDisasterAddEditDisasterRiskMutation,
  useGetApiDisasterActiveInactiveDisasterRiskQuery,
  useGetApiFaqGetFaQsListQuery,
  usePostApiNotificationSendNotificationMutation,
  useGetApiNotificationGetEmployeeNotificationsQuery,
  useGetApiNotificationUpdateAdminNotificationStatusQuery,
  useGetApiNotificationGetCustomerNotificationQuery,
  useGetApiNotificationUpdateCustomerNotificationStatusQuery,
  usePostApiNotificationSendAdminNotificationMutation,
  useDeleteApiNotificationDeleteOldNotificationsMutation,
  useGetApiNotificationGetEmployeeNotificationCountQuery,
  usePostApiPaymentCreateCustomerSubscriptionV2Mutation,
  usePostApiPaymentCreateCustomerSubscriptionV3Mutation,
  useGetApiPaymentGetLinkedSubscriptionsQuery,
  usePostApiPaymentCancelPolicyByCartItemIdMutation,
  usePostApiPaymentCancelPolicyByCartItemIdV2Mutation,
  usePostApiPaymentGetSubscriptionsByStatusMutation,
  useGetApiPaymentGetSubscriptionsDetailsQuery,
  usePostApiPaymentUpdateSubscriptionCardMutation,
  useGetApiPaymentGetSubscriptionPlansQuery,
  usePostApiPaymentUpgradeSubscriptionsMutation,
  usePostApiPaymentAddNewBillingCardMutation,
  usePostApiPaymentDeleteBillingCardByIdMutation,
  usePostApiPaymentGetPaymentsByStatusMutation,
  usePostApiPaymentCancelSubscriptionMutation,
  usePostApiPaymentSavePaymentsResponseMutation,
  usePostApiPaymentCreateTestPaymentIntentMutation,
  usePostApiPaymentCreateTestPaymentMethodMutation,
  usePostApiPaymentConfirmTestPaymentMutation,
  usePostApiPaymentGetRefundsByStatusMutation,
  useGetApiPaymentGetRefundByIdQuery,
  usePostApiPaymentSubscriptionRefundsMutation,
  useGetApiPaymentGetPromoCodeInfoQuery,
  useGetApiPaymentIncrementCouponRedeemCountQuery,
  usePostApiPaymentInvoiceWebhookMutation,
  usePostApiPaymentCouponWebhookMutation,
  usePostApiPaymentSaveSubscriptionDetailsMutation,
  usePostApiPaymentSubscriptionWebhookMutation,
  usePostApiUserLoginMutation,
  usePostApiUserSignUpUserMutation,
  usePostApiUserForgetPasswordMutation,
  usePostApiUserUpdateCustomerProfileMutation,
  useGetApiUserGetCustomerProfileQuery,
  usePostApiUserChangePasswordMutation,
  usePostApiUserResetPasswordMutation,
  usePostApiUserVerifyEmailMutation,
  usePostApiUserResendVerificationEmailMutation,
  usePostApiUserVerifyPhoneNumberMutation,
  usePostApiUserVerifyPhoneCodeMutation,
  usePostApiUserDeleteAccountMutation,
  usePostApiUserRemoveDeviceTokenMutation,
  useGetApiUserCheckLiveEnvironmentQuery,
  useGetApiUserTest500ErrorQuery,
  usePostApiUserVerifyPkPhoneNumberMutation,
} = injectedRtkApi;
