import React, {useState, useEffect} from 'react';
import './style.scss';
import {ALL_TEXT, ENUMS} from '../../../common';
import {tableHeading} from './table';
import {
  CouponEditDetail,
  CustomizedSearch,
  GenericButton,
  GenericModal,
  GenericTable,
} from '../../../components';
import Pagination from '@mui/material/Pagination';
import {
  setCouponEditModal,
  setDeactivateCoupon,
} from '../../../redux/slice/coupon-code';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {path} from './../../../common/routesNames';
import {
  NodukAdmnApi,
  usePostApiAdminGetAllCouponCodeMutation,
} from '../../../redux/slice/nodukAdmin.ts';
import {getValuesFromEnumsObj} from '../../../utils/helpers';
import {toast} from 'react-toastify';

function CouponCode() {
  const [btnLoader, setBtnLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCoupon, setSelectedCoupon] = useState({
    pageNumber: 1,
    couponCode: '',
    type: 0,
    status: 0,
    startDate: '',
    expireDate: '',
  });
  const [getCouponListAPI, getCouponListResponse] =
    usePostApiAdminGetAllCouponCodeMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {useLazyGetApiAdminActiveDeactivePromotionCodeQuery} = NodukAdmnApi;
  const [activeDeactivateCouponAPI, activeDeactivateCouponResponse] =
    useLazyGetApiAdminActiveDeactivePromotionCodeQuery();
  const isDeactivateCouponModal = useSelector(
    state => state.coupon.isDeactivateCoupon,
  );
  const selectedCouponData = useSelector(state => state.coupon.selectedCoupon);
  const isCouponEditModal = useSelector(
    state => state.coupon.isCouponEditModal,
  );
  const {
    STAFF: {
      ROLE: {ALL},
    },
    COUPON_CODE: {TYPE},
  } = ENUMS;
  const couponsList = getCouponListResponse?.data?.content?.list;

  useEffect(() => {
    const payload = {
      couponRequestDto: {
        pageNo: currentPage,
        pageSize: 10,
        couponCode: selectedCoupon.couponCode,
        couponType: selectedCoupon.type,
        startDate: selectedCoupon.startDate,
        expireDate: selectedCoupon.expireDate,
        status: selectedCoupon.status,
      },
    };
    fetchCouponList(payload);
  }, [currentPage]);

  const fetchCouponList = async payload => {
    let response = await getCouponListAPI(payload);
    if (response && response.data.success) {
      setBtnLoader(false);
      setTotalPages(response.data.content.totalPages);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const couponSearchHandler = async (
    startDate,
    expiryDate,
    couponCode,
    couponType,
    couponStatus,
    clear,
  ) => {
    let payload;
    if (clear) {
      payload = {
        couponRequestDto: {
          pageNo: 1,
          pageSize: 10,
          couponCode: '',
          couponType: 0,
          startDate: '',
          expireDate: '',
          status: 0,
        },
      };
      setSelectedCoupon({
        couponCode: '',
        type: 0,
        status: 0,
        expireDate: '',
        startDate: '',
      });
    } else {
      const type = getValuesFromEnumsObj(TYPE, couponType);
      const status = getValuesFromEnumsObj(ENUMS.USERS.STATUS, couponStatus);
      setSelectedCoupon({
        couponCode: couponCode,
        type: type,
        status: status,
        expireDate: expiryDate,
        startDate: startDate,
      });
      payload = {
        couponRequestDto: {
          pageNo: 1,
          couponCode: couponCode,
          couponType: type,
          startDate: startDate,
          expireDate: expiryDate,
          status: status,
          pageSize: ENUMS.PAGE_SIZE,
        },
      };
    }
    setCurrentPage(1);
    setBtnLoader(true);
    await fetchCouponList(payload);
    setBtnLoader(false);
  };
  const activeInactiveCouponCodeHandler = async () => {
    const response = await activeDeactivateCouponAPI({
      couponId: selectedCouponData.stripeCouponId,
      status: !selectedCouponData.isActive,
    });
    if (response && response.data.success) {
      dispatch(setDeactivateCoupon(false));
      toast.success(response.data.description);
      const payload = {
        couponRequestDto: {
          pageNo: currentPage,
          pageSize: 10,
          couponCode: selectedCoupon.couponCode,
          couponType: selectedCoupon.type,
          startDate: '',
          expireDate: '',
          status: selectedCoupon.status,
        },
      };
      fetchCouponList(payload);
    } else {
      toast.error(response.data.description);
    }
  };

  return (
    <div className="coupon-code-page-container">
      <div className="title-container-coupon-code-page">
        <div>
          <span>{ALL_TEXT.ADMINISTRATION} / </span>
          <span>{ALL_TEXT.COUPON_CODE}</span>
        </div>
        <GenericButton
          customStyle={'custom-register-btn'}
          buttonText={`+${ALL_TEXT.USER_DETAIL.ADD_COUPON_CODE}`}
          onPress={() => {
            navigate({pathname: path.addCouponCode});
          }}
        />
      </div>
      <div className="manageCarrierList-search-bar">
        <CustomizedSearch
          firstdate={true}
          seconddate={true}
          title={ALL_TEXT.SEARCH_COUPON_CODE}
          firstLabel={ALL_TEXT.START_DATE}
          firstType={'date'}
          firstPlaceholder={ALL_TEXT.START_DATE}
          firstDropdown
          firstDropdownLabel={ALL_TEXT.COUPON_TYPE}
          firstDropdownList={[
            TYPE.ONCE.LABEL,
            TYPE.FOREVER.LABEL,
            TYPE.FIRST_MONTH.LABEL,
            ALL.LABEL,
          ]}
          secondInput
          secondType={'date'}
          secondLabel={ALL_TEXT.EXPIRY_DATE}
          secondPlaceholder={ALL_TEXT.EXPIRY_DATE}
          thirdInput
          thirdLabel={ALL_TEXT.COUPON_CODE}
          thirdPlaceholder={ALL_TEXT.COUPON_CODE}
          secondDropdown
          secondDropdownLabel={ALL_TEXT.STATUS}
          seconddropdownlist={[
            ENUMS.USERS.STATUS.ACTIVE.LABEL,
            ENUMS.USERS.STATUS.INACTIVE.LABEL,
            ENUMS.USERS.STATUS.ALL.LABEL,
          ]}
          onClearFilterHandler={() => {
            couponSearchHandler('', '', '', '', '', true);
          }}
          btnLoader={btnLoader}
          onSearchBtnPress={(
            startDate,
            expiryDate,
            couponCode,
            couponType,
            couponStatus,
          ) => {
            couponSearchHandler(
              startDate,
              expiryDate,
              couponCode,
              couponType,
              couponStatus,
            );
          }}
        />
      </div>
      <div className="table-manageCarrierList-container">
        <GenericTable
          headings={tableHeading()}
          data={couponsList}
          loader={getCouponListResponse.isLoading}
        />
      </div>
      {totalPages > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPages}
            />
          </div>
        </div>
      )}
      {isCouponEditModal && (
        <CouponEditDetail
          show={isCouponEditModal}
          handleClose={isUpdated => {
            dispatch(setCouponEditModal(false));
            if (isUpdated) {
              const payload = {
                couponRequestDto: {
                  pageNo: currentPage,
                  pageSize: 10,
                  couponCode: selectedCoupon.couponCode,
                  couponType: selectedCoupon.type,
                  startDate: '',
                  expireDate: '',
                  status: selectedCoupon.status,
                },
              };
              fetchCouponList(payload);
            }
          }}
        />
      )}

      {isDeactivateCouponModal && (
        <GenericModal
          show={isDeactivateCouponModal}
          type={ENUMS.POPUP_ENUMS.ALERT}
          title={
            !selectedCouponData.isActive
              ? ALL_TEXT.DISASTER.ACTIVATE
              : ALL_TEXT.DISASTER.DEACTIVATE
          }
          body={
            !selectedCouponData.isActive
              ? ALL_TEXT.ACTIVATE_COUPON_MESSAGE
              : ALL_TEXT.DEACTIVATE_COUPON_MESSAGE
          }
          buttonText={ALL_TEXT.BUTTON_TEXT.YES}
          buttonText2={ALL_TEXT.BUTTON_TEXT.NO}
          grayButton2
          button2
          primaryBtnPress={() => {
            activeInactiveCouponCodeHandler();
          }}
          btnLoader={activeDeactivateCouponResponse.isFetching}
          secondaryBtnPress={() => {
            dispatch(setDeactivateCoupon(false));
          }}
        />
      )}
    </div>
  );
}

export default CouponCode;
