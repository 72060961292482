import React, {useState, useEffect} from 'react';
import './styles.scss';
import {BsFillTrashFill} from 'react-icons/bs';
import {ALL_TEXT, colors, ENUMS, QUERYPARAM} from '../../common';
import {
  AddCommercialInput,
  AddDeviceInput,
  AddHomeInput,
  AddVehicleInput,
  GenericButton,
  GenericModal,
} from '../../components';
import AddPolicyMainLayout from '../add-policy-main-layout';
import {path} from '../../common/routesNames';
import {useLocation, useNavigate} from 'react-router';
import {
  getCarrierMakerByType,
  getCartItemsList,
  getCustomerProfile,
  getDeductibleList,
  removeAllCartItems,
  removeSingleCartItem,
} from '../../utils/rest-services';
import _ from 'lodash';
import {CircularProgress} from '@material-ui/core';
import {EditCartPopup} from '../../components/cart-edit-popup';
import {useSelector} from 'react-redux';

const AddPolicy = () => {
  const signupId = useSelector(
    state => state.checkout.selectedUser?.signupId ?? null,
  );

  const [selectedPolicyIndex, setSelectedPolicyIndex] = useState(
    ENUMS.COVERAGE_TYPES.VEHICLE,
  );
  useEffect(() => {
    retreiveCarrierWithType();
    fetchDeductibles();
    getAllCartItemshandler();
  }, [selectedPolicyIndex, signupId]);

  const [makerItem, setMakerItem] = useState([]);
  const [carrierItem, setCarrierItem] = useState([]);
  const [deductibleItem, setDeductibleItem] = useState([]);
  const [cartItemsList, setCartItemsList] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [customerData, setCustomerData] = useState({});

  const retreiveCarrierWithType = async () => {
    const responseData = await getCarrierMakerByType(selectedPolicyIndex);
    if (responseData && responseData.success) {
      const {
        content: {carriers, makers},
      } = responseData;
      setMakerItem(makers || []);
      setCarrierItem(carriers || {});
    }
  };

  const fetchDeductibles = async () => {
    let response = await getDeductibleList();
    if (response && response.success) {
      const {content} = response;
      setDeductibleItem(content || {});
    }
  };

  const getAllCartItemshandler = async () => {
    if (signupId) {
      let response = await getCartItemsList(signupId); // this id temporary
      if (response && response.success) {
        const {content} = response || {};
        if (content) {
          const cartItems = _.flatMap(content?.items, ({cartItem}) => cartItem); // getting cat item list
          const totalCostSum = _.sumBy(content?.items, 'totalCost');
          setTotalCost(totalCostSum);
          setCartItemsList(cartItems);
        } else {
          setTotalCost(0);
          setCartItemsList([]);
        }
      } else {
        setTotalCost(0);
        setCartItemsList([]);
      }
    }
  };

  const removeSingleCartItemHandler = async id => {
    let response = await removeSingleCartItem(id);
    if (response && response.success) {
      getAllCartItemshandler();
    }
  };
  const emptyCartHandler = async id => {
    let response = await removeAllCartItems(id);
    if (response && response.success) {
      getAllCartItemshandler();
    }
  };

  const planSelectHandler = id => {
    switch (id) {
      case ENUMS.COVERAGE_TYPES.VEHICLE:
        return (
          <AddVehicleInput
            makerItem={makerItem}
            carrierItem={carrierItem}
            deductibleItem={deductibleItem}
            onItemAddedToCart={getAllCartItemshandler}
          />
        );
      case ENUMS.COVERAGE_TYPES.DEVICE:
        return (
          <AddDeviceInput
            carrierItem={carrierItem}
            deductibleItem={deductibleItem}
            onItemAddedToCart={getAllCartItemshandler}
          />
        );
      case ENUMS.COVERAGE_TYPES.HOME:
        return (
          <AddHomeInput
            carrierItem={carrierItem}
            deductibleItem={deductibleItem}
            onItemAddedToCart={getAllCartItemshandler}
            customerProfile={customerData}
          />
        );
      case ENUMS.COVERAGE_TYPES.COMMERCIALS:
        return (
          <AddCommercialInput
            carrierItem={carrierItem}
            deductibleItem={deductibleItem}
            onItemAddedToCart={getAllCartItemshandler}
          />
        );
      default:
        return null;
    }
  };
  let navigate = useNavigate();

  const getPolicyCartItemTitle = item => {
    if (
      item?.serviceTypeId === ENUMS.SERVICE_TYPE.VEHICLE ||
      item?.serviceTypeId === ENUMS.SERVICE_TYPE.COMMERCIAL
    ) {
      return `${item.make}/${item.model}`;
    } else if (item?.serviceTypeId === ENUMS.SERVICE_TYPE.DEVICE) {
      return `${item.brand}/${item.model}`;
    } else {
      return `${item.address}`;
    }
  };

  let location = useLocation();
  const getParamFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamFromUrl();
  useEffect(() => {
    getCustomerProfileHandler(signupId);
  }, [signupId]);

  const getCustomerProfileHandler = async signupId => {
    const id = query.get(QUERYPARAM.SIGNUPID);

    let result = await getCustomerProfile(signupId || id);
    if (result && result.success) {
      setCustomerData(result.content);
    } else {
      setCustomerData({});
    }
  };
  return (
    <AddPolicyMainLayout selectedId={0}>
      <div className="add-policy-wrapper">
        <div className="covaerage-selector-wrapper">
          <span>{'Select Coverage'}</span>
          <div className="covaerage-selector-container">
            <CoverageItem
              onClick={() => {
                setSelectedPolicyIndex(ENUMS.COVERAGE_TYPES.VEHICLE);
              }}
              text={ENUMS.POLICY_TYPES.VEHICLE.LABEL}
              check={selectedPolicyIndex === ENUMS.COVERAGE_TYPES.VEHICLE}
              selectedTab={selectedPolicyIndex === ENUMS.COVERAGE_TYPES.VEHICLE}
            />
            <CoverageItem
              onClick={() => {
                setSelectedPolicyIndex(ENUMS.COVERAGE_TYPES.DEVICE);
              }}
              text={ENUMS.POLICY_TYPES.DEVICE.LABEL}
              check={selectedPolicyIndex === ENUMS.COVERAGE_TYPES.DEVICE}
              selectedTab={selectedPolicyIndex === ENUMS.COVERAGE_TYPES.DEVICE}
            />
            <CoverageItem
              onClick={() => {
                setSelectedPolicyIndex(ENUMS.COVERAGE_TYPES.HOME);
              }}
              text={ENUMS.POLICY_TYPES.HOME.LABEL}
              check={selectedPolicyIndex === ENUMS.COVERAGE_TYPES.HOME}
              selectedTab={selectedPolicyIndex === ENUMS.COVERAGE_TYPES.HOME}
            />
            <CoverageItem
              onClick={() => {
                setSelectedPolicyIndex(ENUMS.COVERAGE_TYPES.COMMERCIALS);
              }}
              text={ENUMS.POLICY_TYPES.COMMERCIAL.LABEL}
              check={selectedPolicyIndex === ENUMS.COVERAGE_TYPES.COMMERCIALS}
              selectedTab={
                selectedPolicyIndex === ENUMS.COVERAGE_TYPES.COMMERCIALS
              }
            />
          </div>
        </div>
        <div className="input-carrier-container">
          {planSelectHandler(selectedPolicyIndex)}
        </div>

        <div className="policy-item-container">
          {cartItemsList.length > 0 && (
            <>
              <div className="top-policy-items-header">
                <span>{`Total cart items (${cartItemsList.length})`}</span>
                <div className="secondary-container-policy">
                  <div
                    onClick={() => {
                      setDeleteModal(true);
                    }}>
                    <BsFillTrashFill />
                    <span>{ALL_TEXT.EMPTY_CART}</span>
                  </div>
                  <span>{`Total: $${totalCost.toFixed(2)}/monthly`}</span>
                </div>
              </div>
              <div className="policy-items-wrapper">
                {cartItemsList.map(i => {
                  return (
                    <PolicyItem
                      key={i}
                      item={i}
                      signupId={signupId}
                      name={getPolicyCartItemTitle(i)}
                      fetchDataList={getAllCartItemshandler}
                      deductible={`$${i.deductibleAmount.toFixed(2)}`}
                      onDelPress={async () => {
                        await removeSingleCartItemHandler(i.cartItemId);
                      }}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>

        <div className="next-btn-policy">
          <GenericButton
            disable={cartItemsList.length <= 0}
            buttonText={'Next'}
            onPress={() => {
              navigate(
                {pathname: path.selectPayment, search: location.search},
                {replace: true},
              );
            }}
          />
        </div>
      </div>
      {
        <GenericModal
          show={deleteModal}
          type={ENUMS.MODAL_TYPES.EMPTY_CART}
          title={ALL_TEXT.EMPTY_CART}
          buttonText={ALL_TEXT.YES}
          buttonText2={ALL_TEXT.NO}
          button2
          body={ALL_TEXT.EMPTY_CART_DETAILS}
          primaryBtnPress={() => {
            emptyCartHandler(signupId); // will be handle in Future
            setDeleteModal(false);
          }}
          secondaryBtnPress={() => {
            setDeleteModal(false);
          }}
          grayButton2
        />
      }
    </AddPolicyMainLayout>
  );
};
export default AddPolicy;

// used to display individual policy item in user cart
const PolicyItem = ({
  name,
  deductible,
  onDelPress,
  item,
  fetchDataList,
  signupId,
}) => {
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedID, setSelectedID] = useState(0);
  return (
    <div className="policy-items-container">
      <span>{name}</span>
      <span>{`Deductible: ${deductible}`}</span>
      <div className="policy-icon-container">
        <div
          onClick={() => {
            setSelectedID({
              cartItemId: item.cartItemId,
              serviceTypeId: item.serviceTypeId,
            });

            setShowEditModal(true);
          }}>
          <span class="icon-edit"></span>
        </div>
        <div
          onClick={async () => {
            setDeleteLoader(true);
            await onDelPress();
            setDeleteLoader(false);
          }}>
          {deleteLoader ? (
            <CircularProgress size={15} color={colors.white} />
          ) : (
            <span class="icon-Vector-7"></span>
          )}
        </div>
      </div>
      {showEditModal && (
        <EditCartPopup
          show={showEditModal}
          handleClose={isSuccess => {
            setShowEditModal(false);
            if (isSuccess === true) {
              fetchDataList();
            }
          }}
          selectedId={selectedID}
          signupId={signupId}
        />
      )}
    </div>
  );
};

// used to display radio button for user to select Policy Type
const CoverageItem = ({text, check, onClick, selectedTab}) => {
  return (
    <div
      onClick={onClick}
      className={
        selectedTab ? 'coverage-item coverage-item-selected' : 'coverage-item'
      }>
      <input
        class="form-check-input"
        type="radio"
        id="flexRadioDefault2"
        name="radio-group-2"
        value={`name-Vehicle`}
        checked={check}></input>
      <span>{text}</span>
    </div>
  );
};
