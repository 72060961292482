import React, {useEffect, useState} from 'react';
import {MdOutlineManageSearch, MdDelete} from 'react-icons/md';
import {colors} from '../../common';
import './styles.scss';
import {ALL_TEXT, ENUMS} from '../../common';
import {getProfile} from '../../utils/localstorage';
export const PolicyCard = ({
  planIconTitle,
  badge,
  cost,
  LeftTopDetails,
  LeftBottomDetails,
  rightTopDetails,
  rigthBottomDetails,
  onDetailBtnPress,
  onDeleteBtnPress,
  cardType,
  lastBottomDetails,
  lastLeftBottomDetails,
  isActive,
}) => {
  const [loginUser, setLoginUSer] = useState(null);

  useEffect(() => {
    let role = getProfile().roleId;
    setLoginUSer(role);
  });
  return (
    <div className="main-description-box">
      <div className="detail-invoice-container">
        <div className="description-header">
          {planIconTitle}
          <div className="description-badge second-child-card">{badge}</div>
          <div className="cost-action third-child-card">{cost}</div>
        </div>
        <div className="description-details">
          {LeftTopDetails}
          {LeftBottomDetails}
        </div>
        <div className="description-details">
          {rightTopDetails}
          {rigthBottomDetails}
        </div>
        {
          <div className="description-details">
            {lastLeftBottomDetails}
            {lastBottomDetails}
          </div>
        }
      </div>
      <div class="middle-btn-invoice">
        {(cardType == ENUMS.VIEW_CARD_TYPE.SUBSCRIPTION ||
          cardType === ENUMS.VIEW_CARD_TYPE.INVOICE ||
          cardType === ENUMS.VIEW_CARD_TYPE.CLAIMS ||
          cardType === ENUMS.VIEW_CARD_TYPE.REFUND) && (
          <div onClick={onDetailBtnPress} class="view-invoice-btn">
            <MdOutlineManageSearch size={25} />
            <span>{ALL_TEXT.VIEW_DETAILS}</span>
          </div>
        )}
        {cardType === ENUMS.VIEW_CARD_TYPE.SUBSCRIPTION &&
          isActive &&
          loginUser !== 6 && (
            <div onClick={onDeleteBtnPress} class="view-cancel-btn">
              <MdDelete size={20} color={colors.primary} />
              <span>{ALL_TEXT.CANCEL_SUBSCRIPTION}</span>
            </div>
          )}
      </div>
    </div>
  );
};
