import React, {useState, useEffect} from 'react';
import Autocomplete from 'react-google-autocomplete';
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './styles.scss';
import {GenericButton} from '../generic-button';
import {Grid} from '@mui/material';
import {InputField} from '../input-field';
import {Formik} from 'formik';
import {
  customerSchema,
  userDetailSchema,
  companyDetailsSchema,
  agentAndAgencyManagerSchema,
} from '../../common/schemas';
import {addUserDetail, updateCustomerInfo} from '../../utils/rest-services';
import {ALL_TEXT, ENUMS, QUERYPARAM} from './../../common/constants';
import {GenericModal} from '../generic-modal';
import {
  generateFormData,
  getStringFromEnumsObj,
  getValuesFromEnumsObj,
} from '../../utils/helpers';
import {CriteriaDropdown} from '../criteria-dropdown';
import {
  usePostApiAdminAddNewEmployeeMutation,
  usePostApiAdminUpdateEmployeeByIdMutation,
  NodukAdmnApi,
} from '../../redux/slice/nodukAdmin.ts';
import {getProfile} from '../../utils/localstorage';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {openModal, setModalDetails} from '../../redux/slice/popup-modal';
import {Loader} from '../loader';
import {useNavigate} from 'react-router';
import {path} from '../../common/routesNames';

export const AddNewUser = ({
  show,
  handleClose,
  buttonText,
  editCustomer,
  customerData,
  signupId,
  addStaffMember,
}) => {
  const {
    STAFF: {ROLE},
  } = ENUMS;
  const {useLazyGetApiAdminGetEmployeeByIdQuery} = NodukAdmnApi;
  const {useLazyGetApiAdminGetRoleBasedAgenciesListQuery} = NodukAdmnApi;
  const [showModal, setShowModal] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [employeeInformation, setEmployeeInformation] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState({});
  const [buttonDisable, setButtondisable] = useState(false);
  const [newuser, setNewUser] = useState('');
  const [agencyId, setAgencyId] = useState(null);
  const [editSelectedRole, setEditSelectedRole] = useState(0);
  const [userlogin, setloginUser] = useState(0);
  const dispatch = useDispatch();
  // Get Edit State in case of Edit Staff Member Information
  const isEditModal = useSelector(state => state.staffManagment.isEditModal);
  const selectedEmploye = useSelector(
    state => state.staffManagment.selectedEmployeeData,
  );
  const navigate = useNavigate();

  const [popUpResponse, setPopUpResponse] = useState({
    type: ENUMS.POPUP_ENUMS.SUCCESS,
    title: '',
    description: '',
  });
  const visibleRolesForAdmin = [
    {
      value: ROLE.ADMIN.LABEL,
      label: ROLE.ADMIN.LABEL,
    },
    {
      value: ROLE.AGENCY.LABEL,
      label: ROLE.AGENCY.LABEL,
    },
    {
      value: ROLE.AGENCY_MANAGER.LABEL,
      label: ROLE.AGENCY_MANAGER.LABEL,
    },
    {
      value: ROLE.AGENT.LABEL,
      label: ROLE.AGENT.LABEL,
    },
    {
      value: ROLE.BUSINESS_AMB.LABEL,
      label: ROLE.BUSINESS_AMB.LABEL,
    },
    {
      value: ROLE.CUSTOMER_SUPPORT.LABEL,
      label: ROLE.CUSTOMER_SUPPORT.LABEL,
    },
    {
      value: ROLE.INDEPANDANT_AGENT.LABEL,
      label: ROLE.INDEPANDANT_AGENT.LABEL,
    },
    {
      value: ROLE.SALES_REP.LABEL,
      label: ROLE.SALES_REP.LABEL,
    },
    // {
    //   value: ROLE.DISTRIBUTION_CHANNEL.LABEL,
    //   label: ROLE.DISTRIBUTION_CHANNEL.LABEL,
    // },
  ];

  // const visibleRolesForDistributionChannel = [
  //   {
  //     value: ROLE.AGENCY.LABEL,
  //     label: ROLE.AGENCY.LABEL,
  //   },
  //   {
  //     value: ROLE.AGENT.LABEL,
  //     label: ROLE.AGENT.LABEL,
  //   },
  //   {
  //     value: ROLE.CUSTOMER_SUPPORT.LABEL,
  //     label: ROLE.CUSTOMER_SUPPORT.LABEL,
  //   },
  //   {
  //     value: ROLE.SALES_REP.LABEL,
  //     label: ROLE.SALES_REP.LABEL,
  //   },
  //   {
  //     value: ROLE.BUSINESS_AMB.LABEL,
  //     label: ROLE.BUSINESS_AMB.LABEL,
  //   },
  // ];
  const visibleRolesForAgency = [
    {
      value: ROLE.AGENT.LABEL,
      label: ROLE.AGENT.LABEL,
    },
    {
      value: ROLE.AGENCY_MANAGER.LABEL,
      label: ROLE.AGENCY_MANAGER.LABEL,
    },
  ];
  const visibleForBussinessAmbassader = [
    {
      value: ROLE.BUSINESS_AMB.LABEL,
      label: ROLE.BUSINESS_AMB.LABEL,
    },
    {
      value: ROLE.INDEPANDANT_AGENT.LABEL,
      label: ROLE.INDEPANDANT_AGENT.LABEL,
    },
    {
      value: ROLE.SALES_REP.LABEL,
      label: ROLE.SALES_REP.LABEL,
    },
  ];
  const visibleForAggencyManager = [
    {
      value: ROLE.AGENT.LABEL,
      label: ROLE.AGENT.LABEL,
    },
  ];
  const others = [
    {
      value: ROLE.CUSTOMER_SUPPORT.LABEL,
      label: ROLE.CUSTOMER_SUPPORT.LABEL,
    },
  ];
  const {profile} = customerData || {};
  // RTK Quey for Employee Information
  const [getCurrentEmployeInfoAPI, getCurrentEmployeInfoResponse] =
    useLazyGetApiAdminGetEmployeeByIdQuery();
  const [getRoleBaseAgneciesAPI, getRoleBaseAgneciesResponse] =
    useLazyGetApiAdminGetRoleBasedAgenciesListQuery();
  const dataList = getRoleBaseAgneciesResponse?.data?.content || [];

  useEffect(() => {
    const Profile = getProfile();
    setloginUser(Profile.roleId);
  });

  const getrolebaseAgencies = array => {
    const agencies = array.map(item => ({
      label: item.agencyName,
      value: item.employeeId,
    }));
    return agencies;
  };

  const getObjectOrNullOrUndefined = obj => {
    return Object.keys(obj).length === 0 ? null : obj;
  };
  const [addNewEmployee, addNewEmployeResponse] =
    usePostApiAdminAddNewEmployeeMutation();
  const [updateEmployeeAPI, updateEmployeeResponse] =
    usePostApiAdminUpdateEmployeeByIdMutation();

  useEffect(() => {
    fetchEmployeeInfoHandler();
  }, [isEditModal]);

  useEffect(() => {
    setEditSelectedRole(selectedEmploye.userRoleId);
  }, []);
  // used for getting information from URL
  const fetchEmployeeInfoHandler = async () => {
    let response = await getCurrentEmployeInfoAPI({
      employeeId: selectedEmploye.employeeId,
    });
    if (response && response?.data?.success) {
      setEmployeeInformation(response?.data?.content ?? null);
    }
  };
  useEffect(() => {
    if (employeeInformation.agencyId) {
      setSelectedAgency({
        value: employeeInformation?.agencyId,
        label: employeeInformation?.companyName,
      });
    }
  }, [employeeInformation]);

  const getRoleDropDownValues = () => {
    const profile = getProfile();
    if (profile.roleId == ROLE.ADMIN.VALUE) {
      return visibleRolesForAdmin;
    } else if (profile.roleId == ROLE.AGENCY.VALUE) {
      return visibleRolesForAgency;
      // } else if (profile.roleId == ROLE.DISTRIBUTION_CHANNEL.VALUE) {
      //   return visibleRolesForDistributionChannel;
    } else if (profile.roleId === ROLE.BUSINESS_AMB.VALUE)
      return visibleForBussinessAmbassader;
    else if (profile.roleId === ROLE.AGENCY_MANAGER.VALUE)
      return visibleForAggencyManager;
    else {
      return others;
    }
  };

  const userDetailsInfo = {
    firstName: profile?.firstName || '',
    lastName: profile?.lastName || '',
    companyName: profile?.companyName || '',
    taxId: profile?.taxId || '',
    phone: profile?.phone.replace(/^(\+1)/, '').replace(/^(1)/, '') || '',
    email: profile?.email || '',
    address: profile?.address || '',
    state: profile?.state || '',
    city: profile?.city || '',
    zip: profile?.zip || '',
  };
  const performAddUser = async (value, formik) => {
    const userInfo = {
      FirstName: value.firstName.trim(),
      LastName: value.lastName.trim(),
      Phone: value.phone.trim(),
      Email: value.email.trim(),
      Address: value.address.trim(),
      State: value.state.trim(),
      City: value.city.trim(),
      Zip: value.zip.trim(),
    };

    const userDetail = generateFormData(userInfo);
    setBtnLoader(true);
    let response = await addUserDetail(userDetail);
    setNewUser(response.data.content.id);
    formik.setSubmitting(false);
    setFormSubmitted(false);
    if (response && response?.data?.success) {
      setPopUpResponse({
        type: ENUMS.POPUP_ENUMS.SUCCESS,
        title: '',
        details: response.data.description,
      });
      setShowModal(true);
    } else {
      setPopUpResponse({
        type: ENUMS.POPUP_ENUMS.ERROR,
        title: '',
        details: response.data.description,
      });
      setShowModal(true);
    }
    setBtnLoader(false);
  };
  const editCustomerHandler = async (value, formik) => {
    const userInfo = {
      signupId: signupId || 0,
      firstName: value.firstName.trim(),
      lastName: value.lastName.trim(),
      birthDate: '',
      signupDate: '',
      address: value.address.trim(),
      city: value.city.trim(),
      state: value.state.trim(),
      zip: value.zip.trim(),
      phone: value.phone.trim(),
      email: value.email.trim(),
      isPhoneConfirmed: true,
      profile: '',
      affiliateId: 0,
      profileURL: '',
      lastSeen: '',
      customerId: '',
      accountId: 0,
      agentId: 0,
    };
    setBtnLoader(true);
    let response = await updateCustomerInfo(userInfo);
    formik.setSubmitting(false);
    if (response && response?.data?.success) {
      setPopUpResponse({
        type: ENUMS.POPUP_ENUMS.SUCCESS,
        title: '',
        details: response.data.description,
      });
      setShowModal(true);
    } else {
      setPopUpResponse({
        type: ENUMS.POPUP_ENUMS.ERROR,
        title: '',
        details: response.data.description,
      });
      setShowModal(true);
    }
    setBtnLoader(false);
  };
  const convertArrayToString = data => {
    const joined = data.join();
    let newAddress = joined.replace(/,/g, ' ');
    return newAddress;
  };

  const AddressSeprateHandler = (data, setFieldValue, values) => {
    let address = [];
    let city = [];
    let state = [];
    let postal = [];
    data.map(dataItem => {
      dataItem.types.map(type => {
        if (type === ALL_TEXT.FIELD_TYPE_ENUMS.STREET_NUMBER) {
          address.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.ROUTE) {
          address.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.NAIBHOUR) {
          address.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.LOCALITY) {
          city.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.ADMIN) {
          state.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.CODE) {
          postal.push(dataItem.long_name);
        }
      });
    });
    // === convert Address array to string === //
    setFieldValue(ALL_TEXT.FIELD_VALUE.ADDRESS, values);
    setFieldValue('city', convertArrayToString(city));
    setFieldValue('zip', convertArrayToString(postal));
    setFieldValue('state', convertArrayToString(state));
  };

  const handleChangePhoneNumber = (event, setFieldValue) => {
    let inputValue = event.target.value;

    // Remove non-numeric characters, including '1'
    inputValue = inputValue.replace(/[^0-9]/g, '');

    // Ensure the input is not '1'
    if (inputValue === '1') {
      inputValue = '';
    }

    const restrictedValue = inputValue.substring(0, 12); // restrict to 10 digits
    setFieldValue('phone', restrictedValue);
  };
  const handleChangeZip = (event, setFieldValue) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9+]/g, ''); // remove non-numeric characters
    const restrictedValue = numericValue.substring(0, 9); // restrict to 10 digits
    setFieldValue('zip', restrictedValue);
  };

  const [selectedRole, setSelectedRole] = useState(0);
  // this modal is being used for edit customer and add user and Add Employee
  const addNewStaffMember = async values => {
    let staffRole = getValuesFromEnumsObj(ENUMS.STAFF.ROLE, values.role.label);
    // Handling Agency Agent and Indepandant Agents
    // commented because it is mananing role ID 3 and role Id 8 now we separetly use these role it might be possible we will use it again
    // const profile = getProfile();
    // if (profile.roleId == ROLE.AGENCY.VALUE) {
    //   debugger;
    //   if (staffRole === 8) {
    //     staffRole = 3;
    //   }
    // } else if (
    //   profile.roleId == ROLE.ADMIN.VALUE
    //   //  ||
    //   // profile.roleId == ROLE.DISTRIBUTION_CHANNEL.VALUE
    // ) {
    //   debugger;
    //   if (staffRole === 3) {
    //     staffRole = 8;
    //   }
    // }
    const params = {
      adminEmployeeDto: {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        companyName: values.companyName.trim(),
        taxId: values.taxId.trim(),
        address: values.address.trim(),
        city: values.city.trim(),
        state: values.state.trim(),
        zip: values.zip.trim(),
        phone: values.phone.trim(),
        email: values.email.trim(),
        website: values.website.trim(),
        roleId: staffRole,
        agencyId: agencyId?.value,
      },
    };
    var result;
    if (isEditModal) {
      // use this modal for edit mode
      params.adminEmployeeDto.contactId = employeeInformation?.contactId;
      result = await updateEmployeeAPI(params);
    } else {
      result = await addNewEmployee(params);
    }

    if (result && result?.data?.success) {
      handleClose(true);
      toast.success(result?.data?.description);
    } else if (
      result &&
      result?.data?.success === false &&
      result?.data?.description === ALL_TEXT.BUSINESS_AMB_LIMIT
    ) {
      handleClose(true);
      dispatch(
        setModalDetails({
          type: ENUMS.POPUP_ENUMS.ALERT,
          details: result?.data?.description,
        }),
      );
      dispatch(openModal());
    } else {
      dispatch(
        setModalDetails({
          type: ENUMS.POPUP_ENUMS.ERROR,
          details: result?.data?.description,
        }),
      );
      setFormSubmitted(false);
      dispatch(openModal());
    }
  };
  const getModalTitle = () => {
    if (editCustomer) {
      return ALL_TEXT.EDIT_CUSTOMER;
    }
    if (isEditModal) {
      return 'Edit Staff';
    } else if (addStaffMember) {
      return ALL_TEXT.ADD_STAFF;
    } else {
      return ALL_TEXT.USER_DETAIL.ADD_NEW_USER_TITLE;
    }
  };

  const getInitialValues = () => {
    if (isEditModal && userlogin === 9) {
      const role = getStringFromEnumsObj(
        ENUMS.STAFF.ROLE,
        ENUMS.STAFF.ROLE.AGENT.VALUE,
      );
      return {
        firstName: employeeInformation?.firstName || '',
        lastName: employeeInformation?.lastName || '',
        email: employeeInformation?.email || '',
        phone: employeeInformation?.phone || '',
        address: employeeInformation?.address || '',
        city: employeeInformation?.city || '',
        state: employeeInformation?.state || '',
        zip: employeeInformation?.zip || '',
        website: employeeInformation?.website || '',
        role: {value: role, label: role},
        companyName: employeeInformation?.companyName || '',
        taxId: employeeInformation?.taxId || '',
        agencyId: {
          value: employeeInformation?.agencyId,
          label: employeeInformation?.companyName,
        },
      };
    } else if (!isEditModal && userlogin === 9) {
      const role = getStringFromEnumsObj(
        ENUMS.STAFF.ROLE,
        ENUMS.STAFF.ROLE.AGENT.VALUE,
      );
      return {
        firstName: employeeInformation?.firstName || '',
        lastName: employeeInformation?.lastName || '',
        email: employeeInformation?.email || '',
        phone: employeeInformation?.phone || '',
        address: employeeInformation?.address || '',
        city: employeeInformation?.city || '',
        state: employeeInformation?.state || '',
        zip: employeeInformation?.zip || '',
        website: employeeInformation?.website || '',
        role: {value: role, label: role},
        companyName: employeeInformation?.companyName || '',
        taxId: employeeInformation?.taxId || '',
        agencyId: {
          value: employeeInformation?.agencyId,
          label: employeeInformation?.companyName,
        },
      };
    } else if (isEditModal) {
      // returns if the add-staff API is in edit mode
      const role = getStringFromEnumsObj(
        ENUMS.STAFF.ROLE,
        employeeInformation?.roleId,
      );

      return {
        firstName: employeeInformation?.firstName || '',
        lastName: employeeInformation?.lastName || '',
        email: employeeInformation?.email || '',
        phone: employeeInformation?.phone || '',
        address: employeeInformation?.address || '',
        city: employeeInformation?.city || '',
        state: employeeInformation?.state || '',
        zip: employeeInformation?.zip || '',
        website: employeeInformation?.website || '',
        role: {value: role, label: role},
        companyName: employeeInformation?.companyName || '',
        taxId: employeeInformation?.taxId || '',
        agencyId: {
          value: employeeInformation?.agencyId,
          label: employeeInformation?.companyName,
        },
      };
    } else {
      return {
        firstName: userDetailsInfo.firstName || '',
        lastName: userDetailsInfo.lastName || '',
        email: userDetailsInfo.email || '',
        phone: userDetailsInfo.phone || '',
        address: userDetailsInfo.address || '',
        city: userDetailsInfo.city || '',
        state: userDetailsInfo.state || '',
        zip: userDetailsInfo.zip || '',
        website: '',
        role: null,
        companyName: employeeInformation?.companyName || '',
        taxId: employeeInformation?.taxId || '',
        agencyId: null,
      };
    }
  };

  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        validateOnMount={true}
        validationSchema={
          addStaffMember
            ? selectedRole == 'agency' || editSelectedRole === 5
              ? companyDetailsSchema
              : editSelectedRole === 3 || editSelectedRole === 9
              ? agentAndAgencyManagerSchema
              : userDetailSchema
            : customerSchema
        }
        enableReinitialize
        onSubmit={(values, formik) => {
          if (editCustomer) {
            editCustomerHandler(values, formik);
          } else if (addStaffMember) {
            //handeling this modal for Staff maintainance API
            addNewStaffMember(values, formik);
          } else {
            performAddUser(values, formik);
          }
          setFormSubmitted(true);
        }}>
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          values,
          touched,
          errors,
        }) => (
          <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton={handleClose}>
              <h5 class="fw-bold">{getModalTitle()}</h5>
            </Modal.Header>
            {isEditModal && getCurrentEmployeInfoResponse.isFetching ? (
              <div className="loader-container-dasboard">
                <Loader />
              </div>
            ) : (
              <Modal.Body>
                <div className="user-detail">
                  <Grid container spacing={2}>
                    {addStaffMember && (
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <label htmlFor="/">{' Role*'}</label>
                          <CriteriaDropdown
                            options={getRoleDropDownValues()}
                            name={'role'}
                            id={'role'}
                            selectedOption={values.role}
                            error={touched.role && errors.role}
                            dropdowntitle={[ALL_TEXT.SELECT + ' Role']}
                            onChangeOption={e => {
                              setFieldValue('role', e);

                              handleChange('role');
                              setSelectedRole(e.value.toLowerCase());
                              setSelectedAgency({});
                              if (
                                getProfile().roleId === 1 &&
                                (e.value.toLowerCase() ===
                                  ALL_TEXT.AGEBT_SMALL ||
                                  e.value.toLowerCase() ===
                                    ALL_TEXT.SMALL_AGENCY_MANAGER)
                              ) {
                                setButtondisable(true);
                              } else {
                                setButtondisable(false);
                              }
                            }}
                            disabled={formSubmitted}
                            isDisable={userlogin === 9}
                          />
                        </Grid>
                        {values?.role?.value?.toLowerCase() ===
                          ALL_TEXT.AGENCY && (
                          <div className="company-contact-Info">
                            <h6>
                              <b>Company Details</b>
                            </h6>
                            <div className="company-container">
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <label htmlFor="/">
                                  {ALL_TEXT.USER_DETAIL.COMPANY_NAME}
                                </label>
                                <InputField
                                  id={'companyName'}
                                  name={'companyName'}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  error={
                                    touched.companyName && errors.companyName
                                  }
                                  value={values.companyName}
                                  disabled={formSubmitted}
                                />
                              </Grid>
                              <div style={{width: 10}} />
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <label htmlFor="/">
                                  {ALL_TEXT.USER_DETAIL.TAX_ID}
                                </label>
                                <InputField
                                  id={'taxId'}
                                  name={'taxId'}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  error={touched.taxId && errors.taxId}
                                  value={values.taxId}
                                  disabled={formSubmitted}
                                />
                              </Grid>
                            </div>
                          </div>
                        )}
                        {(values?.role?.value?.toLowerCase() ===
                          ALL_TEXT.AGEBT_SMALL ||
                          values?.role?.value?.toLowerCase() ===
                            ALL_TEXT.SMALL_AGENCY_MANAGER) &&
                          getProfile().roleId === 1 && (
                            <div className="agency-select">
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}>
                                <label htmlFor="/">
                                  {ALL_TEXT.AGENT_DETAILS.AGENCY_NAME}
                                </label>
                                <CriteriaDropdown
                                  id={'agencyId'}
                                  name={'agencyId'}
                                  options={getrolebaseAgencies(dataList)}
                                  error={touched.agencyId && errors.agencyId}
                                  onInputChange={i => {
                                    if (i.length >= 2) {
                                      getRoleBaseAgneciesAPI({
                                        agencyNameLetters: i,
                                      });
                                    } else {
                                      getRoleBaseAgneciesAPI({
                                        agencyNameLetters: '',
                                      });
                                    }
                                  }}
                                  loading={
                                    getRoleBaseAgneciesResponse.isLoading
                                  }
                                  dropdowntitle={ALL_TEXT.SELECT_AGENCY}
                                  noOptionsMessage={() => 'No Agency Found'}
                                  onChangeOption={e => {
                                    setSelectedAgency(e);
                                    setButtondisable(false);
                                    setAgencyId(e);
                                  }}
                                  selectedOption={getObjectOrNullOrUndefined(
                                    selectedAgency,
                                  )}
                                />
                              </Grid>
                            </div>
                          )}
                      </>
                    )}
                    {values?.role?.value?.toLowerCase() === 'agency' ? (
                      <div className="company-contact-Info">
                        <h6>
                          <b>Person Contact</b>
                        </h6>
                        <div className="person-container">
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <label htmlFor="/">
                              {ALL_TEXT.USER_DETAIL.FIRST_NAME}
                            </label>
                            <InputField
                              id={'firstName'}
                              name={'firstName'}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              error={touched.firstName && errors.firstName}
                              value={values.firstName}
                              disabled={formSubmitted}
                              placeholder={'Enter first name'}
                            />
                          </Grid>
                          <div style={{width: 10}} />
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <label htmlFor="/">
                              {ALL_TEXT.USER_DETAIL.LAST_NAME}
                            </label>
                            <InputField
                              id={'lastName'}
                              name={'lastName'}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              error={touched.lastName && errors.lastName}
                              value={values.lastName}
                              disabled={formSubmitted}
                              placeholder={'Enter last name'}
                            />
                          </Grid>
                        </div>
                        <div className="person-container">
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <label htmlFor="/">
                              {ALL_TEXT.USER_DETAIL.PHONE}
                            </label>
                            <InputField
                              id={'phone'}
                              name={'phone'}
                              handleChange={e =>
                                handleChangePhoneNumber(e, setFieldValue)
                              }
                              handleBlur={handleBlur}
                              error={touched.phone && errors.phone}
                              value={values.phone}
                              phone
                              // disabled={formSubmitted}
                              placeholder={'Enter phone'}
                            />
                          </Grid>
                          <div style={{width: 10}} />
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <label htmlFor="/">
                              {ALL_TEXT.USER_DETAIL.EMAIL}
                            </label>
                            <InputField
                              id={'email'}
                              name={'email'}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              error={touched.email && errors.email}
                              value={values.email}
                              // disabled={formSubmitted}
                              placeholder={'Enter email'}
                            />
                          </Grid>
                        </div>
                        <div className="person-container">
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <label htmlFor="/">
                              {ALL_TEXT.USER_DETAIL.ADDRESS}
                            </label>
                            <Autocomplete
                              className="input-field-container p-2 drop-down-height"
                              apiKey={ALL_TEXT.ADDRESSKEY}
                              defaultValue={values.address}
                              placeholder="Enter address"
                              onChange={handleChange('address')}
                              onBlur={handleBlur('address')}
                              onPlaceSelected={data => {
                                AddressSeprateHandler(
                                  data.address_components,
                                  setFieldValue,
                                  data.formatted_address,
                                );
                              }}
                              options={{
                                componentRestrictions: {country: 'us'},
                              }}
                              disabled={formSubmitted}
                            />
                            <div className="error-input-container">
                              {errors.address && touched.address ? (
                                <p className="form-error">{errors.address}</p>
                              ) : null}
                            </div>
                          </Grid>
                          <div style={{width: 10}} />
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <label htmlFor="/">
                              {ALL_TEXT.USER_DETAIL.CITY}
                            </label>
                            <InputField
                              id={'city'}
                              name={'city'}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              error={touched.city && errors.city}
                              value={values.city}
                              placeholder={'Enter city'}
                            />
                          </Grid>
                        </div>
                        <div className="person-container">
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <label htmlFor="/">
                              {ALL_TEXT.USER_DETAIL.STATE}
                            </label>
                            <InputField
                              id={'state'}
                              name={'state'}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              error={touched.state && errors.state}
                              value={values.state}
                              disabled={formSubmitted}
                              placeholder={'Enter state'}
                            />
                          </Grid>
                          <div style={{width: 10}} />
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <label htmlFor="/">
                              {ALL_TEXT.USER_DETAIL.ZIP}
                            </label>
                            <InputField
                              id={'zip'}
                              name={'zip'}
                              handleBlur={handleBlur}
                              handleChange={e =>
                                handleChangeZip(e, setFieldValue)
                              }
                              error={touched.zip && errors.zip}
                              value={values.zip}
                              disabled={formSubmitted}
                              placeholder={'Enter zip code'}
                            />
                          </Grid>
                        </div>
                        {addStaffMember && (
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <label htmlFor="/">{ALL_TEXT.WEBSITE}</label>
                            <InputField
                              id={'website'}
                              name={'website'}
                              disabled={
                                (editCustomer ? true : false) || formSubmitted
                              }
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              error={touched.website && errors.website}
                              value={values.website}
                              placeholder={'Enter website'}
                            />
                          </Grid>
                        )}
                        {/* </div> */}
                      </div>
                    ) : (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <label htmlFor="/">
                            {ALL_TEXT.USER_DETAIL.FIRST_NAME}
                          </label>
                          <InputField
                            id={'firstName'}
                            name={'firstName'}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            error={touched.firstName && errors.firstName}
                            value={values.firstName}
                            disabled={formSubmitted}
                            placeholder={'Enter first name'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <label htmlFor="/">
                            {ALL_TEXT.USER_DETAIL.LAST_NAME}
                          </label>
                          <InputField
                            id={'lastName'}
                            name={'lastName'}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            error={touched.lastName && errors.lastName}
                            value={values.lastName}
                            disabled={formSubmitted}
                            placeholder={'Enter last name'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <label htmlFor="/">
                            {ALL_TEXT.USER_DETAIL.PHONE}
                          </label>
                          <InputField
                            id={'phone'}
                            name={'phone'}
                            handleChange={e =>
                              handleChangePhoneNumber(e, setFieldValue)
                            }
                            handleBlur={handleBlur}
                            error={touched.phone && errors.phone}
                            value={values.phone}
                            phone
                            // disabled={formSubmitted}
                            placeholder={'Enter phone'}
                            // disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <label htmlFor="/">
                            {ALL_TEXT.USER_DETAIL.EMAIL}
                          </label>
                          <InputField
                            id={'email'}
                            name={'email'}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            error={touched.email && errors.email}
                            value={values.email}
                            // disabled={formSubmitted}
                            placeholder={'Enter email'}
                            // disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <label htmlFor="/">
                            {ALL_TEXT.USER_DETAIL.ADDRESS}
                          </label>
                          <Autocomplete
                            className="input-field-container p-2 drop-down-height"
                            apiKey={ALL_TEXT.ADDRESSKEY}
                            defaultValue={values.address}
                            placeholder="Enter address"
                            onChange={handleChange('address')}
                            onBlur={handleBlur('address')}
                            onPlaceSelected={data => {
                              AddressSeprateHandler(
                                data.address_components,
                                setFieldValue,
                                data.formatted_address,
                              );
                            }}
                            options={{
                              componentRestrictions: {country: 'us'},
                            }}
                            disabled={formSubmitted}
                          />
                          <div className="error-input-container">
                            {errors.address && touched.address ? (
                              <p className="form-error">{errors.address}</p>
                            ) : null}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <label htmlFor="/">{ALL_TEXT.USER_DETAIL.CITY}</label>
                          <InputField
                            id={'city'}
                            name={'city'}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            error={touched.city && errors.city}
                            value={values.city}
                            placeholder={'Enter city'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <label htmlFor="/">
                            {ALL_TEXT.USER_DETAIL.STATE}
                          </label>
                          <InputField
                            id={'state'}
                            name={'state'}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            error={touched.state && errors.state}
                            value={values.state}
                            disabled={formSubmitted}
                            placeholder={'Enter state'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <label htmlFor="/">{ALL_TEXT.USER_DETAIL.ZIP}</label>
                          <InputField
                            id={'zip'}
                            name={'zip'}
                            handleBlur={handleBlur}
                            handleChange={e =>
                              handleChangeZip(e, setFieldValue)
                            }
                            error={touched.zip && errors.zip}
                            value={values.zip}
                            disabled={formSubmitted}
                            placeholder={'Enter zip code'}
                          />
                        </Grid>
                        {addStaffMember && (
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <label htmlFor="/">{ALL_TEXT.WEBSITE}</label>
                            <InputField
                              id={'website'}
                              name={'website'}
                              disabled={
                                (editCustomer ? true : false) || formSubmitted
                              }
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              error={touched.website && errors.website}
                              value={values.website}
                              placeholder={'Enter website'}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </div>
              </Modal.Body>
            )}
            <Modal.Footer>
              <GenericButton
                buttonText={buttonText}
                loading={
                  btnLoader ||
                  addNewEmployeResponse?.isLoading ||
                  updateEmployeeResponse.isLoading
                }
                disable={buttonDisable}
                onPress={() => {
                  handleSubmit();
                }}
              />
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
      <GenericModal
        show={showModal}
        type={popUpResponse.type}
        body={popUpResponse.details}
        buttonText={ALL_TEXT.BUTTON_TEXT.OK}
        handleClose={() => {
          if (popUpResponse.type === ENUMS.POPUP_ENUMS.SUCCESS) {
            setShowModal(false);
            handleClose(true);
            navigate({
              pathname: path.addPolicy,
              search: `?${QUERYPARAM.SIGNUPID}=${newuser}&${QUERYPARAM.NEW_USER}=1`,
            });
          } else {
            setShowModal(false);
          }
        }}
      />
    </>
  );
};
