import React from 'react';
import {CellValue} from '../../../components';
import {getEmailLogStatusIcon} from '../../../utils/helpers';
import {setIsLogPopup, setSelectedEmailData} from '../../../redux/slice/logs';
import {useDispatch} from 'react-redux';
import {QUERYPARAM, images} from '../../../common';
import {useNavigate} from 'react-router';
import {path} from '../../../common/routesNames';
import {useState, useEffect} from 'react';
import {getEmployeeIdFromToken} from '../../../utils/localstorage';

export function TableHeading() {
  const dispatch = useDispatch();
  // We will use it for checks on redirection
  // const userLoginEmpId = useSelector(state => state.loginSlice.loginUserEmpId);
  const [loginUserProfile, setLoginUSerProfile] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const logInuser = getEmployeeIdFromToken();
    setLoginUSerProfile(logInuser);
  });
  const handleRedirect = row => {
    const isTargetPage = window.location.pathname === path.emailLogs;
    // if (row.roleId !== 1) {
    if (isTargetPage) {
      if (
        row.signupId === 0 &&
        row.employeeId !== 0 &&
        row.employeeId !== loginUserProfile
      ) {
        navigate({
          pathname: path.staffMaintainance,
          search: `?${QUERYPARAM.EMAIL}=${row.sendTo}`,
        });
      } else if (row.signupId !== 0 && row.employeeId === 0) {
        navigate({
          pathname: path.customerProfile,
          search: `?${QUERYPARAM.SIGNUPID}=${row.signupId}`,
        });
      }
      // else if (row.signupId === 0 && row.employeeId === 0) {
      //   navigate({});
      // }
      // }
    }
  };

  return [
    {
      title: 'User',
      fieldName: 'userEmail',
      dataformat: (cell, row) => {
        return (
          <div className="email-logs-email">
            <p>{row.userName}</p>
            <p onClick={() => handleRedirect(row)}>{row?.sendTo}</p>
          </div>
        );
      },
    },
    {
      title: 'Module',
      fieldName: 'moduleAction',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Send by',
      fieldName: 'sentBy',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Sent',
      fieldName: 'sent',
      dataformat: cell => {
        const status = cell;
        const icon = getEmailLogStatusIcon(status);
        return <div>{icon}</div>;
      },
    },
    {
      title: 'Send Date',
      fieldName: 'sentDate',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Send Time',
      fieldName: 'sentTime',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Last Login',
      fieldName: 'lastLogin',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Actions',
      fieldName: '',
      dataformat: (cell, row) => (
        <div>
          <span className="action-icons-staff">
            <span
              className="search-icon-"
              onClick={() => {
                dispatch(setIsLogPopup(true));
                dispatch(setSelectedEmailData(row));
              }}>
              <img src={images.searchIcon} />
            </span>
          </span>
        </div>
      ),
    },
  ];
}
