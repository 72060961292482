import React from 'react';
import {ENUMS} from '../../common';
import {GenericBadge} from '../../components';
import './style.scss';

const {
  USERS: {ROLE, STATUS},
  INVOICES: {PAID, FAILED, OPENED, UPCOMING},
  CLAIMS: {STAND},
  STAFF: {ROLE: STAFF_ROLE},
  RISKS: {HIGH, MEDIUM, LOW},
  SUBSCRIPTION_TYPES: {BIANNUAL, ANNUAL, MONTHLY, MONTH},
  SUBSCRIPTION: {CANCELLED},
} = ENUMS;

const toLower = text => {
  return text?.toLowerCase();
};

export const GetBadge = text => {
  if (
    toLower(text) === toLower(ROLE.REGISTERED.LABEL) ||
    toLower(text) == toLower(STAND.CANCELLED.LABEL) ||
    toLower(text) == toLower(STAFF_ROLE.AGENT.LABEL) ||
    toLower(text) == toLower(STAFF_ROLE.INDEPANDANT_AGENT.LABEL) ||
    toLower(text) == toLower(MONTHLY.LABEL) ||
    toLower(text) == toLower(MONTH.LABEL)
  ) {
    return (
      <GenericBadge
        value={text.charAt(0).toUpperCase() + text.slice(1)}
        colors="gray-badge"
      />
    );
  } else if (
    toLower(text) == toLower(OPENED.LABEL) ||
    toLower(text) == toLower(STAFF_ROLE.CUSTOMER_SUPPORT.LABEL)
  ) {
    return (
      <GenericBadge
        value={text.charAt(0).toUpperCase() + text.slice(1)}
        colors="blue-badge"
      />
    );
  } else if (
    toLower(text) === toLower(ROLE.CUSTOMER.LABEL) ||
    toLower(text) == toLower(UPCOMING.LABEL) ||
    toLower(text) == toLower(STAND.PENDING.LABEL) ||
    toLower(text) == toLower(STAFF_ROLE.SALES_REP.LABEL) ||
    toLower(text) == toLower(MEDIUM.LABEL) ||
    toLower(text) == toLower('PENDING')
  ) {
    return (
      <GenericBadge
        value={text.charAt(0).toUpperCase() + text.slice(1)}
        colors="yellow-badge"
      />
    );
  } else if (
    toLower(text) == toLower(STATUS.ACTIVE.LABEL) ||
    toLower(text) == toLower(PAID.LABEL) ||
    toLower(text) === toLower(STAND.SETTLED.LABEL) ||
    toLower(text) == toLower(STAFF_ROLE.AGENCY.LABEL) ||
    toLower(text) == toLower(LOW.LABEL) ||
    toLower(text) == toLower('VERIFIED')
  ) {
    return (
      <GenericBadge
        value={text.charAt(0).toUpperCase() + text.slice(1)}
        colors="green-badge"
      />
    );
  } else if (
    toLower(text) == toLower(STATUS.INACTIVE.LABEL) ||
    toLower(text) == toLower(FAILED.LABEL) ||
    toLower(text) == toLower(STAND.REJECTED.LABEL) ||
    toLower(text) == toLower(STAFF_ROLE.ADMIN.LABEL) ||
    toLower(text) == toLower(HIGH.LABEL) ||
    toLower(text) == toLower(ANNUAL.LABEL) ||
    // toLower(text) == toLower(STAFF_ROLE.DISTRIBUTION_CHANNEL.LABEL) ||
    toLower(text) == toLower(CANCELLED.LABEL) ||
    toLower(text) == toLower('REJECTED')
  ) {
    return (
      <GenericBadge
        value={text.charAt(0).toUpperCase() + text.slice(1)}
        colors="red-badge"
      />
    );
  } else if (toLower(text) == toLower(BIANNUAL.LABEL)) {
    return (
      <GenericBadge
        value={text.charAt(0).toUpperCase() + text.slice(1)}
        colors="gray-badge-second"
      />
    );
  }
};

export const GetActiveInactiveBadge = text => {
  if (toLower(text) == toLower(STAND.CANCELLED.LABEL)) {
    return (
      <GenericBadge
        value={text.charAt(0).toUpperCase() + text.slice(1)}
        colors="red-badge"
      />
    );
  } else if (toLower(text) == toLower(STATUS.ACTIVE.LABEL)) {
    return (
      <GenericBadge
        value={text.charAt(0).toUpperCase() + text.slice(1)}
        colors="green-badge"
      />
    );
  }
};
