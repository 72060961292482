import React, {useEffect, useState} from 'react';
import './styles.scss';
import {
  CustomizedSearch,
  DisasterAreaModal,
  GenericModal,
  GenericTable,
} from '../../../../components';
import {DisasterAreasTable} from './table';
import {useDispatch, useSelector} from 'react-redux';
import {ALL_TEXT, ENUMS} from './../../../../common/constants';
import {
  NodukAdmnApi,
  usePostApiDisasterGetDisasterAreasListMutation,
} from '../../../../redux/slice/nodukAdmin.ts';
import {Pagination} from '@mui/material';
import {getValuesFromEnumsObj} from '../../../../utils/helpers';
import {
  setAreaModalOpen,
  setIsUpdateStatusModal,
} from '../../../../redux/slice/disaster-areas';
import {toast} from 'react-toastify';

export function DisasterAreas({isDisasterAPI}) {
  const {useLazyGetApiDisasterActiveDeactiveDisasterAreaQuery} = NodukAdmnApi;
  const [disasterAreaAPI, disasterAreaResponse] =
    usePostApiDisasterGetDisasterAreasListMutation();
  const [updateDisasterStatusAPI, updateDisasterStatusResponse] =
    useLazyGetApiDisasterActiveDeactiveDisasterAreaQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [btnLoader, setBtnLoader] = useState(false);
  const [disasterLevelData, setDisasterLevelData] = useState({
    zipCode: 0,
    riskLevel: 0,
    status: 0,
  });
  const [totalPage, setTotalPage] = useState(1);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const fetchDisasterArea = async payload => {
    const response = await disasterAreaAPI(payload);
    if (response && response.data.success) {
      const totalPages = response?.data?.content?.totalPages;
      setTotalPage(totalPages);
    }
  };

  const initialDisasterAreasFetch = () => {
    let payload = {
      disasterPageDto: {
        zipCode: disasterLevelData.zipCode,
        riskLevel: disasterLevelData.riskLevel,
        status: disasterLevelData.status,
        pageNo: currentPage,
        pageSize: 10,
      },
    };
    fetchDisasterArea(payload);
  };

  useEffect(() => {
    initialDisasterAreasFetch();
  }, [currentPage, isDisasterAPI]);

  const disasterAreasData = disasterAreaResponse?.data?.content?.list || [];

  const searchFilterHandler = async (zipCode, riskLevel, status, clear) => {
    let payload;
    if (clear) {
      payload = {
        disasterPageDto: {
          zipCode: 0,
          riskLevel: 0,
          status: 0,
          pageNo: currentPage,
          pageSize: 10,
        },
      };
      setDisasterLevelData({
        zipCode: 0,
        riskLevel: 0,
        status: 0,
      });
    } else {
      const type = getValuesFromEnumsObj(ENUMS.RISKS, riskLevel);
      const status_ = getValuesFromEnumsObj(ENUMS.USERS.STATUS, status);
      setDisasterLevelData({
        zipCode: zipCode,
        riskLevel: type,
        status: status_,
      });
      payload = {
        disasterPageDto: {
          zipCode: zipCode || 0,
          riskLevel: type,
          status: status_,
          pageNo: currentPage,
          pageSize: 10,
        },
      };
    }
    setCurrentPage(1);
    setBtnLoader(true);
    await fetchDisasterArea(payload);
    setBtnLoader(false);
  };

  // Redux
  const dispatch = useDispatch();
  const isAreaModal = useSelector(state => state.disasterAreas.isAreaModalOpen);
  const isUpdateStatusModal = useSelector(
    state => state?.disasterAreas?.isUpdateStatusModal,
  );
  const selectedDisaster = useSelector(
    state => state?.disasterAreas?.selectedDisasterArea,
  );

  const updateStatusHandler = async () => {
    let response = await updateDisasterStatusAPI({
      id: selectedDisaster.id,
      status: !selectedDisaster.status,
    });
    if (response && response.data.success) {
      dispatch(setIsUpdateStatusModal(false));
      toast.success(response.data.description);
      initialDisasterAreasFetch();
    } else {
      toast.error(response.data.description);
    }
  };

  return (
    <>
      <div className="disaster-search-bar">
        <CustomizedSearch
          title={ALL_TEXT.DISASTER.SEARCH_DISASTER_AREA}
          firstLabel={ALL_TEXT.DISASTER.SEARCH_ZIP_CODE}
          firstPlaceholder={ALL_TEXT.DISASTER.ENTER_ZIP_CODE}
          firstDropdown
          firstDropdownLabel={ALL_TEXT.DISASTER.SEARCH_AREA_RISK}
          firstDropdownList={[
            // ENUMS.RISK_LEVEL.ALL.LABEL,
            {label: ENUMS.RISK_LEVEL.HIGH.LABEL, value: 'high'},
            {label: ENUMS.RISK_LEVEL.LOW.LABEL, value: 'low'},
            {label: ENUMS.RISK_LEVEL.MEDIUM.LABEL, value: 'medium'},
            {label: ENUMS.RISK_LEVEL.ALL.LABEL, value: 'all'},
          ]}
          secondDropdown
          secondDropdownLabel={ALL_TEXT.DISASTER.STATUS}
          seconddropdownlist={[
            ENUMS.USERS.STATUS.ALL.LABEL,
            ENUMS.USERS.STATUS.ACTIVE.LABEL,
            ENUMS.USERS.STATUS.INACTIVE.LABEL,
          ]}
          btnLoader={btnLoader}
          onClearFilterHandler={() => {
            searchFilterHandler('', '', '', true);
          }}
          onSearchBtnPress={(
            zipCode,
            _,
            __,
            riskLevelDropDown,
            statusDropDown,
          ) => {
            searchFilterHandler(
              parseInt(zipCode || 0),
              riskLevelDropDown,
              statusDropDown,
            );
          }}
        />
      </div>
      <GenericTable
        headings={DisasterAreasTable()}
        data={disasterAreasData}
        loader={disasterAreaResponse.isLoading}
      />
      {isAreaModal && (
        <DisasterAreaModal
          handleClose={isEditSuccess => {
            if (isEditSuccess === true) {
              initialDisasterAreasFetch();
            }
            dispatch(setAreaModalOpen(false));
          }}
          show={isAreaModal}
        />
      )}
      {isUpdateStatusModal && (
        <GenericModal
          show={isUpdateStatusModal}
          type={ENUMS.POPUP_ENUMS.ALERT}
          title={
            !selectedDisaster.status
              ? ALL_TEXT.DISASTER.ACTIVATE
              : ALL_TEXT.DISASTER.DEACTIVATE
          }
          body={
            !selectedDisaster.status
              ? ALL_TEXT.ACTIVATE_DISASTER_AREA
              : ALL_TEXT.DEACTIVATE_DISASTER_AREA
          }
          buttonText={ALL_TEXT.BUTTON_TEXT.YES}
          button2
          grayButton2
          btnLoader={updateDisasterStatusResponse.isFetching}
          buttonText2={ALL_TEXT.BUTTON_TEXT.NO}
          secondaryBtnPress={() => {
            dispatch(setIsUpdateStatusModal(false));
          }}
          primaryBtnPress={updateStatusHandler}
        />
      )}

      <div className="pagination-container">
        {totalPage > 1 && (
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPage}
            />
          </div>
        )}
      </div>
    </>
  );
}
