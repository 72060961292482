import React, {useState, useEffect} from 'react';
import {BsFillLockFill} from 'react-icons/bs';
import './styles.scss';
import {GenericButton, GenericModal} from '../../components';
import {ALL_TEXT, ENUMS, QUERYPARAM} from '../../common/constants';
import {getPlanBadgColors} from '../../components/plan-card';
import {colors, images} from '../../common';
import {Grid} from '@mui/material';
import {path} from '../../common/routesNames';
import {useLocation, useNavigate} from 'react-router';
import {
  AddCustomerStripeSubId,
  CreateOderSubscription,
} from '../../utils/rest-services';
import AddPolicyMainLayout from '../add-policy-main-layout';
import {useDispatch, useSelector} from 'react-redux';
import {openModal, setModalDetails} from '../../utils/store/slices/popup-modal';
import {useStripe} from '@stripe/react-stripe-js';
import {setSelectedCard} from '../../redux/slice/checkout';
import {HiOutlineInformationCircle} from 'react-icons/hi2';
import 'react-tooltip/dist/react-tooltip.css';
import {Tooltip} from 'react-tooltip';

// const taxPercentage = 2.9;
// const processingFee = 1;
export const ItemInfo = ({text, price, duration}) => {
  return (
    <div className="total-amount-container">
      <span>{text}</span>
      <span>
        {price}{' '}
        {duration && (
          <span>
            {`${
              duration?.toLowerCase() === 'once'
                ? duration + '(Single Invoice)'
                : duration + '(All Invoices)'
            }`}{' '}
          </span>
        )}
      </span>
    </div>
  );
};
const ReviewPay = () => {
  let navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isNewuser = queryParams.get('newUser');
  const newuser = queryParams.get('signupId');

  const selectedPlanId = useSelector(state => {
    return state.checkout.selectedPlanId;
  });
  const selectedCard = useSelector(state => {
    return state.checkout.selectedCard;
  });
  const signupId = useSelector(
    state => state.checkout.selectedUser?.signupId ?? null,
  );
  const email = useSelector(
    state => state.checkout.selectedUser?.email ?? null,
  );
  // const selectedPlan = useSelector(state => {
  //   return state.checkout;
  // });
  // const discounts = useSelector(state => {
  //   return state.checkout.discounts;
  // });
  // const couponCodeText = useSelector(state => {
  //   return state.checkout.couponCodeText;
  // });
  // const paymentdetails = useSelector(state => {
  //   return state.checkout.paymentdetails;
  // });
  // const duration = useSelector(state => {
  //   return state.checkout.duration;
  // });
  // const couponType = useSelector(state => {
  //   return state.checkout.couponType;
  // });
  const taxPercentage = useSelector(state => {
    return state.checkout.taxPercent;
  });

  // New States
  // const monthlyPlanData = useSelector(
  //   state => state.checkout?.monthlyPlanData ?? null,
  // );
  // const biAnnualPlanData = useSelector(
  //   state => state.checkout?.biAnnualPlanData ?? null,
  // );
  // const annualPlanData = useSelector(
  //   state => state.checkout.annualPlanData ?? null,
  // );
  const processingFee = useSelector(
    state => state.checkout.processingFee ?? null,
  );
  const selectedPlanRecords = useSelector(
    state => state.checkout.selectedPlanRecords ?? null,
  );
  const cartItems = useSelector(state => state.checkout.cartItems ?? null);
  const totalDeductibles = useSelector(
    state => state.checkout.totalDeductibles ?? null,
  );

  // const [cartItems, setCartItems] = useState(null);
  // const [totalDeductibles, setTotalDeductibles] = useState(0);
  const [btnLoader, setBtnLoader] = useState(false);
  const [openInternalModal, setOpenInternalModal] = useState(false);
  // const [loader, setLoader] = useState(false);
  const [popupDetails, setPopupDetails] = useState({});
  const dispatch = useDispatch();
  const stripe = useStripe();

  // const taxedValue = value => (value * taxPercentage) / 100;

  useEffect(() => {
    if (signupId) {
      // fetchCheckoutObject();
    } else {
      navigate(
        {pathname: path.addPolicy, search: location.search},
        {replace: true},
      );
    }
  }, []);

  // const getCalculatedDiscount = () => {
  //   const totalCost = getPlansCostWithoutDiscount(
  //     selectedPlan?.selectedPlanId,
  //   ).replace(/\$|,/g, '');
  //   let monthlyCost = getPlansCostWithoutDiscount(ENUMS.PLANS_ID.MONTHLY);
  //   monthlyCost = monthlyCost.replace(/\$|,/g, '');
  //   if (discounts?.percentage) {
  //     return totalCost - discounts?.percentage * totalCost;
  //   } else if (discounts?.amount) {
  //     return totalCost - discounts?.amount;
  //   } else if (duration === FIRST_INVOICE) {
  //     return parseFloat(totalCost - monthlyCost);
  //   } else {
  //     return parseFloat(selectedPlan?.totalCost?.replace(/\$|,/g, ''));
  //   }
  // };

  // const getTotalVisible = () => {
  //   let currentInvoice = getCalculatedDiscount();
  //   const taxAmount = taxedValue(currentInvoice);
  //   return `$${(taxAmount + processingFee + currentInvoice).toFixed(2)}`;
  // };

  // const getCurrentInvoice = () => {
  //   return `$${getCalculatedDiscount()?.toFixed(2)}`;
  // };
  // const getFutureInvoice = () => {
  //   const totalCost = parseFloat(selectedPlan?.totalCost?.replace(/\$|,/g, ''));
  //   const withOutDiscount = parseFloat(
  //     getPlansCostWithoutDiscount(selectedPlan?.selectedPlanId)?.replace(
  //       /\$|,/g,
  //       '',
  //     ),
  //   );
  //   if (duration == FOREVER_INVOICE) {
  //     return `$${(
  //       getCalculatedDiscount() + taxedValue(getCalculatedDiscount())
  //     ).toFixed(2)}`;
  //   } else if (duration == ONCE_INVOICE) {
  //     return `$${(withOutDiscount + taxedValue(withOutDiscount)).toFixed(2)}`;
  //   } else if (duration == FIRST_INVOICE) {
  //     return `$${(withOutDiscount + taxedValue(withOutDiscount)).toFixed(2)}`;
  //   } else {
  //     return `$${(totalCost + taxedValue(totalCost)).toFixed(2)}`;
  //   }
  // };

  // const getTaxVisible = () => {
  //   const discountedAmount = getCalculatedDiscount();
  //   return taxedValue(discountedAmount) + processingFee;
  // };

  // const getDiscountVisible = () => {
  //   if (discounts?.percentage) {
  //     return `${discounts?.percentage * 100}%`;
  //   } else if (discounts?.amount) {
  //     return `$${discounts?.amount}`;
  //   } else {
  //     return 'No Discount';
  //   }
  // };
  // const getPlansCostWithoutDiscount = selectedPlanId => {
  //   if (selectedPlanId == ENUMS.PLANS_ID.MONTHLY) {
  //     return paymentdetails?.monthlyCost;
  //   } else if (selectedPlanId == ENUMS.PLANS_ID.BIANNUAL) {
  //     return `$${(
  //       parseFloat(paymentdetails?.monthlyCost.replace(/\$|,/g, '')) * 6
  //     ).toFixed(2)}`;
  //   } else {
  //     return `$${(
  //       parseFloat(paymentdetails?.monthlyCost.replace(/\$|,/g, '')) * 12
  //     ).toFixed(2)}`;
  //   }
  // };
  // const fetchCheckoutObject = async () => {
  //   setLoader(true);
  //   let result = await getCheckoutDetails(signupId);
  //   setLoader(false);
  //   if (result && result?.success) {
  //     const {
  //       content: {cartItemDetail, totals, fees},
  //     } = result;
  //     // dispatch(setProcessingFee(fees.processingFee));
  //     // dispatch(setTaxPercentage(fees.taxPercent));
  //     // setCartItems(cartItemDetail);
  //     // setTotalDeductibles(totals.totalDeductibles);
  //   } else {
  //     setPopupDetails({
  //       title: ALL_TEXT.GENERIC_MODAL.ERROR,
  //       type: ENUMS.MODAL_TYPES.ERROR,
  //       details: ALL_TEXT.CHECKOUT_ERROR,
  //     });
  //     setOpenInternalModal(true);
  //   }
  // };

  const PlaceOrderHandler = () => {
    const subscriptionObject = {
      paymentMethodId: selectedCard?.paymentMethodId,
      subscriptionType: getPlanType(),
      saveCard: selectedCard.isCardSave,
      nameOnCard: selectedCard.cardName,
      newCard: selectedCard.newCard || false, // we will updat it for exisiting cards
      paymentPlatform: 1,
      signupId: signupId,
      email: email,
      promoCode: selectedPlanRecords?.appliedDiscount?.couponCode || '',
      couponType: selectedPlanRecords?.appliedDiscount?.discountType || 0,
    };
    subscriptionWithExistingCard(subscriptionObject);
  };

  const subscriptionWithExistingCard = async subscriptionObject => {
    setBtnLoader(true);
    try {
      let subscripResult = await CreateOderSubscription(subscriptionObject);
      if (subscripResult?.success != true) {
        openErrorModal(
          subscripResult?.exceptionMessage || subscripResult?.description,
        );
        setBtnLoader(false);
        return;
      }
      const {
        content: {clientSecret, paymethodId, stripeSubsId},
        success,
      } = subscripResult || {};
      if (subscripResult && success) {
        const {paymentIntent, error} = await stripe.confirmCardPayment(
          clientSecret,
          {
            [`payment_method`]: paymethodId,
          },
        );

        if (paymentIntent) {
          saveStripeID(stripeSubsId); // incase you have confirm Payment from stripe
        } else {
          openErrorModal(
            error?.message ||
              'Something went wrong while procesing your payment',
          );
          setBtnLoader(false);
          return;
        }
      }
    } catch (error) {
      openErrorModal(error);
      setBtnLoader(false);
      return;
    }
  };
  const openSuccessModal = details => {
    setPopupDetails({
      title: ALL_TEXT.GENERIC_MODAL.SUCCESS,
      type: ENUMS.MODAL_TYPES.SUCCESS,
      details: details || ALL_TEXT.CHECKOUT_ERROR,
    });
    setOpenInternalModal(true);
  };

  const openErrorModal = details => {
    dispatch(
      setModalDetails({
        title: ALL_TEXT.GENERIC_MODAL.ERROR,
        type: ENUMS.MODAL_TYPES.ERROR,
        details: details || ALL_TEXT.CHECKOUT_ERROR,
      }),
    );
    dispatch(openModal());
  };
  const saveStripeID = async stripeSubsId => {
    let response = await AddCustomerStripeSubId({
      signupId: signupId, // temporary
      email: email,
      subscriptionId: stripeSubsId,
    });
    if (response && response?.success) {
      const {success} = response || {};
      if (success) {
        dispatch(setSelectedCard(null));
        setBtnLoader(false);
        openSuccessModal(ALL_TEXT.SUCCESS_PAYMENT_DETAILS);
      } else {
        openErrorModal(response?.data?.description);
      }
    }
  };

  const getRecurringTotalByItem = value => {
    if (selectedPlanId === ENUMS.PLANS_ID.MONTHLY) {
      return value * 1;
    } else if (selectedPlanId === ENUMS.PLANS_ID.BIANNUAL) {
      return value * 6;
    } else {
      return value * 12;
    }
  };

  // const getDiscountOnPlans = () => {
  //   if (selectedPlanId == ENUMS.PLANS_ID.MONTHLY) {
  //     return ALL_TEXT.DISCOUNTS_PLANS.MONTHLY;
  //   } else if (selectedPlanId == ENUMS.PLANS_ID.BIANNUAL) {
  //     return ALL_TEXT.DISCOUNTS_PLANS.BIANNUAL;
  //   } else {
  //     return ALL_TEXT.DISCOUNTS_PLANS.ANNUALLY;
  //   }
  // };

  const getPlanType = () => {
    if (selectedPlanId == ENUMS.PLANS_ID.MONTHLY) {
      return ALL_TEXT.PlANS.MONTHLY;
    } else if (selectedPlanId == ENUMS.PLANS_ID.BIANNUAL) {
      return ALL_TEXT.PlANS.BIANNUAL;
    } else {
      return ALL_TEXT.PlANS.ANNUAL;
    }
  };

  const IconSelector = id => {
    switch (id) {
      case ENUMS.SERVICE_TYPE.VEHICLE:
        return <img src={images.vehicleV} alt={ALL_TEXT.ICON} />;
      case ENUMS.SERVICE_TYPE.HOME:
        return <img src={images.homeV} alt={ALL_TEXT.ICON} />;
      case ENUMS.SERVICE_TYPE.DEVICE:
        return <img src={images.devicesV} alt={ALL_TEXT.ICON} />;
      case ENUMS.SERVICE_TYPE.COMMERCIAL:
        return <img src={images.commercialV} alt={ALL_TEXT.ICON} />;
      case ENUMS.SERVICE_TYPE.DISASTER:
        return <img src={images.disasterV} alt={ALL_TEXT.ICON} />;
    }
  };
  const DeductibleItem = ({IconId, deductible, recurring}) => {
    return (
      <div className="subscription-table-item">
        <span className="subscription-icon-container">
          {IconSelector(IconId)}
        </span>
        <span className="subscription-deductibles-container">{deductible}</span>
        <span>{recurring}</span>
      </div>
    );
  };
  const getDisplayCost = value => `$${parseFloat(value).toFixed(2)}`;
  const checkPercentageOrDollarChange = isPercentageDiscount => {
    if (isPercentageDiscount) {
      return `${selectedPlanRecords?.appliedDiscount?.discountPercentage}%`;
    } else {
      return getDisplayCost(
        selectedPlanRecords?.appliedDiscount?.discountedAmount,
      );
    }
  };
  return (
    <AddPolicyMainLayout selectedId={2}>
      <div className="new-subscription-box">
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <div className="new-subscription-container">
              <section className="subscription-container">
                <div className="subscription-top-wrapper w-100">
                  <div className="subscription-title">
                    <span>{ALL_TEXT.SUBSCRIPTION.SUBSCRIPTION_SUMMARY} </span>
                    <div
                      className={`subscription-type ${
                        getPlanBadgColors(selectedPlanId).background
                      } `}>
                      <span>{getPlanType()} </span>
                    </div>
                    <span className="payment-card-type">
                      {selectedCard && (
                        <i
                          class={`fa-brands fa-cc-${selectedCard.cardType}`}
                          style={{
                            color: colors.primary,
                            fontSize: '30px',
                            marginRight: '10px',
                          }}></i>
                      )}
                      <span className="card-payment-info">
                        {selectedCard
                          ? `*** *** *** ${selectedCard.last4}`
                          : ALL_TEXT.SELECT_PAYMENT.SELECT_PAYMENT_METHOD}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="subscription-table-heading">
                  <span>{ALL_TEXT.SUBSCRIPTION_PLANS_HEADING.ITEM}</span>
                  <span>{ALL_TEXT.SUBSCRIPTION_PLANS_HEADING.DEDUCTIBLES}</span>
                  <span>{ALL_TEXT.SUBSCRIPTION_PLANS_HEADING.RECURRING}</span>
                </div>
                {cartItems &&
                  cartItems?.map((value, index) => (
                    <DeductibleItem
                      key={index}
                      IconId={value.serviceTypeId}
                      deductible={`$${value.deductibleAmount.toFixed(2)}`}
                      recurring={`$${getRecurringTotalByItem(
                        value.cost,
                      ).toFixed(2)}`}
                    />
                  ))}
                <ItemInfo
                  text={ALL_TEXT.SUBSCRIPTION.TOTAL_DEDUCTIBLES}
                  price={totalDeductibles}
                />

                <div className="once-invoice-container">
                  <ItemInfo
                    text={
                      <span>
                        {ALL_TEXT.CURRENT_INVOICE}
                        <HiOutlineInformationCircle
                          data-tooltip-id={'tax-tooltip-1'}
                          data-tooltip-content={`Including $${processingFee} Processing Fee`}
                          style={{
                            color: 'black',
                            marginLeft: '10px',
                            fontWeight: 'bolder',
                            height: '24px',
                            width: '24px',
                          }}
                        />
                        <Tooltip id={'tax-tooltip-1'} />
                      </span>
                    }
                    price={getDisplayCost(selectedPlanRecords?.planAmount)}
                  />
                  <ItemInfo
                    text={ALL_TEXT.SUBSCRIPTION.DISCOUNT}
                    price={
                      selectedPlanRecords?.appliedDiscount ? (
                        <span className="bold-black">
                          {/* {`${selectedPlanRecords?.appliedDiscount?.discountName}  `} */}
                          <span className="bold-red">
                            {`${checkPercentageOrDollarChange(
                              selectedPlanRecords?.appliedDiscount
                                .isPercentageDiscount,
                            )}`}
                          </span>
                        </span>
                      ) : (
                        ALL_TEXT.DISCOUNTS_PLANS.MONTHLY
                      )
                    }
                  />
                  <ItemInfo
                    price={getDisplayCost(
                      selectedPlanRecords?.discountedPlanAmount,
                    )}
                    text={'Invoice after discount'}
                  />
                  <ItemInfo
                    text={
                      <span>
                        {'Sales Tax'}
                        <HiOutlineInformationCircle
                          data-tooltip-id={'tax-tooltip'}
                          data-tooltip-content={`${taxPercentage}% Sales Tax`}
                          style={{
                            color: 'black',
                            marginLeft: '10px',
                            fontWeight: 'bolder',
                            height: '24px',
                            width: '24px',
                          }}
                        />
                        <Tooltip id={'tax-tooltip'} />
                      </span>
                    }
                    price={getDisplayCost(selectedPlanRecords?.currentSalesTax)}
                  />

                  <ItemInfo
                    text={ALL_TEXT.TOTAL_INVOICE}
                    price={getDisplayCost(
                      selectedPlanRecords?.totalTaxedInvoiceAmount,
                    )}
                  />
                </div>
                <ItemInfo
                  text={ALL_TEXT.FUTURE_INVOICE}
                  price={getDisplayCost(
                    selectedPlanRecords?.futureTaxedInvoiceAmount,
                  )}
                />
                <div className="bottom-note">
                  <span>
                    <span>{ALL_TEXT.SUBSCRIPTION.NOTE}</span>
                    {ALL_TEXT.SUBSCRIPTION.NODUK_INFO}
                    <span>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={ALL_TEXT.URLS.HOME}>
                        {ALL_TEXT.SUBSCRIPTION.LEARN_MORE}
                      </a>
                    </span>
                  </span>
                </div>
              </section>
            </div>
          </Grid>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <div className="navgate-controller mt-3">
              <GenericButton
                customStyle={'custom-style-Back'}
                grayButton
                buttonText={ALL_TEXT.BUTTON_TEXT.BACK}
                onPress={() => {
                  navigate(
                    {pathname: path.selectPayment, search: location.search},
                    {replace: true},
                  );
                }}
              />
              <GenericButton
                loading={btnLoader}
                buttonText={
                  <div>
                    <BsFillLockFill className="lock-icon" size={13} />
                    {ALL_TEXT.BUTTON_TEXT.CHECKOUT}
                  </div>
                }
                onPress={() => {
                  PlaceOrderHandler();
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <GenericModal
        show={openInternalModal}
        type={popupDetails?.type}
        body={
          popupDetails?.type === ENUMS.POPUP_ENUMS.SUCCESS ? (
            <div className="payment-success-popup">
              <div> {ALL_TEXT.SUCCESSFULLY_PAYMENT.PAYMENT_RECEIVED}</div>
              {ALL_TEXT.SUCCESSFULLY_PAYMENT.DESCRIPTION}
              <a href={`mailto:${ALL_TEXT.URLS.INFO}`}>{ALL_TEXT.URLS.INFO}</a>
            </div>
          ) : (
            popupDetails?.details
          )
        }
        buttonText={ALL_TEXT.OK}
        primaryBtnPress={() => {
          if (
            popupDetails?.type === ENUMS.MODAL_TYPES.SUCCESS &&
            isNewuser === '1'
          ) {
            navigate({
              pathname: path.customerProfile,
              search: `?${QUERYPARAM.SIGNUPID}=${newuser}`,
            });
          } else {
            navigate({
              pathname: path.addPolicy,
              search: `?${QUERYPARAM.SIGNUPID}=${newuser}`,
            });
          }
        }}
      />
    </AddPolicyMainLayout>
  );
};

export default ReviewPay;
