import React, {useState, useRef} from 'react';
import '../../pages/add-policy/styles.scss';
import {ALL_TEXT, ENUMS, images} from '../../common';
import {CriteriaDropdown, GenericButton, InputField} from '../../components';
import {Grid} from '@mui/material';
import {Formik} from 'formik';
import {addVehicleSchema} from '../../common/schemas';
// import {path} from '../../common/routesNames';
// import {useNavigate} from 'react-router';
import {
  addItemToCart,
  getVehicleModelListById,
} from '../../utils/rest-services';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {openModal, setModalDetails} from '../../utils/store/slices/popup-modal';
import {useDispatch, useSelector} from 'react-redux';

export const AddVehicleInput = ({
  makerItem,
  carrierItem,
  deductibleItem,
  onItemAddedToCart,
}) => {
  const {
    ADD_PLAN_LABELS: {
      CARRIER,
      MAKER,
      MODEL,
      YEAR,
      DEDUCTIBLES,
      OTHER_CARRIER,
      OTHER_DEDUCTIBLES,
    },
  } = ALL_TEXT;

  const signupId = useSelector(
    state => state.checkout.selectedUser?.signupId ?? null,
  );
  const [isOtherDeductibles, setIsOtherDeductibles] = useState(false);
  const [isOtherCarrier, setIsOtherCarrier] = useState(false);
  const [modelItem, setModelItem] = useState([]);
  // let navigate = useNavigate();
  const dispatch = useDispatch();
  const carrierRef = useRef();
  const deductiblesRef = useRef();

  const fetchVehicleModelList = async makerId => {
    let response = await getVehicleModelListById(
      ENUMS.COVERAGE_TYPES.VEHICLE,
      makerId,
    );
    if (response && response.success) {
      const {content} = response;
      setModelItem(content || []);
    }
  };

  const AddVehicleToCart = async (values, actions) => {
    let addToCartPayload = {
      serviceTypeId: ENUMS.COVERAGE_TYPES.VEHICLE.toString(),
      serviceProviderId: values.carrier.id,
      otherServiceProvider: values.otherCarrier,
      deductibleAmount:
        values.deductibles.id === ALL_TEXT.NOT_AVAILABLE_ID
          ? values.otherDeductibles
          : values.deductibles.id,
      make: values.maker.value,
      model: values.model.value,
      year: values.year,
      signupId: signupId, // for the time being its temporary
      cartItemId: 0,
    };
    let result = await addItemToCart(addToCartPayload);
    if (result && result.success) {
      onItemAddedToCart();
      setIsOtherDeductibles(false);
      toast(
        <div className="cart-icon-toaster">
          <img src={images.cartSmall} />
          <span className="toast-text">{result.description}</span>
        </div>,
      );
      actions.resetForm();
      // onItemAddedToCart();
    } else {
      dispatch(
        setModalDetails({
          title: ALL_TEXT.GENERIC_MODAL.ERROR,
          details: result.description,
          type: ENUMS.POPUP_ENUMS.ERROR,
        }),
      );
      dispatch(openModal());
    }
    actions.setSubmitting(false);
  };
  const handleCancel = resetForm => {
    resetForm();
  };

  return (
    <Formik
      initialValues={{
        carrier: null,
        maker: null,
        model: null,
        year: '',
        deductibles: null,
        otherDeductibles: '',
        otherCarrier: '',
      }}
      onSubmit={(values, formikActions) => {
        AddVehicleToCart(values, formikActions);
      }}
      validationSchema={addVehicleSchema(isOtherDeductibles, isOtherCarrier)}
      enableReinitialize={true}>
      {({
        handleChange,
        handleSubmit,
        touched,
        errors,
        values,
        setFieldValue,
        isSubmitting,
        resetForm,
      }) => (
        <>
          <div className="vehicle-input-container">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="selectOptionCOntainer">
                  <CriteriaDropdown
                    label={CARRIER}
                    options={carrierItem}
                    name={'carrier'}
                    selectedOption={values.carrier}
                    error={touched.carrier && errors.carrier}
                    dropdowntitle={ALL_TEXT.SELECT_CARRIER}
                    onChangeOption={e => {
                      setFieldValue('carrier', e);
                      handleChange('carrier');
                      if (e.id === ALL_TEXT.NOT_AVAILABLE_ID) {
                        setIsOtherCarrier(true);
                        setTimeout(() => {
                          carrierRef?.current?.focus();
                        }, 500);
                      } else {
                        setIsOtherCarrier(false);
                      }
                    }}
                  />
                </div>
              </Grid>
              {values?.carrier?.id === ALL_TEXT.NOT_AVAILABLE_ID && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <InputField
                    label={OTHER_CARRIER}
                    value={values.otherCarrier}
                    handleChange={({target: {value}}) => {
                      setFieldValue('otherCarrier', value);
                      handleChange('otherCarrier');
                    }}
                    placeHolder={ALL_TEXT.OTHER_CARRIER}
                    name={ALL_TEXT.OTHER_CARRIER}
                    error={touched.otherCarrier && errors.otherCarrier}
                    reff={carrierRef}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <CriteriaDropdown
                  label={MAKER}
                  options={makerItem}
                  name={'maker'}
                  selectedOption={values.maker}
                  error={touched.maker && errors.maker}
                  dropdowntitle={ALL_TEXT.SELECT_MAKER}
                  onChangeOption={async e => {
                    handleChange('maker');
                    setFieldValue('maker', e);
                    await fetchVehicleModelList(e.id);
                    setFieldValue('model', null);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <CriteriaDropdown
                  label={MODEL}
                  options={modelItem}
                  error={touched.model && errors.model}
                  name={ALL_TEXT.SELECT_MODEL}
                  selectedOption={values.model}
                  dropdowntitle={ALL_TEXT.SELECT_MODEL}
                  onChangeOption={e => {
                    handleChange('model');
                    setFieldValue('model', e);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <InputField
                  label={YEAR}
                  value={values.year}
                  handleChange={({target: {value}}) => {
                    const numericValue = value.replace(/[^0-9+]/g, ''); // remove non-numeric characters
                    const restrictedValue = numericValue.substring(0, 4); // restrict to 4 digits
                    setFieldValue('year', restrictedValue);
                    handleChange('year');
                  }}
                  placeHolder={ALL_TEXT.SELECT_YEAR}
                  name={ALL_TEXT.SELECT_YEAR}
                  error={touched.year && errors.year}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <CriteriaDropdown
                  label={DEDUCTIBLES}
                  options={deductibleItem}
                  error={touched.deductibles && errors.deductibles}
                  dropdowntitle={ALL_TEXT.SELECT_DEDUCTIBLES}
                  name={ALL_TEXT.SELECT_DEDUCTIBLES}
                  selectedOption={values.deductibles}
                  onChangeOption={e => {
                    handleChange('deductibles');
                    setFieldValue('deductibles', e);
                    if (e.id === ALL_TEXT.NOT_AVAILABLE_ID) {
                      setIsOtherDeductibles(true);
                      setTimeout(() => {
                        deductiblesRef?.current?.focus();
                      }, 500);
                    } else {
                      setIsOtherDeductibles(false);
                    }
                  }}
                />
              </Grid>
              {(values?.deductibles?.id === ALL_TEXT.NOT_AVAILABLE_ID ||
                isOtherDeductibles) && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <InputField
                    label={OTHER_DEDUCTIBLES}
                    value={values.otherDeductibles}
                    handleChange={e => {
                      const inputValue = e.target.value;
                      const numericValue = inputValue.replace(/[^0-9.]/g, ''); // remove non-numeric characters
                      const restrictedValue = numericValue.substring(0, 7); // restrict to 10 digits
                      setFieldValue('otherDeductibles', restrictedValue);
                      handleChange('otherDeductibles');
                    }}
                    placeHolder={ALL_TEXT.OTHER_DEDUCTIBLES + ' Amount'}
                    name={ALL_TEXT.OTHER_DEDUCTIBLES}
                    error={touched.otherDeductibles && errors.otherDeductibles}
                    maxLength={7}
                    reff={deductiblesRef}
                  />
                </Grid>
              )}
            </Grid>
          </div>
          <div className="select-user">
            {signupId == null && (
              <>
                <span>{'Note:'}</span>
                <span>{'Please select Customer first to add policy'}</span>
              </>
            )}
          </div>
          <div className="add-policy-btn-container">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <GenericButton
                  onPress={handleSubmit}
                  buttonText={'Add Plan'}
                  disable={signupId == null}
                  loading={isSubmitting}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <GenericButton
                  customStyle={'policy-cancel-button'}
                  buttonText={'Cancel'}
                  grayButton
                  onPress={() => handleCancel(resetForm)}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </Formik>
  );
};
