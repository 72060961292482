import {createSlice} from '@reduxjs/toolkit';

export const LoginSlice = createSlice({
  name: 'login',
  initialState: {
    loginUserEmpId: '',
  },
  reducers: {
    setloginUserEmpId(state, {payload}) {
      state.loginUserEmpId = payload;
    },
  },
});

export const {setloginUserEmpId} = LoginSlice.actions;
