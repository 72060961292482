import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './styles.scss';
import {path} from '../../common/routesNames';
import {useNavigate} from 'react-router';
import {ALL_TEXT} from '../../common';
import {
  clearStorage,
  getDeviceToken_,
  getProfile,
} from '../../utils/localstorage';

import {ENUMS} from '../../common/constants';

import {removeDeviceTokenAPI} from '../../utils/rest-services';

export function ActionDropDownPopUp({
  items,
  visibleIcon,
  userInfo,
  userImage,
  userName,
  userEmail,
  addPolicy,
  cancelPolicy,
}) {
  let navigate = useNavigate();

  const performLogout = async () => {
    await removeDeviceToken();
    clearStorage();
    navigate(path.login, {replace: true});
  };

  const removeDeviceToken = async () => {
    const token = getDeviceToken_();
    await removeDeviceTokenAPI(token);
  };

  const roleHandler = roleId => {
    switch (roleId) {
      case ENUMS.STAFF.ROLE.CUSTOMER_SUPPORT.VALUE:
        return ENUMS.STAFF.ROLE.CUSTOMER_SUPPORT.LABEL;

      case ENUMS.STAFF.ROLE.ADMIN.VALUE:
        return ENUMS.STAFF.ROLE.ADMIN.LABEL;

      case ENUMS.STAFF.ROLE.AGENT.VALUE:
        return ENUMS.STAFF.ROLE.AGENT.LABEL;

      case ENUMS.STAFF.ROLE.AGENCY.VALUE:
        return ENUMS.STAFF.ROLE.AGENCY.LABEL;

      case ENUMS.STAFF.ROLE.BUSINESS_AMB.VALUE:
        return ENUMS.STAFF.ROLE.BUSINESS_AMB.LABEL;

      case ENUMS.STAFF.ROLE.SALES_REP.VALUE:
        return ENUMS.STAFF.ROLE.SALES_REP.LABEL;

      // case ENUMS.STAFF.ROLE.DISTRIBUTION_CHANNEL.VALUE:
      //   return ENUMS.STAFF.ROLE.DISTRIBUTION_CHANNEL.LABEL;
      case ENUMS.STAFF.ROLE.AGENCY_MANAGER.VALUE:
        return ENUMS.STAFF.ROLE.AGENCY_MANAGER.LABEL;

      case ENUMS.STAFF.ROLE.INDEPANDANT_AGENT.VALUE:
        return ENUMS.STAFF.ROLE.INDEPANDANT_AGENT.LABEL;

      default:
        return ENUMS.STAFF.ROLE.ADMIN.LABEL;
    }
  };
  const roleId = getProfile()?.roleId;
  const roleLabel = roleHandler(roleId);
  return (
    <div className="action-menu">
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          <span className="action-popup-style">{visibleIcon}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="action-popup">
          {userInfo && (
            <div className="logout-user-info">
              <div className="user-primary">
                <span>{userImage}</span>
              </div>
              <div className="user-secondry">
                <h5>{userName}</h5>
                <p>{userEmail}</p>
                <p>{roleLabel}</p>
              </div>
            </div>
          )}
          {items?.map((i, index) => (
            <Dropdown.Item
              className="no-underline"
              key={index}
              onClick={i.onPress}>
              <div
                className="action-options"
                onClick={() => {
                  if (i.title === ALL_TEXT.LOGOUT) {
                    performLogout();
                  } else if (i.title === ALL_TEXT.ADD_CLAIM) {
                    addPolicy();
                  } else if (i.title === ALL_TEXT.CANCEL) {
                    cancelPolicy();
                  } else {
                    i.onItemPress();
                  }
                }}>
                {i.icon}
                <span className="text-cancel-policy px-2">{i.title}</span>
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
