// import React from 'react';
// import {VictoryBar, VictoryChart, VictoryAxis, VictoryTheme} from 'victory';
// import {colors} from '../../common';
// import './style.scss';

// const BarChart = ({title, graphData}) => {
//   const limitedGraphData = graphData?.slice(-7);

//   const formatDate = inputDate => {
//     const months = [
//       'Jan',
//       'Feb',
//       'Mar',
//       'Apr',
//       'May',
//       'Jun',
//       'Jul',
//       'Aug',
//       'Sep',
//       'Oct',
//       'Nov',
//       'Dec',
//     ];
//     const date = new Date(inputDate);
//     const day = date.getDate();
//     const month = months[date.getMonth()];

//     return `${day} ${month}`;
//   };

//   return (
//     <div className="bar-chart-dashb">
//       <h5>{title}</h5>
//       <VictoryChart
//         width={500} // Adjust the width of the chart here
//         height={400} // Adjust the height of the chart here
//         theme={VictoryTheme.material}
//         domainPadding={15}>
//         <VictoryAxis
//           tickValues={limitedGraphData?.map(item => item.count)}
//           tickFormat={limitedGraphData?.map(item => formatDate(item.date))}
//           style={{
//             tickLabels: {fontWeight: 'bold'},
//             fill: 'black',
//             fontSize: 14,
//           }}
//         />

//         <VictoryAxis
//           dependentAxis
//           tickFormat={t => Math.round(t)}
//           style={{
//             tickLabels: {fontWeight: 'bold'},
//             fill: 'black',
//             fontSize: 14,
//           }}
//           domain={[10, 50]}
//         />
//         <VictoryBar
//           data={limitedGraphData}
//           // barWidth={({index}) => index * 5 + 8}
//           barWidth={25}
//           barRatio={0.8}
//           x="date"
//           y="count"
//           style={{
//             data: {fill: colors.primary}, // Change bar color here
//           }}
//         />
//       </VictoryChart>
//     </div>
//   );
// };

// export default BarChart;

import React from 'react';
import {Bar} from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import {colors} from '../../common';
import {LinearScale} from 'chart.js';
import './style.scss';
Chart.register(LinearScale);

const HorizontalScrollableBarChart = ({title, graphData}) => {
  if (!graphData || !Array.isArray(graphData)) {
    return (
      <div className="not-available-user">
        <h5>Registered Count</h5>
        <div className="not-available-user-chart">
          <span>No data available</span>
        </div>
      </div>
    );
  }
  const data = {
    labels: graphData.map(item => item.date),
    datasets: [
      {
        label: title,
        data: graphData.map(item => item.count),
        backgroundColor: colors.primary,
        borderColor: colors.primary,
        borderWidth: 1,
      },
    ],
  };
  const options = {
    indexAxis: 'x',
    scales: {
      y: {
        beginAtZero: false,
        min: 0,
        max: 10,
        ticks: {
          color: 'black',
          font: {
            weight: 'bold',
          },
        },
      },
      x: {
        beginAtZero: false,
        ticks: {
          color: 'black',
          font: {
            weight: 'bold',
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          speed: 10,
          ticks: {
            color: 'black',
          },
        },
        zoom: {
          enabled: true,
          mode: 'x',
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 16,
          weight: 'bold',
        },
        align: 'start',
        color: 'black',
      },
    },
  };

  return (
    <div
      className="horizontal-scrollable-chart-container"
      style={{width: '100%', overflowX: 'auto'}}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default HorizontalScrollableBarChart;
