import React from 'react';
import {Navigate} from 'react-router-dom';
import {path} from '../common/routesNames';
import {getProfile, getToken} from '../utils/localstorage';
import {ENUMS} from '../common';

const PrivateRoute = ({children}) => {
  const isLoggedIn = getToken() !== '';
  let userProfile = isLoggedIn ? getProfile() : -1;

  // Check if children.props.roles exists and is an array
  const isAuthorized =
    children.props.roles && Array.isArray(children.props.roles)
      ? children.props.roles.includes(userProfile?.roleId || 0)
      : false;

  if (!isLoggedIn) {
    return <Navigate to={path.login} />;
  } else if (!isAuthorized) {
    if (userProfile?.roleId === ENUMS.STAFF.ROLE.BUSINESS_AMB.VALUE) {
      return <Navigate to={path.generateQr} />;
    } else if (
      userProfile?.roleId === ENUMS.STAFF.ROLE.CUSTOMER_SUPPORT.VALUE
    ) {
      return <Navigate to={path.users} />;
    } else {
      return <Navigate to={path.dashboard} />;
    }
  }

  return children;
};

export default PrivateRoute;
