import React, {useState, useEffect} from 'react';
import './style.scss';
import {Modal} from 'react-bootstrap';
import {ALL_TEXT, ENUMS} from './../../common/constants';
import {GenericButton} from '../generic-button';
import {InputField} from '../input-field';
import {IoIosClose} from 'react-icons/io';
import {Formik} from 'formik';
import {disasterAreaSchema} from '../../common/schemas';
import {CriteriaDropdown} from '../criteria-dropdown';
import {usePostApiDisasterAddUpdateDisasterAreaMutation} from '../../redux/slice/nodukAdmin.ts';
import {toast} from 'react-toastify';
import {getValuesFromEnumsObj} from '../../utils/helpers';
import {useSelector} from 'react-redux';
import _ from 'lodash';

export function DisasterAreaModal({show, handleClose}) {
  const [isZipCodeContainer, setIsZipCodeContainer] = useState(false);
  const [zipCodes, setZipCodes] = useState([]);
  const [risksList, setRisksList] = useState([]);
  const [zipAlreadyExist, setZipAlreadyExist] = useState(false);
  const [riskLevelID, setRiskLevelID] = useState(0);
  const [addUpdateAPI, addUpdateResponse] =
    usePostApiDisasterAddUpdateDisasterAreaMutation();

  const selectedDisaster = useSelector(
    state => state?.disasterAreas?.selectedDisasterArea,
  );
  const riskLevels = useSelector(state => state?.disasterAreas?.risklevels);

  const getRiskDeductibles = () => {
    const risks = _.map(riskLevels, ({riskLevel, deductiblePercent}) => ({
      value: getriskId(riskLevel).value,
      label: getriskId(riskLevel).label,
      deductible: deductiblePercent,
    }));
    setRisksList(risks);
  };

  useEffect(() => {
    getRiskDeductibles();
  }, []);

  const getriskId = txt => {
    if (txt == 'high') {
      return {value: 1, label: 'High Risk'};
    } else if (txt == 'medium') {
      return {value: 2, label: 'Medium Risk'};
    } else return {value: 3, label: 'Low Risk'};
  };

  const removeZipCode = zipCodeToRemove => {
    const filteredZipCodes = zipCodes.filter(
      zipCode => zipCode !== zipCodeToRemove,
    );
    setZipCodes(filteredZipCodes);
  };

  const addEditAreasHandler = async values => {
    let payload = {};
    if (selectedDisaster) {
      payload = {
        disasterReq: {
          id: selectedDisaster.id,
          zipCode: [],
          riskId: values.areaRisk.value, // high,meduim , low
          status: true,
        },
      };
    } else {
      payload = {
        disasterReq: {
          id: 0,
          zipCode: zipCodes,
          riskId: values.areaRisk.value, // high,meduim , low
          status: true,
        },
      };
    }
    let response = await addUpdateAPI(payload);
    if (response && response.data.success) {
      toast(response.data.description);
      handleClose(true);
    } else {
      if (riskLevelID.value === 1) {
        toast.error(ALL_TEXT.DISASTER.High_risk);
      } else if (riskLevelID.value === 2) {
        toast.error(ALL_TEXT.DISASTER.Medium_risk);
      } else {
        toast.error(ALL_TEXT.DISASTER.Low_risk);
      }
    }
  };
  function capitalize(word) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
  }
  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount={true}
        validationSchema={disasterAreaSchema}
        initialValues={{
          zipCode: selectedDisaster ? selectedDisaster.zipCode : '',
          areaRisk: selectedDisaster
            ? {
                value: getValuesFromEnumsObj(
                  ENUMS.RISKS,
                  selectedDisaster.riskLevel,
                ),
                label: `${capitalize(selectedDisaster.riskLevel)} Risk`,
              }
            : null,
          deductibles: selectedDisaster
            ? selectedDisaster.deductiblePercent
            : '',
        }}
        onSubmit={values => {
          addEditAreasHandler(values);
        }}>
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
          errors,
        }) => (
          <Modal show={show} onHide={handleClose} centered size="md">
            <Modal.Header>
              <h5>
                {selectedDisaster
                  ? ALL_TEXT.DISASTER.EDIT_DISASTER_AREAS
                  : ALL_TEXT.DISASTER.ADD_DISASTER_AREAS}
              </h5>
            </Modal.Header>
            <Modal.Body>
              <div className="disaster-modal-body">
                <div className="disaster-zip-action">
                  <div className="disaster-zip-code">
                    <label htmlFor="/">
                      {ALL_TEXT.DISASTER.AREA_ZIP_CODE_}
                    </label>
                    <InputField
                      name={'zipCode'}
                      id={'zipCode'}
                      value={values.zipCode}
                      placeholder={'Enter Zip Code'}
                      handleBlur={handleBlur}
                      disabled={selectedDisaster ? true : false}
                      error={
                        (touched.zipCode && errors.zipCode) ||
                        (zipAlreadyExist && 'Zip Code Alredy Exisits')
                      }
                      handleChange={event => {
                        const inputValue = event.target.value;
                        if (/^\d{0,7}$/.test(inputValue)) {
                          setFieldValue('zipCode', inputValue);
                          setZipAlreadyExist(false);
                        }
                      }}
                    />
                  </div>
                  {!selectedDisaster && (
                    <div className="code-add-button">
                      <GenericButton
                        buttonText={ALL_TEXT.BUTTON_TEXT.ADD}
                        onPress={() => {
                          if (values.zipCode > 0) {
                            if (zipCodes.includes(values.zipCode)) {
                              setZipAlreadyExist(true);
                            } else {
                              setIsZipCodeContainer(true);
                              setZipCodes([...zipCodes, values.zipCode]);
                              setFieldValue('zipCode', '');
                            }
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
                {zipCodes.length > 0 && isZipCodeContainer && (
                  <div className="zip-code-container">
                    {zipCodes.map((zipCode, index) => {
                      return (
                        <div className="zip-code-tab" key={index}>
                          <span>{zipCode}</span>
                          <IoIosClose
                            className="close-icon"
                            onClick={() => removeZipCode(zipCode)}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="risk-area-deductibles-container">
                  <div className="risk-area-dropdown">
                    <label htmlFor="/">{ALL_TEXT.DISASTER.AREA_RISK}</label>
                    <CriteriaDropdown
                      name={'areaRisk'}
                      id={'areaRisk'}
                      dropdowntitle={'Select Area Risk'}
                      options={risksList}
                      handleBlur={handleBlur}
                      onChangeOption={e => {
                        setRiskLevelID(e);
                        setFieldValue('deductibles', e.deductible);
                        setFieldValue('areaRisk', e);
                        handleChange('areaRisk');
                      }}
                      selectedOption={values.areaRisk}
                      error={touched.areaRisk && errors.areaRisk}
                    />
                  </div>
                  <div className="risk-area-deductibles">
                    <label htmlFor="/">{ALL_TEXT.DISASTER.DEDUCTIBLE}</label>
                    <InputField
                      name={'deductibles'}
                      id={'deductibles'}
                      disabled
                      placeholder={'0'}
                      value={values.deductibles}
                      handleChange={e => {
                        const inputVal = e.target.value;
                        if (
                          inputVal === '' ||
                          /^([1-9]|[1-9][0-9]|100)$/.test(inputVal)
                        ) {
                          setFieldValue('deductibles', inputVal);
                        }
                      }}
                      handleBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="disaster-area-footer-button">
                <GenericButton
                  customStyle={'cancel-button'}
                  buttonText={ALL_TEXT.CANCEL}
                  grayButton
                  onPress={handleClose}
                />
                <GenericButton
                  disable={selectedDisaster ? false : zipCodes.length === 0}
                  // grayButton={!isEditDisasterArea}
                  buttonText={
                    selectedDisaster
                      ? ALL_TEXT.BUTTON_TEXT.UPDATE
                      : ALL_TEXT.BUTTON_TEXT.ADD
                  }
                  loading={addUpdateResponse.isLoading}
                  onPress={() => {
                    handleSubmit();
                  }}
                />
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
}
