import {ENUMS} from '../../common';

const {
  STAFF: {
    ROLE: {
      ADMIN,
      AGENT,
      INDEPANDANT_AGENT,
      AGENCY,
      CUSTOMER_SUPPORT,
      SALES_REP,
      // DISTRIBUTION_CHANNEL,
      BUSINESS_AMB,
      AGENCY_MANAGER,
    },
  },
} = ENUMS;

export const administratorTabAccess = [
  ADMIN.VALUE,
  // DISTRIBUTION_CHANNEL.VALUE,
  AGENT.VALUE,
  INDEPANDANT_AGENT.VALUE,
  AGENCY.VALUE,
  SALES_REP.VALUE,
  // CUSTOMER_SUPPORT.VALUE,
  BUSINESS_AMB.VALUE,
  AGENCY_MANAGER.VALUE,
];

export const staffMaintainanceTabAccess = [
  ADMIN.VALUE,
  // DISTRIBUTION_CHANNEL.VALUE,
  AGENCY.VALUE,
  BUSINESS_AMB.VALUE,
  AGENCY_MANAGER.VALUE,
];

export const sendNotificationTabAccess = [
  ADMIN.VALUE,
  // DISTRIBUTION_CHANNEL.VALUE,
  AGENT.VALUE,
  INDEPANDANT_AGENT.VALUE,
  BUSINESS_AMB.VALUE,
  AGENCY.VALUE,
  CUSTOMER_SUPPORT.VALUE,
  AGENCY_MANAGER.VALUE,
  SALES_REP.VALUE,
];

export const couponCodeTabAccess = [
  ADMIN.VALUE,
  // DISTRIBUTION_CHANNEL.VALUE,
  AGENCY.VALUE,
];

export const disasterManagmentTabAccess = [
  ADMIN.VALUE,
  // DISTRIBUTION_CHANNEL.VALUE,
];
export const commissionTabAccess = [
  ADMIN.VALUE,
  // DISTRIBUTION_CHANNEL.VALUE,
  AGENT.VALUE,
  INDEPANDANT_AGENT.VALUE,

  AGENCY.VALUE,
  BUSINESS_AMB.VALUE,
  SALES_REP.VALUE,
];
export const auditLogsTabAccess = [
  ADMIN.VALUE,
  // DISTRIBUTION_CHANNEL.VALUE,
  // AGENT.VALUE,
  // INDEPANDANT_AGENT.VALUE,
  AGENCY.VALUE,
  CUSTOMER_SUPPORT.VALUE,
];

export const logsTabAccess = [
  ADMIN.VALUE,
  // DISTRIBUTION_CHANNEL.VALUE,
  CUSTOMER_SUPPORT.VALUE,
];

export const changePasswordTabAccess = [
  ADMIN.VALUE,
  AGENCY.VALUE,
  AGENT.VALUE,
  INDEPANDANT_AGENT.VALUE,
  SALES_REP.VALUE,
  BUSINESS_AMB.VALUE,
  AGENCY_MANAGER.VALUE,
  CUSTOMER_SUPPORT.VALUE,
];
