import React from 'react';
import {ALL_TEXT, images, QUERYPARAM} from '../../common';
import './styles.scss';
import {useEffect} from 'react';
import {useNavigate} from 'react-router';
import {path} from '../../common/routesNames';
import {NodukAdmnApi} from '../../redux/slice/nodukAdmin.ts';

export default function EmailVerification() {
  const {useLazyGetApiAdminVerifyEmployeeQuery} = NodukAdmnApi;
  const [verifyEmailApi] = useLazyGetApiAdminVerifyEmployeeQuery();
  const getParamFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  const VerifyEmailUpdate = async () => {
    let query = getParamFromUrl();
    let encyptedId = query.get(QUERYPARAM.ENC_ID);
    await verifyEmailApi({token: encyptedId});
  };
  useEffect(() => {
    VerifyEmailUpdate();
  }, []);
  let navigate = useNavigate();
  return (
    <div className="emailConfirmationContainer">
      <img src={images.thanks} />
      <span>
        {ALL_TEXT.EMAIL_VERIFICATION}
        <span
          onClick={() => {
            navigate({
              pathname: path.base,
            });
          }}
          className="noduk-email-confirmation">
          {ALL_TEXT.NODUK_PORTAL}
        </span>
      </span>
      <span>{ALL_TEXT.THANKS}</span>
    </div>
  );
}
