import React from 'react';
import {Dropdown} from 'react-bootstrap';
import {useNavigate} from 'react-router';
import './style.scss';

export function NotificationDropdown({icon, items}) {
  const navigate = useNavigate();
  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" id="notify-dropdown-basic">
        {icon}
      </Dropdown.Toggle>
      <Dropdown.Menu className="notify-action-popup">
        {items?.length > 0 ? (
          items?.map((i, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => navigate('/admin-notifications')}
              className="notify-item">
              <div className="notify-image">
                <span
                  className={
                    i?.notifyType == 'refund'
                      ? 'icon-refund iconFont'
                      : i.notifyType == 'invoice'
                      ? 'icon-invoices iconFont'
                      : i.notifyType == 'subscription'
                      ? 'icon-subscription iconFont'
                      : i.notifyType == 'claim'
                      ? 'icon-claims iconFont'
                      : 'icon-claims iconFont'
                  }></span>
              </div>
              <div className="notify-detail">
                <p className="notify-head">{i.title}</p>
                <p className="notify-text">{i.description}</p>
              </div>
            </Dropdown.Item>
          ))
        ) : (
          <p id="no-notify-text">No New Notifications</p>
        )}
        <Dropdown.Item
          onClick={() => navigate('/admin-notifications')}
          id="seeall-text">
          See All
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
