import React from 'react';
import './styles.scss';
import {ALL_TEXT} from '../../common';
import {useEffect, useState} from 'react';
import {getTutorialLinks} from '../../utils/rest-services';
// import {AiOutlineArrowDown} from 'react-icons/ai';
// import {TbListSearch} from 'react-icons/tb';
// import {Carousel} from '3d-react-carousal';
// import {Grid} from '@mui/material';
import YoutubeCarousel3D from './VideoCarousel';
import {Loader} from '../../components/loader';
function TutorialGuide() {
  const [tutorial, setTutorial] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [currentModuleName, setCurrentModuleName] = useState('');
  const [currentDiscrption, setCurrentDiscrption] = useState('');
  const [loading, setLoading] = useState(true);

  const extractVideoId = url => {
    const match = url.match(
      /(?:youtube\.com\/.*(?:\/|v=)|youtu\.be\/)([^"&?\s]{11})/,
    );
    return match && match[1];
  };

  const handleVideoChange = index => {
    setCurrentVideoIndex(() => {
      const moduleName = tutorial[index]?.moduleName || '';
      const description = tutorial[index]?.description || '';

      setCurrentModuleName(moduleName);
      setCurrentDiscrption(description);
      return index;
    });
  };
  useEffect(() => {
    const moduleName = tutorial[currentVideoIndex]?.moduleName || '';
    const description = tutorial[currentVideoIndex]?.description || '';

    setCurrentModuleName(moduleName);
    setCurrentDiscrption(description);
  }, [currentVideoIndex, tutorial]);
  useEffect(() => {
    getTutorial();
  }, []);
  const getTutorial = async () => {
    try {
      let response = await getTutorialLinks();
      if (response && response.success) {
        setTutorial(response.content || []);
        setLoading(false);
      } else {
        console.error('Failed to fetch tutorial links:', response);
      }
    } catch (error) {
      console.error('Error fetching tutorial links:', error);
    }
  };
  const videos = tutorial.map(i => {
    // Extract the video ID from the "/watch?v=" format
    const videoId = extractVideoId(i.videoURL);

    // Construct the URL in the "/embed/" format
    return {id: `https://www.youtube.com/embed/${videoId}`};
  });

  // const tutorialVideos = [
  //   'https://www.youtube.com/embed/OwsSuvguZpo',
  //   'https://www.youtube.com/embed/OwsSuvguZpo',
  //   'https://www.youtube.com/embed/OwsSuvguZpo',
  //   'https://www.youtube.com/embed/OwsSuvguZpo',
  //   'https://www.youtube.com/embed/OwsSuvguZpo',
  // ];

  // const guideData = [
  //   {
  //     title: 'How to add new customer?',
  //     fileDoc:
  //       'https://devapiv2.noduk.com/ClaimAttachments/Auto_525/1801_pdf-test.pdf',
  //   },
  //   {
  //     title: 'How to purchase plans?',
  //     fileDoc:
  //       'https://devapiv2.noduk.com/ClaimAttachments//Mobile_701//1886_3A1396.pdf',
  //   },
  //   {
  //     title: 'How to File a claim? ',
  //     fileDoc:
  //       'https://devapiv2.noduk.com/ClaimAttachments/Auto_525/1801_pdf-test.pdf',
  //   },
  //   {
  //     title: 'How to check your claim status?',
  //     fileDoc:
  //       'https://devapiv2.noduk.com/ClaimAttachments//Mobile_701//1886_3A1396.pdf',
  //   },
  // ];
  // const videoSlides = tutorialVideos.map((videoUrl, index) => (
  //   <iframe
  //     key={index}
  //     src={videoUrl}
  //     frameBorder="0"
  //     allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
  //     allowFullScreen
  //     style={{width: '100%', height: '100%'}}></iframe>
  // ));

  // const handleFileDownload = fileDocUrl => {
  //   fetch(fileDocUrl)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       const blobUrl = URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = blobUrl;
  //       link.target = '_blank';
  //       link.download = 'file.pdf';
  //       link.click();
  //       URL.revokeObjectURL(blobUrl);
  //     })
  //     .catch(error => {
  //       console.error('Error downloading the file:', error);
  //     });
  // };

  // const handleFileView = fileDoc => {
  //   const link = document.createElement('a');
  //   link.href = fileDoc;
  //   link.target = '_blank';
  //   link.rel = 'noopener noreferrer';
  //   link.click();
  // };
  return (
    <div className="tutorial-main-container">
      <div className="title-container-tutorial">
        <span>{ALL_TEXT.TUTORIAL_GUIDE.TUTORIAL_AND_GUIDE}</span>
      </div>
      {loading ? (
        <Loader size={40} />
      ) : (
        <div className="tutorial-box">
          {/* <p>{ALL_TEXT.TUTORIAL_GUIDE.TUTORIALS}</p> */}
          {videos.length >= 1 ? (
            <div className="tutorial-box-carousel">
              {/* <iframe
            // key={index}
            src={'https://www.youtube.com/embed/OwsSuvguZpo'}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{width: '100%', height: '100%'}}></iframe> */}

              <YoutubeCarousel3D
                videos={videos}
                onVideoChange={handleVideoChange}
              />
            </div>
          ) : (
            <div className="videos-no-data">
              <span>{ALL_TEXT.TUTORIAL_GUIDE.NO_TUTORIAL}</span>
            </div>
          )}
          {videos.length >= 1 && (
            <div className="Subscription-box">
              <span>{currentModuleName}</span>
              <div className="Subscription-inner-box">
                <span>{currentDiscrption}</span>
              </div>
            </div>
          )}
          {/* <div className="guide-box">
          <p>{ALL_TEXT.TUTORIAL_GUIDE.GUIDE}</p>
          <div className="guide-content">
            <Grid container spacing={2}>
              {guideData.map((item, index) => {
                const {title, fileDoc} = item;
                return (
                  <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                    <div className="guide-content-download">
                      <div className="guide-doc-detail">
                        <span class="icon-pdfIcon"></span>
                        <span>{title}</span>
                      </div>
                      <div className="guide-doc-action">
                        <span onClick={() => handleFileView(fileDoc)}>
                          <TbListSearch />
                        </span>
                        <span onClick={() => handleFileDownload(fileDoc)}>
                          <AiOutlineArrowDown />
                        </span>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div> */}
        </div>
      )}
    </div>
  );
}

export default TutorialGuide;
