import React, {useEffect} from 'react';
import {ALL_TEXT, ENUMS, QUERYPARAM, images} from '../../common';
import {ActionDropDownPopUp, CellValue} from '../../components';
import {GetBadge} from '../../utils/helpers/users';
import {BsThreeDotsVertical} from 'react-icons/bs';
import './style.scss';
import {setSelectedCard, setSelectedUser} from '../../redux/slice/checkout';
import {useNavigate} from 'react-router';
import {path} from '../../common/routesNames';
import {useDispatch} from 'react-redux';
import {
  setActivationLinkPopup,
  setActiveUser,
  setChangePasswordModal,
  setSelectedUserData,
} from '../../redux/slice/user';
import {getProfile} from '../../utils/localstorage';
import {useState} from 'react';

const {
  USERS: {ROLE, STATUS},
} = ENUMS;

export function tableHeading() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginUSer, setLoginUSer] = useState(null);

  useEffect(() => {
    let role = getProfile().roleId;
    setLoginUSer(role);
  });

  const getMenuItems = row => {
    const setUserObject = userObj => {
      const selectedUserObj = {
        signupId: userObj.signupId,
        email: userObj.email,
        phone: userObj.phone,
        name: `${userObj.firstName} ${userObj.lastName}`,
        value: `${userObj.firstName} ${userObj.lastName}`,
        label: `${userObj.firstName} ${userObj.lastName}`,
        id: userObj.signupId,
        isActive: userObj.isActive,
        status: userObj.status,
      };
      dispatch(setSelectedUser(selectedUserObj));
      dispatch(setSelectedCard(null));
    };
    const menuItemsSimple = [
      {
        title: ALL_TEXT.VIEW_PROFILE,
        icon: <span class="icon-search popup-menu-icon gray-popup-icon"></span>,
        onItemPress: () => {
          setUserObject(row);
          navigate({
            pathname: path.customerProfile,
            search: `?${QUERYPARAM.SIGNUPID}=${row.signupId}&isActive=${row.isActive}`,
          });
        },
      },
      {
        title: ALL_TEXT.CHANGE_PASSWORD_LINK,
        icon: (
          <span class="icon-resetpassword popup-menu-icon red-popup-icon"></span>
        ),
        onItemPress: () => {
          dispatch(setSelectedUserData(row));
          dispatch(setChangePasswordModal(true));
        },
      },
    ];
    const manuItemForCustomerSupport = [
      {
        title: ALL_TEXT.VIEW_PROFILE,
        icon: <span class="icon-search popup-menu-icon gray-popup-icon"></span>,
        onItemPress: () => {
          setUserObject(row);
          navigate({
            pathname: path.customerProfile,
            search: `?${QUERYPARAM.SIGNUPID}=${row.signupId}&isActive=${row.isActive}`,
          });
        },
      },
    ];
    const menuItemsWithActiveAccount = [
      ...menuItemsSimple,
      {
        title: ALL_TEXT.ADD_POLICY,
        icon: <span class="icon-plus popup-menu-icon green-popup-icon"></span>,
        onItemPress: () => {
          setUserObject(row);
          navigate({
            pathname: path.addPolicy,
            search: `?${QUERYPARAM.SIGNUPID}=${row.signupId}`,
          });
        },
      },
      {
        title: ALL_TEXT.DEACTIVATE,
        icon: <span class="icon-close popup-menu-icon red-popup-icon"></span>,
        onItemPress: () => {
          dispatch(setActiveUser(true));
          dispatch(setSelectedUserData(row));
        },
      },
    ];
    const menuItemsWithInActiveAccount = [
      ...menuItemsSimple,

      {
        title: ALL_TEXT.ACTIVE_ACCONT,
        icon: (
          <span class="icon-Vector-27-Stroke popup-menu-icon green-popup-icon"></span>
        ),
        onItemPress: () => {
          dispatch(setActiveUser(true));
          dispatch(setSelectedUserData(row));
        },
      },
    ];

    if (row?.isActive === true) {
      if (loginUSer === 6) {
        return manuItemForCustomerSupport;
      }
      // to check if it is active
      else if (row?.isEmailConfirmed === false) {
        return [
          ...menuItemsWithActiveAccount,
          {
            title: ALL_TEXT.CREATE_ACTIVATION_LINK,
            icon: (
              <span className="account-setup-link">
                <img src={images.accountSetupLink} alt="" />
              </span>
            ),
            onItemPress: () => {
              dispatch(setSelectedUserData(row));
              dispatch(setActivationLinkPopup(true));
            },
          },
        ];
      } else return menuItemsWithActiveAccount;
    } else if (row?.isActive === false) {
      // to check if it is Inactive
      if (row?.isEmailConfirmed === false) {
        return [
          ...menuItemsWithInActiveAccount,
          {
            title: ALL_TEXT.CREATE_ACTIVATION_LINK,
            icon: (
              <span className="account-setup-link">
                <img src={images.accountSetupLink} alt="" />
              </span>
            ),
            onItemPress: () => {
              dispatch(setSelectedUserData(row));
              dispatch(setActivationLinkPopup(true));
            },
          },
        ];
      } else return menuItemsWithInActiveAccount;
    }
  };

  return [
    {
      title: 'First Name',
      fieldName: 'firstName',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Last Name',
      fieldName: 'lastName',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Address',
      fieldName: 'address',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Phone',
      fieldName: 'phone',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Email',
      fieldName: 'email',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Role',
      fieldName: 'role',
      dataformat: (cell, row) =>
        GetBadge(row.isConverted ? ROLE.CUSTOMER.LABEL : ROLE.REGISTERED.LABEL),
    },
    {
      title: 'Status',
      fieldName: 'status',
      dataformat: (cell, row) =>
        GetBadge(row.isActive ? STATUS.ACTIVE.LABEL : STATUS.INACTIVE.LABEL),
    },
    {
      title: 'Date',
      fieldName: 'dateSignup',
      dataformat: (cell, row) => {
        const [datePart] = cell.split('T');
        const [year, month, day] = datePart.split('-');
        const formattedDate = `${day}-${month}-${year}`;
        return <CellValue cell={formattedDate} row={row} />;
      },
    },
    {
      title: 'Actions',
      fieldName: 'action',
      dataformat: (cell, row) => {
        return (
          <ActionDropDownPopUp
            visibleIcon={<BsThreeDotsVertical />}
            // items={row.isActive ? menuItems : menuItems2}
            items={getMenuItems(row)}
            userObj={row}
          />
        );
      },
    },
  ];
}
