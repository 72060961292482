import {Grid} from '@mui/material';
import React from 'react';
import {ALL_TEXT, images} from '../../../common';
import Subscriptions from '../../../components/subscriptions';
import ProgressBar from '@ramonak/react-progress-bar';

export function DashboardSubscription({data}) {
  let Plans = [
    {
      title: 'Auto',
      value: data?.autoSubscriptions,
    },
    {
      title: 'Device',
      value: data?.deviceSubscriptions,
    },
    {
      title: 'Home',
      value: data?.homeSubscriptions,
    },
    {
      title: 'Commericals',
      value: data?.commercialSubscriptions,
    },
    {
      title: 'Disaster',
      value: data?.disasterSubscriptions,
    },
  ];
  let Tenures = [
    {
      tenure: 'Monthly',
      value: data?.monthlySubscriptions,
      color: 'black',
      tenureValue: data?.monthlySubscriptions,
      total: data?.totalSubscriptions,
    },
    {
      tenure: 'BiAnnual',
      value: data?.biannualSubscriptions,
      color: 'Grey',
      tenureValue: data?.biannualSubscriptions,
      total: data?.totalSubscriptions,
    },
    {
      tenure: 'Annual',
      value: data?.annualSubscriptions,
      color: '#dd3333',
      tenureValue: data?.annualSubscriptions,
      total: data?.totalSubscriptions,
    },
  ];

  const subscriptionDetailData = [
    {
      title: ALL_TEXT.TOTAL,
      count: data?.totalSubscriptions,
    },
    {
      title: ALL_TEXT.ACTIVE,
      count: data?.activeSubscriptions,
    },
    {
      title: ALL_TEXT.CANCELLED,
      count: data?.cancelledSubscriptions,
    },
  ];

  return (
    <div className="subscription-section">
      <h5>Subscriptions </h5>
      <div className="subscription-contain">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <div className="sub-prog-container">
              {Plans.map(i => {
                return (
                  <Subscriptions
                    key={i.value}
                    data={data}
                    title={i.title}
                    value={i.value
                      ?.toString()
                      .replace(/(\.0*|(?<=(\..*?))0*)$/, '')}
                  />
                );
              })}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <div className="subscription-progressBar">
              {Tenures.map(i => (
                <ProgressSlider
                  key={i.value}
                  color={i.color}
                  value={i.value}
                  tenure={i.tenure}
                  tenureValue={i.tenureValue}
                  total={i.total}
                />
              ))}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="subscription-count">
              {subscriptionDetailData.map((item, index) => {
                return (
                  <SubscriptionDetail
                    key={index}
                    title={item.title}
                    count={item.count}
                  />
                );
              })}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
const SubscriptionDetail = ({title, count}) => {
  return (
    <div className="subscription-detail">
      <div className="subscription-detail-tile">
        <div className="subscription-detail-title">
          <img src={images.roundSubscriptionIcon} alt="icon" />
          <span>{title}</span>
        </div>
        <div className="subscription-detail-count">
          <span>{count}</span>
        </div>
      </div>
    </div>
  );
};

const ProgressSlider = ({value, color, tenure, tenureValue, total}) => {
  const totalvalue = total ? total : 100;
  return (
    <>
      <div className="tenure-bar">
        <h6>{tenure}</h6>
        <span>{`${tenureValue}/${total}`}</span>
      </div>
      <div className="progressbar">
        <ProgressBar
          completed={(value / totalvalue) * 100}
          isLabelVisible={false}
          bgColor={color}
        />
      </div>
    </>
  );
};
