import React, {useState, useRef} from 'react';
import '../../pages/add-policy/styles.scss';
import {ALL_TEXT, ENUMS, images} from '../../common';
import {
  CriteriaDropdown,
  GenericButton,
  HomeTermsAndConditions,
  InputField,
  Loader,
} from '../../components';
import {Grid} from '@mui/material';
import {Formik} from 'formik';
import {addHomeSchema, addHomeSchemaWithDisaster} from '../../common/schemas';
// import {path} from '../../common/routesNames';
// import {useNavigate} from 'react-router';
import {addItemToCart} from '../../utils/rest-services';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {openModal, setModalDetails} from '../../utils/store/slices/popup-modal';
import {useDispatch, useSelector} from 'react-redux';
import Autocomplete from 'react-google-autocomplete';
import {NodukAdmnApi} from '../../redux/slice/nodukAdmin.ts';
import {TbInfoCircle} from 'react-icons/tb';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export const AddHomeInput = ({
  carrierItem,
  deductibleItem,
  onItemAddedToCart,
  customerProfile,
}) => {
  const {
    ADD_PLAN_LABELS: {CARRIER, DEDUCTIBLES, OTHER_CARRIER, OTHER_DEDUCTIBLES},
  } = ALL_TEXT;
  const signupId = useSelector(
    state => state.checkout.selectedUser?.signupId ?? null,
  );

  const {useLazyGetApiCustomerVerifyZipCodeQuery} = NodukAdmnApi;

  const [verifyZipCodeAPI] = useLazyGetApiCustomerVerifyZipCodeQuery();

  const [isOtherDeductibles, setIsOtherDeductibles] = useState(false);
  const [isOtherCarrier, setIsOtherCarrier] = useState(false);
  const [isDisasterCheckMatch, setIsDisasterCheckMatch] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [termsandConditionPopup, setTermsandConditionPopup] = useState(false);
  const [zipLoader, setZipLoader] = useState(false);
  const [disasterData, setDisasterData] = useState(false);
  const [zipErrorMsg, setZipErrorMsg] = useState('');
  // let navigate = useNavigate();
  const carrierRef = useRef();
  const deductiblesRef = useRef();
  const dispatch = useDispatch();
  const addHomeToCart = async (values, actions) => {
    let addToCartPayload = {
      serviceTypeId: ENUMS.COVERAGE_TYPES.HOME.toString(),
      serviceProviderId: values.carrier.id,
      otherServiceProvider: values.otherCarrier,
      deductibleAmount:
        values.deductibles.id === ALL_TEXT.NOT_AVAILABLE_ID
          ? values.otherDeductibles
          : values.deductibles.id,
      address: `${values.address},${values.state},${values.city},${values.zip}`,
      signupId: signupId, // for the time being its temporary
      cartItemId: 0,
      isDisaster: isDisasterCheckMatch && disasterData != null,
      disasterDeductible: values?.disasterDeductibles?.id || 0,
      zipCode: values.zip,
    };
    let result = await addItemToCart(addToCartPayload);
    if (result && result.success) {
      setIsDisasterCheckMatch(false);
      setIsOtherDeductibles(false);

      toast(
        <div className="cart-icon-toaster">
          <img src={images.cartSmall} />
          <span className="toast-text">{result.description}</span>
        </div>,
      );

      actions.resetForm();
      onItemAddedToCart();
    } else {
      dispatch(
        setModalDetails({
          title: ALL_TEXT.GENERIC_MODAL.ERROR,
          details: result.description,
          type: ENUMS.POPUP_ENUMS.ERROR,
        }),
      );
      dispatch(openModal());
    }
    actions.setSubmitting(false);
  };
  const AddressSeprateHandler = (data, setFieldValue, values) => {
    let address = [];
    let city = [];
    let state = [];
    let postal = [];
    data.map(dataItem => {
      dataItem.types.map(type => {
        if (type === ALL_TEXT.FIELD_TYPE_ENUMS.STREET_NUMBER) {
          address.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.ROUTE) {
          address.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.NAIBHOUR) {
          address.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.LOCALITY) {
          city.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.ADMIN) {
          state.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.CODE) {
          postal.push(dataItem.long_name);
        }
      });
    });
    // === convert Address array to string === //
    setFieldValue(ALL_TEXT.FIELD_VALUE.ADDRESS, values);
    setFieldValue(ALL_TEXT.FIELD_VALUE.CITY, convertArrayToString(city));
    setFieldValue(ALL_TEXT.FIELD_VALUE.STATE, convertArrayToString(state));
    setFieldValue(ALL_TEXT.FIELD_VALUE.ZIP, convertArrayToString(postal));
  };
  const convertArrayToString = data => {
    const joined = data.join();
    let newAddress = joined.replace(/,/g, ' ');
    return newAddress;
  };

  const handleChangeZip = (event, setFieldValue) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9+]/g, ''); // remove non-numeric characters
    const restrictedValue = numericValue.substring(0, 9); // restrict to 10 digits
    setFieldValue('zip', restrictedValue);
  };

  const handleCancel = resetForm => {
    resetForm();
  };
  const getRiskClassName = riskLevel => {
    if (riskLevel === 'high') {
      return 'high-risk-class';
    } else if (riskLevel === 'low') {
      return 'low-risk-class';
    } else if (riskLevel === 'medium') {
      return 'medium-risk-class';
    } else {
      return 'non-risk-class';
    }
  };
  const getRiskBadgs = riskLevel => {
    if (riskLevel === 'high') {
      return images.highRiskBadge;
    } else if (riskLevel === 'low') {
      return images.lowRiskBadge;
    } else {
      return images.mediumRiskBadge;
    }
  };

  const getZipCodeInformation = async (zipCode, emptydisasterDeductibles) => {
    setZipLoader(true);
    let response = await verifyZipCodeAPI({zipCode: zipCode});
    setZipLoader(false);
    if (response && response.data.success) {
      emptydisasterDeductibles();
      setDisasterData(response.data.content);
      setZipErrorMsg('');
    } else {
      setDisasterData(null);
      setZipErrorMsg(response.data.description);
      setTermsAndConditions(true);
    }
  };

  return (
    <Formik
      initialValues={{
        carrier: null,
        otherCarrier: '',
        deductibles: null,
        otherDeductibles: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        disasterDeductibles: null,
      }}
      onSubmit={(values, formikActions) => {
        addHomeToCart(values, formikActions);
      }}
      validationSchema={
        isDisasterCheckMatch && disasterData != null
          ? addHomeSchemaWithDisaster(isOtherDeductibles, isOtherCarrier)
          : addHomeSchema(isOtherDeductibles, isOtherCarrier)
      }
      enableReinitialize={true}>
      {({
        handleChange,
        handleSubmit,
        touched,
        errors,
        values,
        setFieldValue,
        isSubmitting,
        resetForm,
      }) => (
        <>
          <div className="vehicle-input-container">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="selectOptionCOntainer">
                  <CriteriaDropdown
                    label={CARRIER}
                    options={carrierItem}
                    name={'carrier'}
                    selectedOption={values.carrier}
                    error={touched.carrier && errors.carrier}
                    dropdowntitle={ALL_TEXT.SELECT_CARRIER}
                    onChangeOption={e => {
                      setFieldValue('carrier', e);
                      handleChange('carrier');
                      if (e.id === ALL_TEXT.NOT_AVAILABLE_ID) {
                        setIsOtherCarrier(true);
                        setTimeout(() => {
                          carrierRef?.current?.focus();
                        }, 500);
                      } else {
                        setIsOtherCarrier(false);
                      }
                    }}
                  />
                </div>
              </Grid>
              {values?.carrier?.id === ALL_TEXT.NOT_AVAILABLE_ID && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <InputField
                    label={OTHER_CARRIER}
                    value={values.otherCarrier}
                    handleChange={({target: {value}}) => {
                      setFieldValue('otherCarrier', value);
                      handleChange('otherCarrier');
                    }}
                    placeHolder={ALL_TEXT.OTHER_CARRIER}
                    name={ALL_TEXT.OTHER_CARRIER}
                    error={touched.otherCarrier && errors.otherCarrier}
                    reff={carrierRef}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <CriteriaDropdown
                  label={DEDUCTIBLES}
                  options={deductibleItem}
                  error={touched.deductibles && errors.deductibles}
                  dropdowntitle={ALL_TEXT.SELECT_DEDUCTIBLES}
                  name={ALL_TEXT.SELECT_DEDUCTIBLES}
                  selectedOption={values.deductibles}
                  onChangeOption={e => {
                    handleChange('deductibles');
                    setFieldValue('deductibles', e);
                    if (e.id === ALL_TEXT.NOT_AVAILABLE_ID) {
                      setIsOtherDeductibles(true);
                      setTimeout(() => {
                        deductiblesRef?.current?.focus();
                      }, 500);
                    } else {
                      setIsOtherDeductibles(false);
                    }
                  }}
                />
              </Grid>
              {(values?.deductibles?.id === ALL_TEXT.NOT_AVAILABLE_ID ||
                isOtherDeductibles) && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <InputField
                    label={OTHER_DEDUCTIBLES}
                    value={values.otherDeductibles}
                    handleChange={e => {
                      const inputValue = e.target.value;
                      const numericValue = inputValue.replace(/[^0-9.]/g, ''); // remove non-numeric characters
                      const restrictedValue = numericValue.substring(0, 7); // restrict to 10 digits
                      setFieldValue('otherDeductibles', restrictedValue);
                      handleChange('otherDeductibles');
                    }}
                    placeHolder={ALL_TEXT.OTHER_DEDUCTIBLES + ' Amount'}
                    name={ALL_TEXT.OTHER_DEDUCTIBLES}
                    error={touched.otherDeductibles && errors.otherDeductibles}
                    maxLength={7}
                    reff={deductiblesRef}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="plan-labels">
                  {ALL_TEXT.USER_DETAIL.ADDRESS}
                </div>
                <Autocomplete
                  id={'address'}
                  name={'address'}
                  className="input-field-container p-2 drop-down-height"
                  apiKey={ALL_TEXT.ADDRESSKEY}
                  value={values.address}
                  placeholder={ALL_TEXT.ENTER_ADDRESS}
                  onChange={handleChange('address')}
                  onPlaceSelected={data => {
                    AddressSeprateHandler(
                      data.address_components,
                      setFieldValue,
                      data.formatted_address,
                    );
                  }}
                  options={{
                    componentRestrictions: {country: 'us'},
                  }}
                />
                <div className="error-input-container">
                  {errors.address && touched.address ? (
                    <p className="form-error">{errors.address}</p>
                  ) : null}
                </div>
                <div class="address-check-box home-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="sameBillingAddressCheckbox"
                    checked={values.sameBillingAddress}
                    onChange={e => {
                      setIsDisasterCheckMatch(false);
                      const isChecked = e.target.checked;
                      if (isChecked) {
                        setFieldValue(
                          'address',
                          customerProfile?.profile?.address || '',
                        );
                        setFieldValue(
                          'city',
                          customerProfile?.profile?.city || '',
                        );
                        setFieldValue(
                          'state',
                          customerProfile?.profile?.state || '',
                        );
                        setFieldValue(
                          'zip',
                          customerProfile?.profile?.zip || '',
                        );
                      } else {
                        setFieldValue('address', '');
                        setFieldValue('city', '');
                        setFieldValue('state', '');
                        setFieldValue('zip', '');
                      }
                      setFieldValue('sameBillingAddress', isChecked);
                    }}
                  />
                  <span class="add-coverage-box-label">
                    <Tooltip id={`billingddress`} />
                    {ALL_TEXT.SAME_BILING_ADDRESS}
                    <TbInfoCircle
                      data-tooltip-id={`billingddress`}
                      data-tooltip-content={`This biling address is coming from your profile.`}
                      //Icon allow only inline styling, due to this reason we use inline style here.
                      style={{marginLeft: '5px', height: '17px', width: '17px'}}
                    />
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputField
                  label={ALL_TEXT.USER_DETAIL.CITY}
                  placeholder={ALL_TEXT.ENTER_CITY}
                  id={'city'}
                  name={'city'}
                  handleChange={handleChange}
                  error={touched.city && errors.city}
                  value={values.city}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputField
                  label={ALL_TEXT.USER_DETAIL.STATE}
                  id={'state'}
                  name={'state'}
                  placeholder={ALL_TEXT.ENTER_STATE}
                  handleChange={handleChange}
                  error={touched.state && errors.state}
                  value={values.state}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputField
                  label={ALL_TEXT.USER_DETAIL.ZIP}
                  placeholder={ALL_TEXT.ENTER_ZIPCODE}
                  id={'zip'}
                  name={'zip'}
                  setValue={event => {
                    const inputValue = event.target.value;
                    if (/^\d{0,6}$/.test(inputValue)) {
                      setFieldValue('zip', inputValue);
                      handleChange('zip');
                      setIsDisasterCheckMatch(false);
                    }
                  }}
                  handleChange={e => {
                    handleChangeZip(e, setFieldValue),
                      setIsDisasterCheckMatch(false);
                  }}
                  error={touched.zip && errors.zip}
                  value={values.zip}
                />
              </Grid>
            </Grid>
          </div>
          <div class="add-coverage-box home-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              disabled={values.zip === ''}
              id="flexCheckChecked"
              checked={isDisasterCheckMatch}
              onChange={d => {
                if (isDisasterCheckMatch) {
                  setTermsAndConditions(false);
                } else {
                  getZipCodeInformation(
                    values.zip,
                    () => setFieldValue('disasterDeductibles', null),
                    setTermsAndConditions(false),
                  );
                }
                setIsDisasterCheckMatch(d.target.checked);
              }}
            />
            <span class="add-coverage-box-label" for="flexCheckChecked">
              {ALL_TEXT.ADD_DISASTER_COVERAGE}
            </span>
          </div>
          {isDisasterCheckMatch &&
            (zipLoader ? (
              <div>
                <Loader />
              </div>
            ) : (
              <div>
                <div className="disaster-deduction-module border-deduction">
                  <div
                    className={`high-risk-container ${getRiskClassName(
                      disasterData?.zipDetails?.riskLevel,
                    )}`}>
                    <span>Note:</span>
                    {!disasterData ? (
                      <span>
                        {zipErrorMsg ||
                          `Your Zip code Doesnot exist in disaster Area.`}
                      </span>
                    ) : (
                      <>
                        <span>
                          {` You're in a ${disasterData?.zipDetails?.riskLevel}-Risk Area. So, your disaster
                          deductible amount will be ${disasterData?.zipDetails?.percentage}% for this plan.`}
                        </span>
                        <span className="risk-badge">
                          <img
                            src={getRiskBadgs(
                              disasterData?.zipDetails?.riskLevel,
                            )}
                          />
                        </span>
                      </>
                    )}
                  </div>
                  {disasterData && (
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <CriteriaDropdown
                          options={disasterData?.disasterDeductibles}
                          error={
                            touched.disasterDeductibles &&
                            errors.disasterDeductibles
                          }
                          dropdowntitle={ALL_TEXT.selectDisasterDeductible}
                          name={ALL_TEXT.selectDisasterDeductible}
                          selectedOption={values.disasterDeductibles}
                          onChangeOption={e => {
                            handleChange('disasterDeductibles');
                            setFieldValue('disasterDeductibles', e);
                          }}
                        />
                      </Grid>
                      {values?.disasterDeductibles?.id && (
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <div className="disaster-dudctible-cost">
                            <span>{'Cost:'}</span>
                            <span>
                              {` $${(
                                values?.disasterDeductibles?.id *
                                (disasterData?.zipDetails?.percentage / 100)
                              ).toFixed(2)}`}
                            </span>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  )}

                  {disasterData && (
                    <div class="terms-and-condition home-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        checked={termsAndConditions}
                        onChange={d => {
                          if (termsAndConditions) setTermsAndConditions(false);
                          else setTermsandConditionPopup(d.target.checked);
                        }}
                      />
                      <span
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          if (termsAndConditions) setTermsAndConditions(false);
                          else
                            setTermsandConditionPopup(!termsandConditionPopup);
                        }}
                        className="reading-confirm-text">
                        <span class="read-terms-text" for="flexCheckChecked">
                          {ALL_TEXT.homeTermsAndConditions1}
                        </span>
                        <span class="terms-and-condition">
                          {ALL_TEXT.TAndC}
                        </span>
                        <span>{ALL_TEXT.homeTermsAndConditions2}</span>
                      </span>
                    </div>
                  )}
                </div>

                {termsandConditionPopup && (
                  <HomeTermsAndConditions
                    show={termsandConditionPopup}
                    onSelectTerms={() => {
                      setTermsandConditionPopup(false);
                      setTermsAndConditions(true);
                    }}
                    handleClose={() => {
                      setTermsandConditionPopup(false);
                      setTermsAndConditions(false);
                    }}
                  />
                )}
              </div>
            ))}
          <div className="select-user">
            {signupId == null && (
              <>
                <span>{'Note:'}</span>
                <span>{'Please select Customer first to add policy'}</span>
              </>
            )}
          </div>
          <div className="add-policy-btn-container">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <GenericButton
                  disable={
                    signupId == null || isDisasterCheckMatch
                      ? !termsAndConditions
                      : false
                  }
                  onPress={handleSubmit}
                  buttonText={'Add Plan'}
                  loading={isSubmitting}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <GenericButton
                  buttonText={'Cancel'}
                  customStyle={'policy-cancel-button'}
                  grayButton
                  onPress={() => {
                    handleCancel(resetForm);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </Formik>
  );
};
