import React, {useState, useEffect} from 'react';
import './style.scss';
import CustomerProfileLayout from '../main-layout';
import {ALL_TEXT, ENUMS, QUERYPARAM} from '../../../common';
import {
  CustomizedSearch,
  InvoiceAndSubscriptionDetails,
  Loader,
  PolicyCard,
} from '../../../components';
import {invoicesStatus, getValuesFromEnumsObj} from '../../../utils/helpers';
import {getCustommerInvoices} from '../../../utils/rest-services';
import {useLocation} from 'react-router';
import Pagination from '@mui/material/Pagination';
import {useSelector} from 'react-redux';

const Invoices = () => {
  const {
    INVOICES: {PAID, UPCOMING, ALL},
  } = ENUMS;
  const [btnloader, setBtnloader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceStatus, setInvoiceStatus] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(0);

  const location = useLocation();

  const getParamFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamFromUrl();
  const signupId = query.get(QUERYPARAM.SIGNUPID);
  const isSelectedUser = useSelector(
    state => state.checkout.isUserChange ?? null,
  );
  useEffect(() => {
    const payload = {
      signupId: signupId,
      pageNo: currentPage,
      rowsPerPage: ENUMS.PAGE_SIZE,
      status: invoiceStatus,
      id: 0,
      subscriptionId: 0,
    };
    getCustomerInvoicesHandler(payload);
  }, [currentPage, isSelectedUser]);

  const getCustomerInvoicesHandler = async payload => {
    setLoader(true);
    let response = await getCustommerInvoices(payload);
    if (response && response.success) {
      const {
        content: {list, totalPages},
      } = response;
      setInvoiceList(list || []);
      setTotalPage(totalPages);
    }
    setLoader(false);
  };
  const invoiceSearchHandler = async (
    invoiceId,
    subscriptionId,
    status,
    clear,
  ) => {
    let payload;
    if (clear) {
      payload = {
        signupId: signupId,
        pageNo: 1,
        rowsPerPage: ENUMS.PAGE_SIZE,
        status: 0,
        id: 0,
        subscriptionId: 0,
      };

      setInvoiceStatus(0);
    } else {
      const invoiceStatus = getValuesFromEnumsObj(ENUMS.INVOICES, status);
      setInvoiceStatus(invoiceStatus);
      payload = {
        signupId: signupId,
        pageNo: 1,
        rowsPerPage: ENUMS.PAGE_SIZE,
        status: invoiceStatus,
        id: invoiceId ? invoiceId?.split('_')[1] || invoiceId : 0,
        subscriptionId: subscriptionId
          ? subscriptionId?.split('_')[1] || subscriptionId
          : 0,
      };
    }

    setBtnloader(true);
    await getCustomerInvoicesHandler(payload);
    setBtnloader(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <CustomerProfileLayout selectedId={ENUMS.CUSTOMER_TAB_INDEX.INVOICES}>
      <div className="invoices-main-container overflow-auto">
        <CustomizedSearch
          title={'Search Invoice By'}
          firstLabel="Invoice ID"
          isFirstInputNumeric
          firstPrefixerValue={'inv_'}
          firstPlaceholder={'Enter Invoice ID'}
          secondInput
          secondLabel={'Subscription ID'}
          isSecondInputNumeric
          secondPrefixerValue={'sub_'}
          secondPlaceholder={'Enter Subscription Id'}
          firstDropdown
          firstDropdownLabel={'Status'}
          firstDropdownList={[PAID.LABEL, UPCOMING.LABEL, ALL.LABEL]}
          onClearFilterHandler={() => {
            invoiceSearchHandler('', '', '', true);
          }}
          onSearchBtnPress={(invoiceId, subsId, _, status) => {
            invoiceSearchHandler(invoiceId, subsId, status);
          }}
          btnLoader={btnloader}
        />
        {!loader && invoiceList.length === 0 && (
          <div className="loader-container-dasboard flex-center">
            <span> {ALL_TEXT.NO_INVOICES}</span>
          </div>
        )}
        {loader ? (
          <div className="loader-container-dasboard">
            <Loader />
          </div>
        ) : (
          invoiceList.length > 0 &&
          invoiceList?.map((item, index) => {
            const {viewInvoiceId, cost, dueOn, status, isPaid, datePaid} = item;
            return (
              <PolicyCard
                key={index}
                cardType={ENUMS.VIEW_CARD_TYPE.INVOICE}
                onDetailBtnPress={() => {
                  setDetailsModal(true);
                  setSelectedInvoiceId(viewInvoiceId?.split('_')[1]);
                }}
                planIconTitle={
                  <div className="plan-name">
                    <span>{viewInvoiceId}</span>
                  </div>
                }
                badge={invoicesStatus(status)}
                cost={<span className="bold-payment"> {`Cost: ${cost}`} </span>}
                LeftTopDetails={
                  <div className="details-item">
                    <span>{ALL_TEXT.DUE_DATE}</span>
                    <span>{`${dueOn.split(' ')[0]}`}</span>
                  </div>
                }
                LeftBottomDetails={
                  isPaid == 'False' ? null : (
                    <div className="details-item">
                      <span>{ALL_TEXT.PAID_DATE}</span>
                      <span>{`${datePaid.split(' ')[0]}`}</span>
                    </div>
                  )
                }
                rightTopDetails={
                  <div className="details-item extra-card">
                    {/* <span>{ALL_TEXT.CURRENT_BALANCE}</span>
                    <span>{`${currentBalance}`}</span> */}
                  </div>
                }
                // will add Api property
                rigthBottomDetails={
                  isPaid == 'False' ? null : (
                    <div className="details-item extra-card">
                      {/* <span>{ALL_TEXT.PAID_DATE}</span>
                      <span>{`${datePaid.split(' ')[0]}`}</span> */}
                    </div>
                  )
                }
              />
            );
          })
        )}
      </div>
      {totalPage > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPage}
            />
          </div>
        </div>
      )}
      {detailsModal && (
        <InvoiceAndSubscriptionDetails
          show={detailsModal}
          invoiceId={selectedInvoiceId}
          signUpId={signupId}
          handelClose={() => {
            setDetailsModal(false);
          }}
        />
      )}
    </CustomerProfileLayout>
  );
};

export default Invoices;
