import React, {useState} from 'react';
import './style.scss';
import {GenericButton, InputField} from '..';
import {FaPlus} from 'react-icons/fa';
import {FaMinus} from 'react-icons/fa';
import {BsChevronDown, BsChevronUp} from 'react-icons/bs';
import {Grid} from '@mui/material';
import Dropdown from 'react-dropdown';
import {ALL_TEXT} from '../../common/constants';

export function CustomizedSearch({
  onSearchBtnPress,
  btnLoader,
  title,
  seconddropdownlist,
  firstLabel,
  isFirstInputNumeric,
  firstPlaceholder,
  secondInput,
  isSecondInputNumeric,
  thirdInput,
  isThirdInputNumeric,
  isForthInputNumeric,
  thirdLabel,
  thirdPlaceholder,
  secondLabel,
  secondPlaceholder,
  secondDropdownLabel,
  firstDropdown,
  secondDropdown,
  thirdDropdown,
  firstDropdownLabel,
  firstDropdownList,
  thirdDropdownLabel,
  thirddropdownlist,
  onClearFilterHandler,
  agent,
  firstPrefixerValue,
  secondPrefixerValue,
  firstType,
  secondType,
  checkbox,
  checkboxLabel,
  dashboardRole,
  firstdate,
  seconddate,
  emailfilter,
  forthLabel,
  forthinput,
  forthPlaceHolder,
  forthPrefixValue,
}) {
  const [primaryFilter, setPrimaryFilter] = useState(emailfilter || '');
  const [secondryFilter, setSecondryFilter] = useState('');
  const [thirdFilter, setThirdFilter] = useState('');
  const [fourthFilter, setFourthFilter] = useState('');
  const [ispopupOpen, setIspopupOpen] = useState(true);
  const [firstDropdownValue, setFirstDropdownValue] = useState(ALL_TEXT.ALL);
  const [secondDropdownValue, setSecondDropdownValue] = useState(
    dashboardRole || ALL_TEXT.ALL,
  );
  const [thirdDropdownValue, setthirdDropdownValue] = useState(ALL_TEXT.ALL);
  const [fifthInvoiceCheckboxValue, setFifthInvoiceCheckboxValue] = useState(0);
  const onClearFilter = () => {
    setPrimaryFilter('');
    setSecondryFilter('');
    setThirdFilter('');
    setFourthFilter('');
    setFirstDropdownValue(ALL_TEXT.ALL);
    setSecondDropdownValue(ALL_TEXT.ALL);
    setthirdDropdownValue(ALL_TEXT.ALL);
    setFifthInvoiceCheckboxValue(0);
    onClearFilterHandler();
  };
  const isClearVisible =
    primaryFilter !== '' ||
    secondryFilter != '' ||
    thirdFilter != '' ||
    fourthFilter != '' ||
    firstDropdownValue != ALL_TEXT.ALL ||
    secondDropdownValue != ALL_TEXT.ALL ||
    thirdDropdownValue != ALL_TEXT.ALL ||
    fifthInvoiceCheckboxValue != 0;

  return (
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button "
            type="button"
            onClick={() => {
              setIspopupOpen(!ispopupOpen);
            }}
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne">
            <div className="top-search-hearder">
              <span>{title}</span>
              <span className="accordianbuttonStyle">
                {!ispopupOpen && isClearVisible && (
                  <span onClick={onClearFilter} className="clear-filter">
                    Clear All
                  </span>
                )}

                {ispopupOpen ? <FaPlus /> : <FaMinus />}
              </span>
            </div>
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          {/* {!ispopupOpen && isClearVisible && (
            <span onClick={onClearFilter} className="clear-filter">
            Clear All
            </span>
          )} */}
          <hr className="hr-line" />
          <div class="accordion-body">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <label htmlFor="/">{firstLabel}</label>
                {firstdate === true ? (
                  <InputField
                    type={firstType}
                    prefixerValue={firstPrefixerValue}
                    search
                    placeHolder={firstPlaceholder}
                    onKeyDown={e => e.preventDefault()}
                    value={primaryFilter}
                    handleChange={({target: {value}}) => {
                      if (isFirstInputNumeric) {
                        const numericValue = value
                          .replace(/\D/g, '')
                          .slice(0, 7);
                        setPrimaryFilter(numericValue);
                      } else {
                        setPrimaryFilter(value);
                      }
                    }}
                  />
                ) : (
                  <InputField
                    type={firstType}
                    prefixerValue={firstPrefixerValue}
                    search
                    placeHolder={firstPlaceholder}
                    value={primaryFilter}
                    handleChange={({target: {value}}) => {
                      if (isFirstInputNumeric) {
                        const numericValue = value
                          .replace(/\D/g, '')
                          .slice(0, 7);
                        setPrimaryFilter(numericValue);
                      } else {
                        setPrimaryFilter(value);
                      }
                    }}
                  />
                )}
              </Grid>
              {secondInput ? (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label htmlFor="/">{secondLabel}</label>
                  {seconddate === true ? (
                    <InputField
                      type={secondType}
                      prefixerValue={secondPrefixerValue}
                      search
                      placeHolder={secondPlaceholder}
                      onKeyDown={e => e.preventDefault()}
                      value={secondryFilter}
                      handleChange={({target: {value}}) => {
                        if (isSecondInputNumeric) {
                          const numericValue = value
                            .replace(/\D/g, '')
                            .slice(0, 6);
                          setSecondryFilter(numericValue);
                        } else {
                          setSecondryFilter(value);
                        }
                      }}
                    />
                  ) : (
                    <InputField
                      type={secondType}
                      prefixerValue={secondPrefixerValue}
                      search
                      placeHolder={secondPlaceholder}
                      value={secondryFilter}
                      handleChange={({target: {value}}) => {
                        if (isSecondInputNumeric) {
                          const numericValue = value
                            .replace(/\D/g, '')
                            .slice(0, 6);
                          setSecondryFilter(numericValue);
                        } else {
                          setSecondryFilter(value);
                        }
                      }}
                    />
                  )}
                </Grid>
              ) : null}
              {thirdInput ? (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label htmlFor="/">{thirdLabel}</label>
                  <InputField
                    search
                    placeHolder={thirdPlaceholder}
                    value={thirdFilter}
                    handleChange={({target: {value}}) => {
                      if (isThirdInputNumeric) {
                        const numericValue = value
                          .replace(/\D/g, '')
                          .slice(0, 6);
                        setThirdFilter(numericValue);
                      } else {
                        setThirdFilter(value);
                      }
                    }}
                  />
                </Grid>
              ) : null}
              {firstDropdown ? (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label htmlFor="/">{firstDropdownLabel}</label>
                  <Dropdown
                    options={firstDropdownList}
                    onChange={i => {
                      setFirstDropdownValue(i.value);
                    }}
                    value={firstDropdownValue}
                    menuClassName="dropdown-menu-alluser"
                    className="dropdown-style-by-status"
                    controlClassName="status-control-dropdown"
                    placeholder="Select an option"
                    arrowClosed={<BsChevronDown />}
                    arrowOpen={<BsChevronUp />}
                  />
                </Grid>
              ) : null}
              {secondDropdown && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label htmlFor="/">{secondDropdownLabel}</label>
                  <Dropdown
                    options={seconddropdownlist}
                    onChange={i => {
                      setSecondDropdownValue(i.value);
                    }}
                    value={secondDropdownValue}
                    menuClassName="dropdown-menu-alluser"
                    className="dropdown-style-by-status Request-Status"
                    controlClassName="status-control-dropdown"
                    placeholder="Select an option"
                    arrowClosed={<BsChevronDown />}
                    arrowOpen={<BsChevronUp />}
                  />
                </Grid>
              )}
              {thirdDropdown && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="third-drop-down">
                    <label htmlFor="/">{thirdDropdownLabel}</label>
                    <Dropdown
                      options={thirddropdownlist}
                      onChange={i => {
                        if (agent) {
                          setthirdDropdownValue(i);
                        } else {
                          setthirdDropdownValue(i.value);
                        }
                      }}
                      value={thirdDropdownValue}
                      menuClassName="dropdown-menu-alluser"
                      className="dropdown-style-by-status "
                      controlClassName="status-control-dropdown"
                      placeholder="Select an option"
                      arrowClosed={<BsChevronDown />}
                      arrowOpen={<BsChevronUp />}
                    />
                  </div>
                </Grid>
              )}
              {checkbox && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="checkbox-invoice">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                      value={fifthInvoiceCheckboxValue}
                      checked={fifthInvoiceCheckboxValue}
                      onChange={d => {
                        setFifthInvoiceCheckboxValue(d.target.checked);
                      }}
                    />

                    <label htmlFor="/">{checkboxLabel}</label>
                  </div>
                </Grid>
              )}
              {forthinput && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="phone-textbox">
                    <label htmlFor="/">{forthLabel}</label>
                    <InputField
                      search
                      value={fourthFilter}
                      placeholder={forthPlaceHolder}
                      prefixerValue={forthPrefixValue}
                      handleChange={({target: {value}}) => {
                        if (isForthInputNumeric) {
                          const numericValue = value
                            .replace(/\D/g, '')
                            .slice(0, 10);
                          if (
                            numericValue.length > 0 &&
                            numericValue[0] === '1'
                          ) {
                            return '';
                          }
                          {
                            setFourthFilter(numericValue);
                          }
                        } else {
                          setFourthFilter(value);
                        }
                      }}
                      onPaste={event => {
                        const clipboardData =
                          event.clipboardData || window.clipboardData;
                        const pastedText = clipboardData.getData('text');
                        const cleanedText = pastedText.replace(/^\+1/, '');
                        setFourthFilter(cleanedText);
                        event.preventDefault();
                      }}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
            <div className="custom-search-btn-container">
              <GenericButton
                id="customized-search"
                loading={btnLoader}
                buttonText={ALL_TEXT.SEARCH}
                onPress={() => {
                  onSearchBtnPress(
                    primaryFilter,
                    secondryFilter,
                    thirdFilter,
                    firstDropdownValue,
                    secondDropdownValue,
                    thirdDropdownValue,
                    fifthInvoiceCheckboxValue,
                    fourthFilter,
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
