import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import './styles.scss';
import {images} from '../../common';
import {ALL_TEXT, ENUMS} from '../../common/constants';
import {GenericButton, GenericModal, InputField} from '../../components';
import {Formik, Form} from 'formik';
import {signinSchema} from '../../common/schemas';
import {path} from './../../common/routesNames';
import {setDeviceToken_, setProfile, setToken} from '../../utils/localstorage';
import {performLogin} from './../../utils/rest-services/index';
import {getToken} from 'firebase/messaging';
import {messaging} from '../../firebase';
import {setIsUserLoggedIn} from '../../redux/slice/popup-modal';
import {useDispatch} from 'react-redux';
import {setloginUserEmpId} from '../../redux/slice/login';

const Login = () => {
  const [btnLoader, setbtnLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deviceToken, setDeviceToken] = useState('');
  const [apiResponse, setApiResponse] = useState({
    type: ENUMS.MODAL_TYPES.ERROR,
    title: '',
    body: '',
  });
  const navigate = useNavigate();
  const initialValues = {
    email: '',
    password: '',
  };
  const dispatch = useDispatch();
  const saveUserData = (token, employee) => {
    setToken(`bearer ${token}`);
    setProfile(employee);
    // navigate(path.dashboard, {replace: true});
    // dispatch(setIsUserLoggedIn());
    if (employee?.roleId === ENUMS.STAFF.ROLE.CUSTOMER_SUPPORT.VALUE) {
      navigate(path.users, {replace: true});
    } else {
      navigate(path.dashboard, {replace: true});
      dispatch(setIsUserLoggedIn());
    }
  };
  //we will use it again so it is commented

  const requestLogin = async values => {
    setbtnLoader(true);
    let result = await performLogin({
      email: values.email,
      password: values.password,
      deviceToken: deviceToken,
      deviceType: 'web',
    });
    const {success, content} = result || {};
    if (result && success) {
      dispatch(setloginUserEmpId(result.content.employee.roleId));
      const {token, employee} = content;
      saveUserData(token, employee);
    } else {
      setShowModal(true);
      setApiResponse({
        type: ENUMS.MODAL_TYPES.ERROR,
        title: '',
        body: result.description,
      });
    }
    setbtnLoader(false);
  };
  const handleKeyPress = (event, submitForm) => {
    if (event.which === 13) {
      submitForm();
    }
  };

  const requestNotifications = async () => {
    let permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPI_KEY,
      });
      setDeviceToken(token);
      setDeviceToken_(token);
    } else if (permission === 'denied') {
      alert("You won't be able to get notifications.");
    }
  };
  useEffect(() => {
    requestNotifications();
  }, []);
  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        onSubmit={(values, formikActions) => {
          requestLogin(values, formikActions);
        }}
        validationSchema={signinSchema}
        enableReinitialize>
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
          submitForm,
        }) => (
          <Form onKeyPress={e => handleKeyPress(e, submitForm)}>
            <div
              className="login-background"
              style={{
                backgroundImage: `url(${images.noDukAmdinBackground})`,
              }}>
              <div className="noduk-logo">
                <img
                  src={images.newNodukLogo}
                  alt={ALL_TEXT.ICON}
                  className=" noDukImage"
                />
              </div>
              <div className="sign-in-box">
                <div className="login-logo">
                  <img src={images.userLogin} alt={ALL_TEXT.ICON} />
                  <p>{ALL_TEXT.LOGIN}</p>
                </div>
                <div className="login-input-field">
                  <InputField
                    icon={<span class="icon-email"></span>}
                    name={'email'}
                    id={'email'}
                    placeholder={ALL_TEXT.ENTER_EMAIL}
                    error={touched.email && errors.email}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <InputField
                    icon={<span class="icon-password"></span>}
                    id={'password'}
                    name={'password'}
                    placeholder={ALL_TEXT.ENTER_PASSWORD}
                    error={touched.password && errors.password}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    isPassword
                  />
                  <div
                    className="forgotPassword-action"
                    onClick={() => {
                      navigate({pathname: path.forgotPassword});
                    }}>
                    <a>{ALL_TEXT.FORGOT_PASSWORD1}</a>
                  </div>
                  <div className="login-button">
                    <GenericButton
                      id="btn-login"
                      loading={btnLoader}
                      buttonText={ALL_TEXT.LOGIN}
                      onPress={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <GenericModal
        show={showModal}
        type={apiResponse.type}
        title={apiResponse.title}
        body={apiResponse.body}
        handleClose={() => {
          setShowModal(false);
        }}
        buttonText={ALL_TEXT.OK}
      />
    </>
  );
};
export default Login;
