import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CriteriaDropdown} from '../criteria-dropdown';
import './styles.scss';
import _ from 'lodash';
import {
  setCouponCodeText,
  setDiscounts,
  setDuration,
  setIsUserChange,
  setPromoCode,
  setSelectedCard,
  setSelectedUser,
  setTotalCost,
} from '../../redux/slice/checkout';
import {useLocation, useNavigate} from 'react-router';
import {path} from '../../common/routesNames';
import {getUserDropdownValues} from '../../utils/rest-services';
import {ALL_TEXT, QUERYPARAM} from '../../common/constants';

export const UserInfo = () => {
  const selectedUserDetail = useSelector(
    state => state.checkout?.selectedUser ?? null,
  );
  const signupId = useSelector(
    state => state.checkout.selectedUser?.signupId ?? null,
  );
  const [dropDownList, setDropDownList] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const isSelectedUser = useSelector(
    state => state.checkout.isUserChange ?? null,
  );
  let location = useLocation();
  const isAddPolicyPage = location.pathname.includes(path.addPolicy);

  const getParamFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamFromUrl();
  const navigate = useNavigate();

  useEffect(() => {
    getUserInitialData();
  }, [isSelectedUser]);

  const getUserInitialData = async () => {
    const id = query.get(QUERYPARAM.SIGNUPID);
    if (signupId || id) {
      const userObj = await getActiveUsers(id ?? signupId, '');
      if (id) {
        const selectedUserObj = {
          signupId: userObj[0].signupId,
          email: userObj[0].email,
          phone: userObj[0].phone,
          name: `${userObj[0].firstName} ${userObj[0].lastName}`,
          value: `${userObj[0].firstName} ${userObj[0].lastName}`,
          label: `${userObj[0].firstName} ${userObj[0].lastName}`,
          id: userObj[0].signupId,
          signupDate: userObj[0].signupDate,
        };
        dispatch(setSelectedUser(selectedUserObj));
      }
    }
  };
  const isInvalidInput = input => {
    const alphanumericRegex = /^[a-zA-Z0-9\s!@#$%^&*()_+:;<>.?~\\/-]*$/;
    return !alphanumericRegex.test(input);
  };

  const getObjectOrNullOrUndefined = obj => {
    return Object.keys(obj).length === 0 ? null : obj;
  };
  const getActiveUsers = async (signupId, userName) => {
    setLoader(true);
    let response = await getUserDropdownValues({
      signupId: signupId,
      userName: userName,
    });
    setLoader(false);
    if (response && response.success) {
      const {content} = response;
      const newData = _.map(content, item => {
        return _.pick(item, [
          'signupId',
          'email',
          'firstName',
          'lastName',
          'phone',
          'signupDate',
        ]);
      });
      const finalData = _.map(newData, (item, index) => {
        return {
          id: index + 1,
          label: `${item.firstName} ${item.lastName}`,
          value: `${item.firstName} ${item.lastName}`,
          signupId: item.signupId,
          email: item.email,
          name: `${item.firstName} ${item.lastName}`,
          phone: item.phone,
          signupDate: item.signupDate,
        };
      });
      setDropDownList(finalData);
      return content;
    }
  };
  const removeCouponHandler = () => {
    dispatch(setCouponCodeText(''));
    dispatch(setPromoCode(''));
    dispatch(setDuration(''));
    dispatch(setTotalCost(''));
    dispatch(
      setDiscounts({
        amount: 0,
        percentage: 0,
      }),
    );
  };
  return (
    <div className="user-info-container">
      <div className="user-select-dropdown">
        <CriteriaDropdown
          options={dropDownList}
          onInputChange={input => {
            if (input.length >= 3 && !isInvalidInput(input)) {
              getActiveUsers(0, input);
            }
          }}
          isDisable={
            location.pathname == path.selectPayment ||
            location.pathname == path.reviewAndPay
          }
          height={
            (location.pathname == path.selectPayment ||
              location.pathname == path.reviewAndPay) &&
            '3rem'
          }
          loading={loader}
          dropdowntitle={'Select Customer'}
          selectedOption={getObjectOrNullOrUndefined(selectedUserDetail)}
          onChangeOption={e => {
            const selectedUserObj = {
              signupId: e.signupId,
              email: e.email,
              phone: e.phone,
              name: `${e.name}`,
              value: `${e.name}`,
              label: `${e.name}`,
              id: e.signupId,
              signupDate: e.signupDate,
            };

            dispatch(setSelectedUser(selectedUserObj));
            dispatch(setSelectedCard(null));
            dispatch(setIsUserChange(!isSelectedUser));
            removeCouponHandler();
            navigate({
              pathname: location.pathname,
              search: `?signupId=${e.signupId}&isActive=true`,
            }); // just to update the query param
          }}
        />
      </div>
      {getObjectOrNullOrUndefined(selectedUserDetail) && (
        <div className="user-detail-container">
          <div className="user-name-image">
            <span class="icon-user"></span>
            <span>{selectedUserDetail?.name}</span>
          </div>
          {isAddPolicyPage ? (
            <div className="user-contact">
              <span className="user-email">{selectedUserDetail?.email}</span>
              <span className="user-phone">{selectedUserDetail?.phone}</span>
            </div>
          ) : (
            <div className="user-signupdetails">
              <span className="user-email">
                <b>{ALL_TEXT.LAST_LOGIN}</b> {selectedUserDetail?.signupDate}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
