import React from 'react';
import './style.scss';
import {Grid} from '@mui/material';
import Dropdown from 'react-dropdown';
import {BsChevronDown, BsChevronUp} from 'react-icons/bs';
import {Calendar, CriteriaDropdown, GenericButton, InputField} from '..';
import {usePostApiAdminGetUserByRoleIdMutation} from '../../redux/slice/nodukAdmin.ts';
import _ from 'lodash';

export function CommissionSearch({
  firstDropdownLabel,
  firstDropdownList,
  setFirstDropdownValue,
  firstDropdownValue,
  secondDropdownLabel,
  onSubmitBtnPress,
  selectedUser,
  setSelectedUser,
  dateRange,
  setDateRange,
}) {
  const getObjectOrNullOrUndefined = obj => {
    return Object.keys(obj).length === 0 ? null : obj;
  };

  const [getUsersListAPI, getUsersListResponse] =
    usePostApiAdminGetUserByRoleIdMutation();
  const dataList = getUsersListResponse?.data?.content || [];

  const getUserList = inputArray => {
    const transformedArray = _.map(inputArray, item => ({
      value: item.fullName,
      label: item.fullName,
      id: item.id,
    }));
    return transformedArray;
  };

  return (
    <div className="commission-search-container">
      <span className="search-title">Calculate Commission For</span>
      <div className="filter-items-commission">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <label htmlFor="/">{firstDropdownLabel}</label>
            <Dropdown
              options={firstDropdownList}
              onChange={i => {
                setFirstDropdownValue(i);
                setSelectedUser('');
              }}
              value={firstDropdownValue}
              menuClassName="dropdown-menu-alluser"
              className="dropdown-style-by-status"
              controlClassName="status-control-dropdown"
              placeholder="Select an option"
              arrowClosed={<BsChevronDown />}
              arrowOpen={<BsChevronUp />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <label htmlFor="/">{secondDropdownLabel}</label>
            <CriteriaDropdown
              options={getUserList(dataList)}
              onInputChange={i => {
                if (i.length >= 1) {
                  getUsersListAPI({
                    userReq: {
                      empId: 0,
                      roleId: 0,
                      id: firstDropdownValue?.value || 0,
                      text: i,
                    },
                  });
                }
              }}
              isDisable={!firstDropdownValue?.value}
              loading={getUsersListResponse.isLoading}
              dropdowntitle={'Select User'}
              noOptionsMessage={() => 'Please Type Name'}
              selectedOption={getObjectOrNullOrUndefined(selectedUser)}
              onChangeOption={e => {
                setSelectedUser(e);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <label htmlFor="/">{'Date'}</label>
            <div>
              <Calendar
                filterdate
                initialValue={dateRange}
                onChangeDate={d => {
                  setDateRange(d);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="search-btn-container">
        <GenericButton
          onPress={() =>
            onSubmitBtnPress(firstDropdownValue, selectedUser, dateRange)
          }
          disable={
            !(firstDropdownValue && Object.keys(selectedUser).length > 0)
          }
          buttonText={'Calculate'}
        />
      </div>
    </div>
  );
}

export const CommissionSeachWithEmailAndDate = ({
  fieldValue,
  onSubmitBtnPress,
  setFieldValue,
  dateRange,
  setDateRange,
}) => {
  return (
    <div className="commission-search-container">
      <span className="search-title">Calculate Commission For</span>
      <div className="filter-items-commission">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <label htmlFor="/">{'Email'}</label>
            <InputField
              search
              placeHolder={'Enter your Email'}
              value={fieldValue}
              handleChange={({target: {value}}) => {
                setFieldValue(value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <label htmlFor="/">{'Date'}</label>
            <div>
              <Calendar
                filterdate
                initialValue={dateRange}
                onChangeDate={d => {
                  setDateRange(d);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="search-btn-container">
        <GenericButton
          onPress={() => onSubmitBtnPress(fieldValue, dateRange)}
          buttonText={'Calculate'}
        />
      </div>
    </div>
  );
};
