import React, {useState, useEffect} from 'react';
import {ALL_TEXT, ENUMS} from '../../../common';
import './style.scss';
import {CommissionSearch} from '../../../components/commission-search-bar';
import {AdminAgencyCalculator} from './admin(agency/agent)';
import {AdminSalesAmbassador} from './admin(sales-ambassador)';
import {AdminBussinessAmbassador} from './admin(business-ambassador)';
import {AgencySalesCalculator} from './agency(agency)';
import {AgencyAgentSalesCalculator} from './agency(agent)';
import {IndepandantAgentCalculator} from './indepandant-agent';
import {AmbassadorCalculator} from './ambassador';
import {
  usePostApiAdminCalculateCommissionMutation,
  usePostApiAdminGetAgencyCommissionedAgentsMutation,
} from '../../../redux/slice/nodukAdmin.ts';
import {Loader} from '../../../components';
import {getProfile} from '../../../utils/localstorage';
import subDays from 'date-fns/subDays';

const TopTabsCalculator = ({selectedTab, setSelectedTab}) => {
  return (
    <div className="disaster-tab">
      <div
        className={
          selectedTab == 1 ? 'selected-tab disaster-risk' : 'disaster-risk'
        }
        onClick={() => setSelectedTab(1)}>
        {ALL_TEXT.YOUR_SALE}
      </div>
      <div
        className={
          selectedTab == 2 ? 'selected-tab disaster-risk' : 'disaster-risk'
        }
        onClick={() => setSelectedTab(2)}>
        {ALL_TEXT.AGENT_SALE}
      </div>
    </div>
  );
};

function CommissionCalculator() {
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [searchTitle, setSearchTitle] = useState({
    name: '',
    date: '',
  });

  const [role, setRole] = useState({});
  const [getCommissionDataAPI, getCommissionDataResult] =
    usePostApiAdminCalculateCommissionMutation();

  const [getAgencyAgentDataAPI, getAgencyAgentDataResponse] =
    usePostApiAdminGetAgencyCommissionedAgentsMutation();

  const commissionData = getCommissionDataResult?.data?.content;
  const agencyAgentData = getAgencyAgentDataResponse?.data?.content;
  const {roleId} = getProfile();
  const [dateRange, setDateRange] = useState([
    subDays(new Date(), 30),
    new Date(),
  ]);

  const GetAdminScreen = ({children}) => {
    return (
      <>
        <CommissionSearch
          firstDropdownLabel={'Role'}
          firstDropdownList={[
            {
              label: ENUMS.STAFF.ROLE.AGENCY.LABEL,
              value: ENUMS.STAFF.ROLE.AGENCY.VALUE,
            },
            {
              label: ENUMS.STAFF.ROLE.AGENT.LABEL,
              value: ENUMS.STAFF.ROLE.INDEPANDANT_AGENT.VALUE,
            },
            {
              label: ENUMS.STAFF.ROLE.SALES_REP.LABEL,
              value: ENUMS.STAFF.ROLE.SALES_REP.VALUE,
            },
            {
              label: ENUMS.STAFF.ROLE.BUSINESS_AMB.LABEL,
              value: ENUMS.STAFF.ROLE.BUSINESS_AMB.VALUE,
            },
          ]}
          firstDropdownValue={Object.keys(role).length === 0 ? null : role}
          setFirstDropdownValue={data => {
            setRole(data);
          }}
          dateRange={dateRange}
          setDateRange={setDateRange}
          secondDropdownLabel={'Name'}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          onSubmitBtnPress={(role, name, dateRange) => {
            if (role && Object.keys(name).length > 0) {
              getCommissionDataAPI({
                adminDashboard: {
                  empId: name.id,
                  roleId: role.value,
                  startDate: dateRange[0],
                  endDate: dateRange[1],
                },
              });
              setSelectedRole(role);
            }
            setSearchTitle({
              name: selectedUser.value,
              date: dateRange,
            });
          }}
        />
        {children}
      </>
    );
  };
  const getFormattedDate = originalDateString => {
    const originalDate = new Date(originalDateString);
    // Extract year, month, and day components
    const year = originalDate.getFullYear().toString().slice(-2); // Extract the last 2 digits of the year
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = originalDate.getDate().toString().padStart(2, '0');
    // Format the new date string as "DD-MM-YY"
    return `${day}-${month}-${year}`;
  };
  const getSectionOfAdmin = id => {
    switch (id) {
      case ENUMS.STAFF.ROLE.AGENCY.VALUE:
      case ENUMS.STAFF.ROLE.INDEPANDANT_AGENT.VALUE:
        return (
          <GetAdminScreen>
            <div className="title-search-bar">
              <span>{searchTitle.name}</span>
              <span>
                {` (${getFormattedDate(
                  searchTitle.date[0],
                )} -  ${getFormattedDate(searchTitle.date[1])})`}
              </span>
            </div>
            {getCommissionDataResult.isLoading ? (
              <Loader />
            ) : (
              <AdminAgencyCalculator data={commissionData} />
            )}
          </GetAdminScreen>
        );
      case ENUMS.STAFF.ROLE.SALES_REP.VALUE:
        return (
          <GetAdminScreen>
            <div className="title-search-bar">
              <span>{searchTitle.name}</span>
              <span>
                {` (${getFormattedDate(
                  searchTitle.date[0],
                )} -  ${getFormattedDate(searchTitle.date[1])})`}
              </span>
            </div>
            {getCommissionDataResult.isLoading ? (
              <Loader />
            ) : (
              <AdminSalesAmbassador data={commissionData} />
            )}
          </GetAdminScreen>
        );
      case ENUMS.STAFF.ROLE.BUSINESS_AMB.VALUE:
        return (
          <GetAdminScreen>
            <div className="title-search-bar">
              <span>{searchTitle.name}</span>
              <span>
                {` (${getFormattedDate(
                  searchTitle.date[0],
                )} -  ${getFormattedDate(searchTitle.date[1])})`}
              </span>
            </div>
            {getCommissionDataResult.isLoading ? (
              <Loader />
            ) : (
              <AdminBussinessAmbassador data={commissionData} />
            )}
          </GetAdminScreen>
        );
      default:
        return (
          <GetAdminScreen>
            <span className="no-user-selected">
              Please Select user to calculate commission
            </span>
          </GetAdminScreen>
        );
    }
  };
  const getSectionOfAgency = () => {
    const [selectedTab, setSelectedTab] = useState(1);

    useEffect(() => {
      const [startDate, endDate] = dateRange;
      if (selectedTab === 1) {
        getCommissionDataAPI({
          adminDashboard: {
            empId: 0,
            roleId: 0,
            startDate: startDate,
            endDate: endDate,
          },
        });
      } else {
        getAgencyAgentDataAPI({
          adminDashboard: {
            empId: 0,
            roleId: 0,
            startDate: startDate,
            endDate: endDate,
            email: '',
            pageNo: 1,
            rowsPerPage: 10,
          },
        });
      }
    }, [selectedTab]);

    const GetAgencySection = ({id}) => {
      switch (id) {
        case 1:
          return (
            <AgencySalesCalculator
              data={commissionData}
              dateRange={dateRange}
              setDateRange={setDateRange}
              loader={getCommissionDataResult.isLoading}
              onDateChangeFilter={(start, end) => {
                getCommissionDataAPI({
                  adminDashboard: {
                    empId: 0,
                    roleId: 0,
                    startDate: start,
                    endDate: end,
                  },
                });
              }}
            />
          );
        case 2:
          return (
            <AgencyAgentSalesCalculator
              data={agencyAgentData}
              dateRange={dateRange}
              setDateRange={setDateRange}
              loader={getAgencyAgentDataResponse.isLoading}
              onDateChangeFilter={(email, start, end, pageNo) => {
                getAgencyAgentDataAPI({
                  adminDashboard: {
                    empId: 0,
                    roleId: 0,
                    email: email || '',
                    pageNo: pageNo || 1,
                    rowsPerPage: 10,
                    startDate: start || dateRange[0],
                    endDate: end || dateRange[1],
                  },
                });
              }}
            />
          );
      }
    };
    return (
      <>
        <TopTabsCalculator
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <GetAgencySection id={selectedTab} />
      </>
    );
  };

  const getScreensRoleWise = userId => {
    switch (userId) {
      case ENUMS.STAFF.ROLE.ADMIN.VALUE:
        // case ENUMS.STAFF.ROLE.DISTRIBUTION_CHANNEL.VALUE:
        return getSectionOfAdmin(selectedRole.value);
      case ENUMS.STAFF.ROLE.AGENCY.VALUE:
        return getSectionOfAgency();
      case ENUMS.STAFF.ROLE.INDEPANDANT_AGENT.VALUE:
      case ENUMS.STAFF.ROLE.AGENT.VALUE:
        return (
          <IndepandantAgentCalculator
            data={commissionData}
            dateRange={dateRange}
            setDateRange={setDateRange}
            loader={getCommissionDataResult.isLoading}
            onDateChangeFilter={(start, end) => {
              getCommissionDataAPI({
                adminDashboard: {
                  empId: 0,
                  roleId: 0,
                  startDate: start,
                  endDate: end,
                },
              });
            }}
          />
        );
      case ENUMS.STAFF.ROLE.SALES_REP.VALUE:
        return (
          <AmbassadorCalculator
            data={commissionData}
            dateRange={dateRange}
            setDateRange={setDateRange}
            loader={getCommissionDataResult.isLoading}
            userId={ENUMS.STAFF.ROLE.SALES_REP.VALUE}
            onDateChangeFilter={(start, end) => {
              getCommissionDataAPI({
                adminDashboard: {
                  empId: 0,
                  roleId: 0,
                  startDate: start,
                  endDate: end,
                },
              });
            }}
          />
        );
      case ENUMS.STAFF.ROLE.BUSINESS_AMB.VALUE:
        return (
          <AmbassadorCalculator
            data={commissionData}
            dateRange={dateRange}
            loader={getCommissionDataResult.isLoading}
            setDateRange={setDateRange}
            userId={ENUMS.STAFF.ROLE.BUSINESS_AMB.VALUE}
            onDateChangeFilter={(start, end) => {
              getCommissionDataAPI({
                adminDashboard: {
                  empId: 0,
                  roleId: 0,
                  startDate: start,
                  endDate: end,
                },
              });
            }}
          />
        );
    }
  };
  const USER_ROLE_ID = roleId;
  return (
    <div className="commission-calc-page-container">
      <div className="title-container-commission-calc-page">
        <div>
          <span>{ALL_TEXT.COMMISSION_CALCULATOR}</span>
        </div>
      </div>
      {getScreensRoleWise(USER_ROLE_ID)}
    </div>
  );
}

export default CommissionCalculator;
