const preferenceKeys = {
  userProfile: `userProfile`,
  authToken: `authToken`,
  deviceToken: `DeviceToken`,
};

export const setProfile = userDetails => {
  localStorage.setItem(preferenceKeys.userProfile, JSON.stringify(userDetails));
};
export const getProfile = () => {
  return JSON.parse(localStorage.getItem(preferenceKeys.userProfile) || {});
};

export const setToken = authToken => {
  localStorage.setItem(preferenceKeys.authToken, authToken);
};
export const setDeviceToken_ = token => {
  localStorage.setItem(preferenceKeys.deviceToken, token);
};
export const getDeviceToken_ = () => {
  return localStorage.getItem(preferenceKeys.deviceToken) || '';
};
export const getToken = () => {
  return localStorage.getItem(preferenceKeys.authToken) || '';
};
export const getEmployeeIdFromToken = () => {
  const authToken = localStorage.getItem(preferenceKeys.authToken) || '';
  if (authToken) {
    try {
      const payloadBase64 = authToken.split('.')[1];
      const decodedPayload = atob(payloadBase64);
      const decodedToken = JSON.parse(decodedPayload);
      if (decodedToken && decodedToken.EmployeeId) {
        const employeeId = parseInt(decodedToken.EmployeeId, 10);
        return employeeId;
      }
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  }
};

// export const logoutToken = async () => {
//   await localStorage.getItem(preferenceKeys.authToken, '');
// };

export const clearStorage = () => {
  localStorage.clear();
  // localStorage.setItem(preferenceKeys.userProfile, null);
  // localStorage.setItem(preferenceKeys.authToken, null);
};
