import React, {useEffect, useState} from 'react';
import {ALL_TEXT, images} from '../../common';
import './style.scss';
import {getQRCode} from '../../utils/rest-services';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import {EmailSharePopUp, GenericButton, Loader} from '../../components';
import {
  NodukAdmnApi,
  usePostApiAdminAddQrCodeMutation,
} from '../../redux/slice/nodukAdmin.ts';
import {toast} from 'react-toastify';
import {generateFormData} from '../../utils/helpers';
import {saveAs} from 'file-saver';
import {TbArrowNarrowDown} from 'react-icons/tb';

function QRCode() {
  const [imageURL, setImageURL] = useState('');
  const [qrImage, setQrImage] = useState('');
  const [emailSharePopUp, setEmailSharePopUp] = useState(false);
  const [scanCount, setScanCount] = useState(0);
  const [apiResponseStatus, setApiResponseStatus] = useState(null);
  const {useLazyGetApiAdminGetQrCodeUrlQuery} = NodukAdmnApi;
  const [getQRCodeLinkAPI] = useLazyGetApiAdminGetQrCodeUrlQuery();
  const [Link, setQRLink] = useState('');
  const [loader, setLoader] = useState(false);
  const [uploadQrCodeAPI] = usePostApiAdminAddQrCodeMutation();
  const [autoBrochure, setAutoBrochure] = useState('');
  const [deviceBrochure, setSeviceBrochure] = useState('');
  const [homeBrochure, setHomeBrochure] = useState('');
  const [commercailBrochure, setCommercailBrochure] = useState('');

  useEffect(() => {
    getQRCodeLink();
  }, []);
  const handleDownload = () => {
    saveAs(imageURL, 'image.jpg'); // Put your image URL here.
  };
  const getQRCodeLink = async () => {
    setLoader(true);
    try {
      let qrResponse = await getQRCodeLinkAPI();
      setAutoBrochure(qrResponse.data.content.autoBrochure);
      setSeviceBrochure(qrResponse.data.content.deviceBrochure);
      setHomeBrochure(qrResponse.data.content.homeBrochure);
      setCommercailBrochure(qrResponse.data.content.commercialBrochure);

      if (qrResponse && qrResponse?.data?.success) {
        setQRLink(qrResponse?.data?.content.qrUrl);
        setScanCount(qrResponse?.data?.content.scansCount);
        if (!qrResponse?.data?.content?.qrCodeImage) {
          try {
            let result = await getQRCode(qrResponse?.data?.content.qrUrl || '');
            if (result?.status === 200) {
              setQrImage(result?.data);
              const formdata = generateFormData({qrCode: result?.data});
              await uploadQrCodeAPI({
                body: formdata,
              });
              const objectURL = URL.createObjectURL(result?.data);
              setImageURL(objectURL);
            } else if (result?.response?.status === 403) {
              setApiResponseStatus(403);
              toast.error('User API request limit has expired', {
                autoClose: false,
              });
            }
          } catch (error) {
            console.error('Error fetching QR code:', error);
            toast.error(error.message, {autoClose: false});
          }
        } else {
          setImageURL(qrResponse?.data?.content?.qrCodeImage);
        }
      }
    } catch (error) {
      console.error('Error fetching QR code link:', error);
    } finally {
      setLoader(false);
    }
  };
  const handleDownloadURL = url => {
    if (url === null || url === '') {
      toast.error('No brochure avaiable');
    }
  };

  return (
    <div className="qrCode-main-container">
      <div className="title-container-qrCode">
        <span>{ALL_TEXT.PROMOTIONS}</span>
      </div>

      <div className="main-qr-container">
        <div className="maincontainer">
          <span className="qrcodeheading">{ALL_TEXT.QR_CODE_HEADING}</span>
          <div className="baroutercontainer">
            <div className="bar-containers">
              <div className="bar-items">
                <div className="textdocuments">
                  <div>
                    <img src={images.autoBrochure} alt="icon" />
                  </div>
                </div>
                <div className="bartext">
                  <h6 className="barchourHeadings">{ALL_TEXT.AUTO_BROCHURE}</h6>
                </div>
                <div className="bar-item right-content">
                  <div className="file-input" style={{position: 'relative'}}>
                    <button
                      className="downloadcustombtn"
                      onClick={() => handleDownloadURL(autoBrochure)}>
                      <a href={autoBrochure}>{ALL_TEXT.DOWNLOAD}</a>
                      <TbArrowNarrowDown style={{marginLeft: '8px'}} />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="bar-containers">
              <div className="bar-items">
                <div className="textdocuments">
                  <div>
                    <img src={images.deviceBrochure} alt="icon" />
                  </div>
                </div>
                <div className="bartext">
                  <h6 className="barchourHeadings">
                    {ALL_TEXT.DEVICE_BROCHURE}
                  </h6>
                </div>
                <div className="bar-item right-content">
                  <div className="file-input" style={{position: 'relative'}}>
                    <button
                      className="downloadcustombtn"
                      onClick={() => handleDownloadURL(deviceBrochure)}>
                      <a href={deviceBrochure}>{ALL_TEXT.DOWNLOAD}</a>
                      <TbArrowNarrowDown style={{marginLeft: '8px'}} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="baroutercontainer">
            <div className="bar-containers">
              <div className="bar-items">
                <div className="textdocuments">
                  <div>
                    <img src={images.homeBrochure} alt="icon" />
                  </div>
                </div>
                <div className="bartext">
                  <h6 className="barchourHeadings">{ALL_TEXT.HOME_BROCHURE}</h6>
                </div>
                <div className="bar-item right-content">
                  <div className="file-input" style={{position: 'relative'}}>
                    <button
                      className="downloadcustombtn"
                      onClick={() => handleDownloadURL(homeBrochure)}>
                      <a href={homeBrochure}>{ALL_TEXT.DOWNLOAD}</a>
                      <TbArrowNarrowDown style={{marginLeft: '8px'}} />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="bar-containers">
              <div className="bar-items">
                <div className="textdocuments">
                  <div>
                    <img src={images.commercailBrochure} alt="icon" />
                  </div>
                </div>
                <div className="bartext">
                  <h6 className="barchourHeadings">
                    {ALL_TEXT.COMMERCIAL_BROCHURE}
                  </h6>
                </div>
                <div className="bar-item right-content">
                  <div className="file-input" style={{position: 'relative'}}>
                    <button
                      className="downloadcustombtn"
                      onClick={() => handleDownloadURL(commercailBrochure)}>
                      <a href={commercailBrochure}>{ALL_TEXT.DOWNLOAD}</a>
                      <TbArrowNarrowDown style={{marginLeft: '8px'}} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span className="qrcodeheading">{ALL_TEXT.SCAN_QR}</span>
        </div>
        {loader ? (
          <Loader size={40} customContainerClass={''} />
        ) : (
          <>
            <div className="qr-image-container">
              <div className="qr-container">
                {imageURL && <img src={imageURL} alt="dummy" />}
              </div>
              <div className="url-container">
                <div className="url-inner-container">
                  <span
                    data-tooltip-id={'qr-link'}
                    data-tooltip-content={'Copy to Clipboard'}
                    onClick={() => navigator.clipboard.writeText(Link)}
                    className="url-link">
                    {Link}
                  </span>
                </div>
                <span className="scan-count">
                  Scan Count : <span> {scanCount}</span>
                </span>
                <Tooltip id={'qr-link'} />
              </div>
            </div>
            <div className="buttons-container">
              <GenericButton
                disable={apiResponseStatus}
                onPress={handleDownload}
                buttonText={'Download'}
              />
              <GenericButton
                disable={apiResponseStatus}
                buttonText={'Share'}
                onPress={() => setEmailSharePopUp(true)}
              />
            </div>
          </>
        )}
      </div>
      {emailSharePopUp && (
        <EmailSharePopUp
          show={emailSharePopUp}
          handleClose={() => setEmailSharePopUp(false)}
          qrImage={qrImage}
        />
      )}
    </div>
  );
}

export default QRCode;
