import React from 'react';
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './style.scss';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const Subscriptions = ({title, value, data}) => {
  const maxdata = data?.totalPlans ? data?.totalPlans : 100;
  return (
    <>
      <div className="progress-circle col-sm-1 col-md-1 col-lg-1 col-xl-1">
        <span
          data-tooltip-id={'subscription'}
          data-tooltip-content={`Total ${value} ${title} deductibles out of ${data?.totalPlans} deductible plans.`}
          className="subscription">
          <CircularProgressbar
            value={value}
            maxValue={maxdata}
            text={`${value}`}
            styles={{
              path: {
                stroke: '#dd3333',
              },
              text: {
                fontWeight: 'bold',
                fill: 'black',
                fontSize: '20px',
              },
            }}
          />
        </span>

        <Tooltip id={'subscription'} />

        <span>
          <b>{title}</b>
        </span>
      </div>
    </>
  );
};
export default Subscriptions;
