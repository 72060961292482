import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import './styles.scss';
import {InputField} from '../input-field';
import {GenericButton} from '../generic-button';
import {Formik} from 'formik';

import {emailValidateSchema} from '../../common/schemas';
import {RxCrossCircled} from 'react-icons/rx';
import {colors} from '../../common';
import {usePostApiAdminShareQrCodeMutation} from '../../redux/slice/nodukAdmin.ts';
import {generateFormData} from '../../utils/helpers';
import _ from 'lodash';
import {toast} from 'react-toastify';

export function EmailSharePopUp({show, handleClose, qrImage}) {
  const [emails, setEmails] = useState([]);
  const [isExsistEmailError, setIsExsistEmailError] = useState(false);

  const [emailShareAPI, emailShareResponse] =
    usePostApiAdminShareQrCodeMutation();

  const shareQrCodeHandler = async () => {
    const emailArr = _.map(emails, 'email');
    const formdata = generateFormData({qrImage: qrImage}, {email: emailArr});
    let response = await emailShareAPI({body: formdata});
    if (response && response.data.success) {
      toast('Qr Code has been Shared Sucessfully.');
      handleClose();
    } else {
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={(values, {resetForm}) => {
          const newEmail = values?.email.trim().toLowerCase();
          if (newEmail) {
            if (!emails.some(item => item.email.toLowerCase() === newEmail)) {
              const tempEmails = [...emails];
              tempEmails.push({email: newEmail, id: tempEmails.length});
              setEmails(tempEmails);
              resetForm();
              setIsExsistEmailError(false);
            } else {
              setIsExsistEmailError(true);
            }
          }
        }}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={emailValidateSchema}>
        {({handleChange, handleSubmit, values, touched, errors}) => (
          <>
            <Modal
              className="email-share-QR-wrapper"
              centered
              show={show}
              onHide={handleClose}>
              <Modal.Header>
                <div className="email-share-popup">
                  <span>Enter Emails To Share QR Code</span>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="email-send-field-container">
                  <div className="email-input-share">
                    <label>{'Email*'} </label>
                    <InputField
                      fullWidth
                      id="email"
                      name="email"
                      value={values.email}
                      handleChange={handleChange}
                      placeholder={'Enter Email'}
                      error={
                        (touched.email && errors.email) ||
                        (isExsistEmailError && 'Email Already Exsist')
                      }
                    />
                  </div>
                  <div className="add-btn-container-email">
                    <GenericButton buttonText={'Add'} onPress={handleSubmit} />
                  </div>
                </div>
                {emails.length > 0 && (
                  <div className="selected-emails-container">
                    {emails.map((i, index) => (
                      <div key={index} className="selected-email-QR">
                        <span>{i.email}</span>
                        <RxCrossCircled
                          onClick={() => {
                            const tempEmails = [...emails];
                            const index = tempEmails.findIndex(
                              item => item.id == i.id,
                            );
                            if (index != -1) {
                              tempEmails.splice(index);
                              setEmails(tempEmails);
                            }
                          }}
                          cursor={'pointer'}
                          color={colors.primary}
                          size={20}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <div className="email-verification-action-button">
                  <GenericButton
                    grayButton
                    customStyle="cancel-button"
                    buttonText={'Cancel'}
                    onPress={handleClose}
                  />
                  <GenericButton
                    onPress={shareQrCodeHandler}
                    buttonText={'Send'}
                    loading={emailShareResponse.isLoading}
                    disable={emails.length === 0}
                  />
                </div>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
}
