import React, {useState, useEffect, useRef} from 'react';
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import {GenericButton} from '../generic-button';
import './styles.scss';
import {GoX} from 'react-icons/go';
import {Grid} from '@mui/material';
import {InputField} from '..';
import {CriteriaDropdown} from './../criteria-dropdown/index';
import {
  addItemToCart,
  getSingleCartItem,
  getDeductibleList,
} from '../../utils/rest-services';
import {Loader} from '../loader';
import {toast} from 'react-toastify';
import {Formik} from 'formik';
import {editDeductibleSchema} from '../../common/schemas';
import {GenericModal} from '..';
import {ALL_TEXT, ENUMS} from '../../common';

const VehicleInputs = ({
  selectedOption,
  dedectibles,
  setSelectedOption,
  deductiblesRef,
  setIsOtherDeductibles,
  isOtherDeductibles,
  btnLoading,
  editFormHandler,
}) => {
  return (
    <Formik
      initialValues={{
        deductibles: selectedOption.deductible || null,
        otherDeductibles: selectedOption.otherDeductibles || '',
      }}
      onSubmit={() => {
        editFormHandler();
      }}
      validationSchema={editDeductibleSchema(isOtherDeductibles)}
      enableReinitialize={true}>
      {({handleSubmit, touched, errors, setFieldValue}) => (
        <>
          <Grid container spacing={0.5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CriteriaDropdown
                label={'Carrier'}
                // dropdowntitle={plans.selectCriteria}
                isDisable={true}
                selectedOption={selectedOption.carrier}
                height={'3rem'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CriteriaDropdown
                label={'Maker'}
                // placeHolder={plans.selectMaker}
                isDisable={true}
                selectedOption={selectedOption.maker}
                height={'3rem'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CriteriaDropdown
                label={'Model'}
                // placeHolder={plans.selectModel}
                isDisable={true}
                selectedOption={selectedOption.model}
                height={'3rem'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <InputField
                label={'Year'}
                value={selectedOption.year}
                // placeHolder={plans.year}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CriteriaDropdown
                label={' Deductibles'}
                options={dedectibles}
                // dropdowntitle={plans.selectDeductible}
                height={'3rem'}
                selectedOption={selectedOption.deductible}
                error={touched.deductibles && errors.deductibles}
                onChangeOption={e => {
                  setSelectedOption(other => ({...other, deductible: e}));
                  if (e.id === ALL_TEXT.NOT_AVAILABLE_ID) {
                    setIsOtherDeductibles(true);
                    setTimeout(() => {
                      deductiblesRef?.current?.focus();
                    }, 500);
                  } else {
                    setIsOtherDeductibles(false);
                  }
                }}
              />
            </Grid>
            {isOtherDeductibles && (
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <InputField
                  label={'Other Deductibles'}
                  value={selectedOption.otherDeductibles}
                  handleChange={e => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/[^0-9.]/g, ''); // remove non-numeric characters
                    const restrictedValue = numericValue.substring(0, 7); // restrict to 10 digits
                    setFieldValue('otherDeductibles', restrictedValue);
                    setSelectedOption(other => ({
                      ...other,
                      otherDeductibles: restrictedValue,
                    }));
                  }}
                  placeHolder={'Other Deductibles'}
                  error={touched.otherDeductibles && errors.otherDeductibles}
                  name={'otherDeductibles'}
                  reff={deductiblesRef}
                />
              </Grid>
            )}
          </Grid>
          <div className="footerContainer-edit-popup">
            {
              <GenericButton
                onPress={handleSubmit}
                buttonText={ALL_TEXT.UPDATE}
                loading={btnLoading}
              />
            }
          </div>
        </>
      )}
    </Formik>
  );
};
const HomeInput = ({
  selectedOption,
  dedectibles,
  setSelectedOption,
  deductiblesRef,
  setIsOtherDeductibles,
  isOtherDeductibles,
  btnLoading,
  editFormHandler,
}) => {
  return (
    <Formik
      initialValues={{
        deductibles: selectedOption.deductible || null,
        otherDeductibles: selectedOption.otherDeductibles || '',
      }}
      onSubmit={() => {
        editFormHandler();
      }}
      validationSchema={editDeductibleSchema(isOtherDeductibles)}
      enableReinitialize={true}>
      {({handleSubmit, touched, errors, setFieldValue}) => (
        <>
          <Grid container spacing={0.9} className="edit-inputs-grid">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="edit-inputs">
              <CriteriaDropdown
                // dropdowntitle={plans.selectCriteria}
                label={'Carrier'}
                isDisable={true}
                selectedOption={selectedOption.carrier}
                height={'3rem'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CriteriaDropdown
                // placeHolder={plans.address}
                label={'Address'}
                isDisable={true}
                selectedOption={selectedOption.address}
                height={'3rem'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CriteriaDropdown
                options={dedectibles}
                // dropdowntitle={plans.selectDeductible}
                // name={allCommonText.deductibles}
                label={'Deductibles'}
                error={touched.deductibles && errors.deductibles}
                selectedOption={selectedOption.deductible}
                onChangeOption={e => {
                  setSelectedOption(other => ({...other, deductible: e}));
                  if (e.id === ALL_TEXT.NOT_AVAILABLE_ID) {
                    setIsOtherDeductibles(true);
                    setTimeout(() => {
                      deductiblesRef?.current?.focus();
                    }, 500);
                  } else {
                    setIsOtherDeductibles(false);
                  }
                }}
              />
            </Grid>
            {isOtherDeductibles && (
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <InputField
                  label={'Other Deductibles'}
                  value={selectedOption.otherDeductibles}
                  handleChange={e => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/[^0-9.]/g, ''); // remove non-numeric characters
                    const restrictedValue = numericValue.substring(0, 7); // restrict to 10 digits
                    setFieldValue('otherDeductibles', restrictedValue);
                    setSelectedOption(other => ({
                      ...other,
                      otherDeductibles: restrictedValue,
                    }));
                  }}
                  // handleChange={e => {
                  //   setFieldValue('otherDeductibles', e.target.value);
                  //   setSelectedOption(other => ({
                  //     ...other,
                  //     otherDeductibles: e.target.value,
                  //   }));
                  // }}
                  placeHolder={'Other Deductibles'}
                  name={'otherDeductibles'}
                  error={touched.otherDeductibles && errors.otherDeductibles}
                  reff={deductiblesRef}
                />
              </Grid>
            )}
          </Grid>
          <div className="footerContainer-edit-popup">
            {
              <GenericButton
                id="footer-edit-popup"
                onPress={handleSubmit}
                buttonText={ALL_TEXT.UPDATE}
                loading={btnLoading}
              />
            }
          </div>
        </>
      )}
    </Formik>
  );
};
const DevicesInput = ({
  selectedOption,
  dedectibles,
  setSelectedOption,
  deductiblesRef,
  setIsOtherDeductibles,
  isOtherDeductibles,
  btnLoading,
  editFormHandler,
}) => {
  return (
    <Formik
      initialValues={{
        deductibles: selectedOption.deductible || null,
        otherDeductibles: selectedOption.otherDeductibles || '',
      }}
      onSubmit={() => {
        editFormHandler();
      }}
      validationSchema={editDeductibleSchema(isOtherDeductibles)}
      enableReinitialize={true}>
      {({handleSubmit, touched, errors, setFieldValue}) => (
        <>
          <Grid container spacing={0.5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CriteriaDropdown
                // dropdowntitle={plans.selectCriteria}
                label={'Carrier'}
                isDisable={true}
                height={'3rem'}
                selectedOption={selectedOption.carrier}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CriteriaDropdown
                // placeHolder={plans.brandName}
                label={'Brand'}
                isDisable={true}
                height={'3rem'}
                selectedOption={selectedOption.brand}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CriteriaDropdown
                // placeHolder={plans.model}
                label={'Model'}
                isDisable={true}
                height={'3rem'}
                selectedOption={selectedOption.model}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CriteriaDropdown
                options={dedectibles}
                label={'Deductibles'}
                // dropdowntitle={plans.selectDeductible}
                // name={allCommonText.deductibles}
                error={touched.deductibles && errors.deductibles}
                selectedOption={selectedOption.deductible}
                onChangeOption={e => {
                  setSelectedOption(other => ({...other, deductible: e}));
                  if (e.id === ALL_TEXT.NOT_AVAILABLE_ID) {
                    setIsOtherDeductibles(true);
                    setTimeout(() => {
                      deductiblesRef?.current?.focus();
                    }, 500);
                  } else {
                    setIsOtherDeductibles(false);
                  }
                }}
              />
            </Grid>
            {isOtherDeductibles && (
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <InputField
                  label={'Other Deductibles'}
                  value={selectedOption.otherDeductibles}
                  handleChange={e => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/[^0-9.]/g, ''); // remove non-numeric characters
                    const restrictedValue = numericValue.substring(0, 7); // restrict to 10 digits
                    setFieldValue('otherDeductibles', restrictedValue);
                    setSelectedOption(other => ({
                      ...other,
                      otherDeductibles: restrictedValue,
                    }));
                  }}
                  // handleChange={e => {
                  //   setFieldValue('otherDeductibles', e.target.value);
                  //   setSelectedOption(other => ({
                  //     ...other,
                  //     otherDeductibles: e.target.value,
                  //   }));
                  // }}
                  placeHolder={'Other Deductibles'}
                  name={'otherDeductibles'}
                  error={touched.otherDeductibles && errors.otherDeductibles}
                  reff={deductiblesRef}
                />
              </Grid>
            )}
          </Grid>
          <div className="footerContainer-edit-popup">
            {
              <GenericButton
                id="footer-eidt"
                onPress={handleSubmit}
                buttonText={ALL_TEXT.UPDATE}
                loading={btnLoading}
              />
            }
          </div>
        </>
      )}
    </Formik>
  );
};
const CommercialInput = ({
  selectedOption,
  dedectibles,
  setSelectedOption,
  deductiblesRef,
  setIsOtherDeductibles,
  isOtherDeductibles,
  btnLoading,
  editFormHandler,
}) => {
  return (
    <Formik
      initialValues={{
        deductibles: selectedOption.deductible || null,
        otherDeductibles: selectedOption.otherDeductibles || '',
      }}
      onSubmit={() => {
        editFormHandler();
      }}
      validationSchema={editDeductibleSchema(isOtherDeductibles)}
      enableReinitialize={true}>
      {({handleSubmit, touched, errors, setFieldValue}) => (
        <>
          <Grid container spacing={0.5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CriteriaDropdown
                label={'Carrier'}
                // dropdowntitle={plans.selectCriteria}
                isDisable={true}
                height={'3rem'}
                selectedOption={selectedOption.carrier}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CriteriaDropdown
                // placeHolder={plans.maker}
                label={'Maker'}
                isDisable={true}
                height={'3rem'}
                selectedOption={selectedOption.maker}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CriteriaDropdown
                label={'Model'}
                // placeHolder={plans.model}
                isDisable={true}
                height={'3rem'}
                selectedOption={selectedOption.model}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CriteriaDropdown
                label={'Deductibles'}
                options={dedectibles}
                // dropdowntitle={plans.selectDeductible}
                // name={allCommonText.deductibles}
                selectedOption={selectedOption.deductible}
                error={touched.deductibles && errors.deductibles}
                onChangeOption={e => {
                  setSelectedOption(other => ({...other, deductible: e}));
                  if (e.id === ALL_TEXT.NOT_AVAILABLE_ID) {
                    setIsOtherDeductibles(true);
                    setTimeout(() => {
                      deductiblesRef?.current?.focus();
                    }, 500);
                  } else {
                    setIsOtherDeductibles(false);
                  }
                }}
              />
            </Grid>
            {isOtherDeductibles && (
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <InputField
                  label={'Other Deductibles'}
                  value={selectedOption.otherDeductibles}
                  handleChange={e => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/[^0-9.]/g, ''); // remove non-numeric characters
                    const restrictedValue = numericValue.substring(0, 7); // restrict to 10 digits
                    setFieldValue('otherDeductibles', restrictedValue);
                    setSelectedOption(other => ({
                      ...other,
                      otherDeductibles: restrictedValue,
                    }));
                  }}
                  // handleChange={e => {
                  //   setFieldValue('otherDeductibles', e.target.value);
                  //   setSelectedOption(other => ({
                  //     ...other,
                  //     otherDeductibles: e.target.value,
                  //   }));
                  // }}
                  placeHolder={'Other Deductibles'}
                  name={'otherDeductibles'}
                  error={touched.otherDeductibles && errors.otherDeductibles}
                  reff={deductiblesRef}
                />
              </Grid>
            )}
          </Grid>
          <div className="footerContainer-edit-popup">
            {
              <GenericButton
                id="footer-popup"
                onPress={handleSubmit}
                buttonText={ALL_TEXT.UPDATE}
                loading={btnLoading}
              />
            }
          </div>
        </>
      )}
    </Formik>
  );
};

export const EditCartPopup = ({show, handleClose, selectedId, signupId}) => {
  const [loader, setLoader] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [dedectibles, setDedectibles] = useState([]);
  const deductiblesRef = useRef();
  const [isOtherDeductibles, setIsOtherDeductibles] = useState(false);
  const [selectedCartItems, setSelectedCartItems] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [popUpResponse, setPopUpResponse] = useState({
    type: ENUMS.POPUP_ENUMS.SUCCESS,
    title: '',
    description: '',
  });
  const [selectedOption, setSelectedOption] = useState({
    carrier: null,
    maker: null,
    model: null,
    deductible: null,
    otherDeductibles: '',
    year: null,
  });
  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }
  const numberWithCommas = x => {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
    return x;
  };
  const fetchDeductibles = async isDisaster => {
    let response = await getDeductibleList(isDisaster);
    if (response && response.success) {
      const {content} = response;
      setDedectibles(content);
      return content;
    }
  };
  const getSelectedItems = async selectedCartItems => {
    const isObjectEmpty = isEmptyObject(selectedCartItems);
    if (!isObjectEmpty) {
      // check weather it is in edit mode or add mode
      const {
        serviceProviderName,
        serviceProviderId,
        deductibleAmount,
        make,
        model,
        year,
        address,
        brand,
        serviceTypeId,
      } = selectedCartItems || {};
      let deductible = `$${numberWithCommas(deductibleAmount)}.00`;
      const deductibleList = await fetchDeductibles(
        serviceTypeId == 5 ? true : false,
      );
      let index = deductibleList.findIndex(item => item.value == deductible);
      const carrierObj = {
        value: serviceProviderName,
        label: serviceProviderName,
        id: serviceProviderId,
      };
      const makerObj = {
        value: make,
        label: make,
        id: 0,
      };
      const modelObj = {
        value: model,
        label: model,
        id: 0,
      };
      const deductibleObj = {
        value: deductible,
        label: deductible,
        id: 0,
      };
      const addresseObj = {
        value: address,
        label: address,
        id: 0,
      };
      const brandObj = {
        value: brand,
        label: brand,
        id: 0,
      };
      const otherDeductibleObj = {
        id: -1,
        value: ALL_TEXT.OTHER_DEDUCTIBLES,
        label: ALL_TEXT.OTHER_DEDUCTIBLES,
      };

      if (index === -1) {
        setIsOtherDeductibles(true);
        setSelectedOption({
          carrier: carrierObj,
          maker: makerObj,
          model: modelObj,
          year: year,
          otherDeductibles: deductibleAmount.toString(), // here is difference
          deductible: otherDeductibleObj,
          address: addresseObj, // here is difference
          brand: brandObj,
        });
      } else {
        setIsOtherDeductibles(false);
        setSelectedOption({
          carrier: carrierObj,
          maker: makerObj,
          model: modelObj,
          year: year,
          deductible: deductibleObj, // here is difference
          address: addresseObj, // here is difference
          brand: brandObj,
        });
      }
    } else {
      setSelectedOption({
        carrier: null,
        maker: null,
        model: null,
        deductible: null,
        otherDeductibles: '',
        year: null,
        brand: null,
      });
    }
    setLoader(false);
  };

  const fetchCartItemById = async (id, signupId) => {
    let response = await getSingleCartItem(id, signupId);
    if (response && response.success) {
      setSelectedCartItems(response.content);
      getSelectedItems(response.content);
    }
  };

  useEffect(() => {
    fetchCartItemById(selectedId.cartItemId, signupId);
  }, []);

  const editFormHandler = async () => {
    setBtnLoading(true);
    let deductibleAmount;

    if (isOtherDeductibles) {
      deductibleAmount = parseFloat(
        selectedOption.otherDeductibles.replace(/[^0-9.]/g, ''),
      );
    } else {
      deductibleAmount = selectedOption.deductible
        ? parseFloat(selectedOption.deductible.value.replace(/[^0-9.]/g, ''))
        : null;
    }
    let AddToCartPayload = {
      serviceTypeId: selectedId.serviceTypeId.toString(),
      serviceProviderId: selectedCartItems.serviceProviderId,
      otherServiceProvider: null,
      deductibleAmount: isNaN(deductibleAmount) ? null : deductibleAmount,
      make: selectedCartItems.make,
      model: selectedCartItems.model,
      year: selectedCartItems.year,
      cartItemId: selectedCartItems.cartItemId,
      signupId: signupId,
    };
    let result = await addItemToCart(AddToCartPayload);
    if (result && result.success) {
      toast.success(result.description);
      handleClose(true);
    } else {
      //toast.error(result.description);
      setPopUpResponse({
        type: ENUMS.POPUP_ENUMS.ERROR,
        title: '',
        details: result.description,
      });
      setShowModal(true);
    }
    setBtnLoading(false);
  };

  const PlansHandler = id => {
    switch (id) {
      case ENUMS.SERVICE_TYPE.VEHICLE:
        return (
          <VehicleInputs
            selectedOption={selectedOption}
            dedectibles={dedectibles}
            deductiblesRef={deductiblesRef}
            setSelectedOption={setSelectedOption}
            isOtherDeductibles={isOtherDeductibles}
            setIsOtherDeductibles={setIsOtherDeductibles}
            btnLoading={btnLoading}
            editFormHandler={editFormHandler}
          />
        );
      case ENUMS.SERVICE_TYPE.HOME:
      case ENUMS.SERVICE_TYPE.DISASTER:
        return (
          <HomeInput
            selectedOption={selectedOption}
            dedectibles={dedectibles}
            deductiblesRef={deductiblesRef}
            setSelectedOption={setSelectedOption}
            isOtherDeductibles={isOtherDeductibles}
            setIsOtherDeductibles={setIsOtherDeductibles}
            btnLoading={btnLoading}
            editFormHandler={editFormHandler}
          />
        );
      case ENUMS.SERVICE_TYPE.DEVICE:
        return (
          <DevicesInput
            selectedOption={selectedOption}
            dedectibles={dedectibles}
            deductiblesRef={deductiblesRef}
            setSelectedOption={setSelectedOption}
            isOtherDeductibles={isOtherDeductibles}
            setIsOtherDeductibles={setIsOtherDeductibles}
            btnLoading={btnLoading}
            editFormHandler={editFormHandler}
          />
        );
      case ENUMS.SERVICE_TYPE.COMMERCIAL:
        return (
          <CommercialInput
            selectedOption={selectedOption}
            dedectibles={dedectibles}
            deductiblesRef={deductiblesRef}
            setSelectedOption={setSelectedOption}
            isOtherDeductibles={isOtherDeductibles}
            setIsOtherDeductibles={setIsOtherDeductibles}
            btnLoading={btnLoading}
            editFormHandler={editFormHandler}
          />
        );
    }
  };

  return (
    <>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header>
          {
            <div className="edit-cart-header">
              <p>{ALL_TEXT.CHANGE_DEDUCTIBLES}</p>
              <span onClick={handleClose}>
                <GoX />
              </span>
            </div>
          }
        </Modal.Header>
        <div className="edit-modal-body">
          <Modal.Body>
            {
              <div className="modal-body-generic">
                {loader ? (
                  <div className="edit-popup-loader">
                    <Loader />
                  </div>
                ) : (
                  PlansHandler(selectedId.serviceTypeId)
                )}
              </div>
            }
          </Modal.Body>
        </div>
      </Modal>
      <GenericModal
        show={showModal}
        type={popUpResponse.type}
        body={popUpResponse.details}
        buttonText={ALL_TEXT.BUTTON_TEXT.OK}
        handleClose={() => {
          if (popUpResponse.type === ENUMS.POPUP_ENUMS.SUCCESS) {
            setShowModal(false);
            handleClose(true);
          } else {
            setShowModal(false);
          }
        }}
      />
    </>
  );
};
