import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {
  commonPageRoute,
  path,
  privatePageRoutes,
  publicPageRoutes,
} from './common/routesNames';
import PublicRoute from './routes/publiceRoutes';
import PrivateRoute from './routes/privateRoute';
import {getProfile, getToken} from './utils/localstorage';
import {ENUMS} from './common';

const BaseRoutes = () => {
  const privateRoutes = Object.values(privatePageRoutes);
  const publicRoutes = Object.values(publicPageRoutes);
  const commonRoutes = Object.values(commonPageRoute);
  const isLoggedIn = getToken() !== '';
  let profile = isLoggedIn ? getProfile() : -1;

  return (
    <Routes>
      {privateRoutes.map(
        ({component: Component, name, path, subPage, ...rest}) => {
          return (
            <Route
              path={path}
              key={`route-${name}`}
              element={
                <PrivateRoute>
                  <Component
                    SubPage={subPage != null ? subPage : null}
                    {...rest}
                  />
                </PrivateRoute>
              }
              {...rest}
            />
          );
        },
      )}
      {publicRoutes.map(({component: Component, name, path}) => {
        return (
          <Route
            path={path}
            key={`route-${name}`}
            element={
              <PublicRoute>
                <Component />
              </PublicRoute>
            }
          />
        );
      })}
      {commonRoutes.map(({component: Component, name, path}) => {
        return (
          <Route path={path} key={`route-${name}`} element={<Component />} />
        );
      })}
      {profile?.roleId === ENUMS.STAFF.ROLE.BUSINESS_AMB.VALUE ? (
        <Route path="*" element={<Navigate to={path.generateQr} />} />
      ) : profile?.roleId === ENUMS.STAFF.ROLE.CUSTOMER_SUPPORT.VALUE ? (
        <Route path="*" element={<Navigate to={path.users} />} />
      ) : (
        <Route path="*" element={<Navigate to={path.dashboard} />} />
      )}
    </Routes>
  );
};
export default BaseRoutes;
